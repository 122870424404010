import React from 'react'
import ReactDOM from 'react-dom'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'

import * as serviceWorker from './serviceWorker'

import { HashRouter } from 'react-router-dom'
import './assets/base.scss'
import App from './App'
import configureStore from './config/configureStore'
import initialState from './config/initialState'
import DateFnsUtils from '@date-io/date-fns'
import { Provider } from 'react-redux'
import { createTheme } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import deepPurple from '@material-ui/core/colors/deepPurple'
import './config/i18n'

const store = configureStore(initialState)
const rootElement = document.getElementById('root')
const defaultMaterialTheme = createTheme({
  palette: {
    primary: deepPurple,
  },
})

const renderApp = (Component) => {
  ReactDOM.render(
    <Provider store={store}>
      <HashRouter>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={defaultMaterialTheme}>
            <Component />
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </HashRouter>
    </Provider>,
    rootElement,
  )
}

renderApp(App)

serviceWorker.unregister()
