import initialState from '../../config/initialState';
import * as types from '../../config/types';

export const getIsSupervisor = (state) => state.userSettings.isSupervisor;

export const decrVoicemailUnreadCount = {
  type: types.user.DECR_VOICEMAIL_UNREAD_COUNT
}

export default function reducer(state = initialState.userSettings, action) {
  switch (action.type) {
    // получение настроек пользователя
    case types.user.GET_SETTINGS: {
      const { userSettings } = action;
      return userSettings;
    }

    // обновление настроек пользователя
    case types.user.UPDATE_SETTINGS: {
      const { userSettings } = action;
      return userSettings;
    }

    // удаление приветствия пользователя
    case types.user.DELETE_MEDIA: {
      let newState = state;
      switch (action.media.mediaType) {
        case "u_g":
          let emptyGreeting = initialState.userSettings.greeting;
          emptyGreeting['useCallToRecord'] = state.greeting.useCallToRecord;
          newState = Object.assign({}, state, {greeting: emptyGreeting})
          break;
        case "u_v":
          let emptyVoicemailPrompt = initialState.userSettings.voicemailPrompt;
          emptyVoicemailPrompt['useCallToRecord'] = state.voicemailPrompt.useCallToRecord;
          newState = Object.assign({}, state, {voicemailPrompt: emptyVoicemailPrompt})
          break;
        case "u_r":
          let emptyRingtone = initialState.userSettings.ringtone;
          emptyRingtone['useCallToRecord'] = state.ringtone.useCallToRecord;
          newState = Object.assign({}, state, {ringtone: emptyRingtone})
          break;
        default:
          newState = state;
      }
      return newState;
    }

    // обновление медиа пользователя
    case types.user.UPLOAD_MEDIA: {
      let newState = {};
      switch (action.media.mediaType) {
        case "u_g":
          newState = Object.assign({}, state, {greeting: action.media})
          break;
        case "u_v":
          newState = Object.assign({}, state, {voicemailPrompt: action.media})
          break;
        case "u_r":
          newState = Object.assign({}, state, {ringtone: action.media})
          break;
        default:
          newState = state;
      }
      return newState;
    }

    //Уменьшение счетчика новых голосовых сообщений
    case types.user.DECR_VOICEMAIL_UNREAD_COUNT:
      const newCount = state.voicemailUnreadCount > 0
        ? state.voicemailUnreadCount - 1
        : state.voicemailUnreadCount
      ;
      const newState = Object.assign({}, state, {voicemailUnreadCount: newCount});
      return newState;

    // сбросить пользователя
    case types.user.RESET: {
      return {
        name: '',
        displayName: '',
        isSupervisor: 0,
        isRoot: false,
        email: '',
        pin: '',
      };
    }

    default:
      return state;

  }
};