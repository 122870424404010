import initialState from '../config/initialState';
import * as types from '../config/types';

export function domainPayments(state = initialState.domainPayments, action) {
    switch (action.type) {
        case types.domainPaymentsBalance.GET_BALANCE: {
            const { balance } = action
            return { ...state, balance }
          }
        // первая загрузка
        case types.domainPaymentsInOut.GET_RESULT: {
            const { payload } = action;
            state['inOutData'] = payload.data;
            return state;
        }
        // добавление итемов
        case types.domainPaymentsInOut.ADD_ITEMS: {
            const { payload } = action;
            state['inOutData'] = state.inOutData.concat(payload.data);
            return state;
        }
        //скачать
        case types.downloadPaymentsInOutReport.GET_RESULT: {
            return {...state, downloadPaymentsInOutReport: action.payload};
        }
        // первая загрузка
        case types.domainPayments.GET_RESULT: {
            const { payload } = action;
            state['data'] = payload;
            return state;
        }
        // добавление итемов
        case types.domainPayments.ADD_ITEMS: {
            const { payload } = action;
            state['data'] = state.data.concat(payload);
            return state;
        }

        default:
            return state;
    }
}