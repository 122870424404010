import initialState from '../config/initialState'
import * as types from '../config/types'

export const getServices = (state) => state.services

export function services(state = initialState.services, action) {
  switch (action.type) {
    case types.services.GET_SERVICES: {
      const { services } = action
      return services
    }
    //Подключить/отключить услугу
    case types.services.SET_STATUS: {
      const { payload } = action
      return state.map((service) =>
        payload.code === service.code ? payload : service,
      )
    }

    case types.services.UPDATE: {
      return state.map((service) =>
        action.service.code === service.code ? action.service : service,
      )
    }
    
    default:
      return state
  }
}
