import * as types from "./types";

const state = {
  auth: {
    userId:
      window.sessionStorage && window.sessionStorage.getItem('userId') !== null
        ? Number(window.sessionStorage.getItem('userId'))
        : null,
    isSupervisor:
      window.sessionStorage &&
      window.sessionStorage.getItem('isSupervisor') !== null
        ? !!Number(window.sessionStorage.getItem('isSupervisor'))
        : null,
    isRoot: false,
    captcha_image: null,
    passwordResetMessage: '',
    setNewPasswordMessage: '',
    domainName:
      window.localStorage && window.localStorage.getItem('domainName')
        ? window.localStorage.getItem('domainName')
        : '',
    serviceChargeNotice:
      window.sessionStorage &&
      window.sessionStorage.getItem('serviceChargeNotice')
        ? window.sessionStorage.getItem('serviceChargeNotice')
        : 1,
    lastUsername: '',
    readOnly: window.sessionStorage && window.sessionStorage.getItem('readOnly') === 'true'
      ? true
      : false,
    userType:
      window.sessionStorage && window.sessionStorage.getItem('userType') !== null
        ? Number(window.sessionStorage.getItem('userType'))
        : 0,
    // просмотр данных в виде графической схемы или таблицы
    dataView: {
      redirection: window.sessionStorage?.getItem('dataViewRedirection') ? window.sessionStorage?.getItem('dataViewRedirection') : 'graph',
      routes: window.sessionStorage?.getItem('dataViewRoutes') ? window.sessionStorage?.getItem('dataViewRoutes') : 'graph',
      ivr: window.sessionStorage?.getItem('dataViewIvr') ? window.sessionStorage?.getItem('dataViewIvr') : 'graph',
      services: window.sessionStorage?.getItem('dataViewServices') ? window.sessionStorage?.getItem('dataViewServices') : 'list',
    },
    //Когда будет refresh_token - убрать сохранение в sessionStorage. 
    token: window.localStorage.getItem('token')
    ? window.localStorage.getItem('token')
    : null,
    logoutReason: null, // причина разлогина - логаут или ошибка 401
  },
  apiErrors: [],
  domainSettings: {
    domainName: '',
    domainsPool: {
      domains: [],
      name: '',
    },
    features: [],
    balance: {},
    counters: {
      users: null,
      groups: null,
    },
    // цены на услуги
    serviceCharge: {},
    companyName: null,
    dvoConfConnect: null,
    dvoConfDisconnect: null,
    dvoFaxTransfer: null,
    dvoHold: null,
    dvoTransfer: null,
    email: null,
    timeZone: null,
    timeZones: [],
    maxErrorCount: null,
    timeout: null,
    isEmailNotificationEnabled: null,
    emailNotificationBorder: null,
    pickupNumber: null,
    extLineId: 0,
    extLines: [],
    callhistoryLifetime: 0,
    selRecordingSettings: {
      recordRetentionPeriod: 0,
      recordRetentionPeriodOptions: [],
    },
    type: {
      typeId: null,
      typeOptions: [],
    },
    faxSendPhonesLimit: 10000,
  },
  domainUsers: {
    groups: [],
    users: [],
    ivrs: [],
    uploadFileReport: null,
  },
  // пользователь в сессии (текущий)
  userSettings: {
    name: '',
    displayName: '',
    isSupervisor: 0,
    isRoot: false,
    email: '',
    pin: '',
    numberExt: '',
    sendType: 'no',
    voicemailEnabled: false,
    voicemailUnreadCount: 0,
    voicemailPrompt: {
      hash: undefined,
      mediaType: 'u_v',
      name: undefined,
    },
    ringtone: {
      hash: undefined,
      mediaType: 'u_r',
      name: undefined,
    },
    greeting: {
      hash: undefined,
      mediaType: 'u_g',
      name: undefined,
    },
    funcgroups: [],
  },
  // произвольный пользователь домена
  domainUserSettings: {
    displayName: '',
    isSupervisor: 0,
    email: '',
    pin: '',
    numberExt: '',
    sendType: 'no',
    voicemailEnabled: false,
    voicemailUnreadCount: 0,
    voicemailPrompt: {
      hash: undefined,
      mediaType: 'u_v',
      name: undefined,
    },
    ringtone: {
      hash: undefined,
      mediaType: 'u_r',
      name: undefined,
    },
    greeting: {
      hash: undefined,
      mediaType: 'u_g',
      name: undefined,
    },
  },
  groupSettings: {
    name: '',
    acdType: '',
    email: '',
    pin: '',
    users: [],
    isCallPickup: 0,
    sendType: 'null',
    skipGreeting: 0,
    missedAlert: 0,
    successAlert: 0,
    voicemailEnabled: 0,
    voicemailUnreadCount: 0,
    voicemailPrompt: {
      hash: undefined,
      mediaType: 'g_v',
      name: undefined,
    },
    onHoldMusic: {
      hash: undefined,
      mediaType: 'g_h',
      name: undefined,
    },
    greeting: {
      hash: undefined,
      mediaType: 'g_g',
      name: undefined,
    },
    textGreeting: '',
    autobreakTime: 0,
    aftercallTime: 0,
    avgCallDuration: 0,
    refUrl: '',
  },
  redirection: {},
  services: [],
  outgoingCompany: [],
  callHistory: {
    totalCount: 0,
    data: [],
  },
  callHistoryIds: [],
  callHistoryFilter: {
    direction: 1,
    dateStart: '2019-11-14',
    dateEnd: '2019-11-15',
    search: '',
    offset: 0,
    pageSize: 10,
  },
  domainCallHistory: {
    totalCount: 0,
    data: [],
  },
  domainCallHistoryArchives: [],
  domainNumbers: [],
  contacts: {
    userContacts: [],
    domainContacts: [],
    domainUsers: [],
    uploadFileReport: null,
  },
  faxes: {
    totalCount: 0,
    data: [],
  },
  faxProtocol: {},
  adminHistory: {
    totalCount: '',
    data: [],
  },
  domainPayments: {
    totalCount: '',
    inOutData: [],
    data: [],
  },
  callCenterVips: {
    totalCount: 0,
    data: [],
  },
  operatorMessages: {
    totalCount: 0,
    data: [],
  },
  loadings: {
    domainUsers: {
      users: false,
      groups: false,
    },
  },
  vatsThemeOptions: {
    enableMobileMenu: false,
    fullscreen: false,
    showHeaderBalance: true,
    showMenuBalance: true,
    showDashboardExpense: true,
    showDailyCharge: true,
    appLinks: [],
    svetofon: window.sessionStorage && window.sessionStorage.getItem('svetofon') === 'true'
      ? true
      : false,
  },
  groupCallthemes: [],
  rmo: {
    statistics: false,
    missedCalls: [],
    messages: [],
    campaigns: [],
    campaignClient: {},
  },
  cache: { /** Раздел Cache. Описание работы с ним в actions\cache */
    [types.cache.statistics.extended.TAB]: 0,
    [types.cache.statistics.extended.BY_COUNT]: null,
    [types.cache.statistics.extended.BY_DIRECTION]: null,
    [types.cache.statistics.extended.USERS_EFFICIENCY]: null,
    [types.cache.clientStat.calls.BY_USERS]: null,
  },
  system: {
    settings: {
      metrika: null,
      isBillingInUse: 1,
    }
  }
}


export default state