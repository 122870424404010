import initialState from '../config/initialState'
import * as types from '../config/types'
import { clone } from 'helpers'

const recursiveSetter = (address, obj, value) => {
  const pointPos = address.indexOf('.')
  const current  = address.substring(0, pointPos)
  const next     = address.substring(pointPos + 1)
  if (current) {
    recursiveSetter(next, obj[current], value)
  } else {
    obj[next] = value
  }
}

export default function reducer(state = initialState.loadings, action) {
  switch (action.type) {
    case types.loadings.SET: {
      const newState = clone(state)
      recursiveSetter(action.address, newState, action.value)
      return newState
    }

    default:
      return state
  }
}
