import React, { Suspense, lazy } from 'react'
import { connect } from 'react-redux'
import cx from 'classnames'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { Portal } from '@rmwc/base'
import ResizeDetector from 'react-resize-detector'
import { ToastContainer } from 'react-toastify'
import Loader from './components/Loader.js'
import { setEnableMobileMenu, setFullscreen, setSvetofon } from 'reducers/vatsThemeOptions'
import { ApiClient } from 'vats_webapi'
import { getSystemSettings } from 'actions/systemSettings';
import { bindActionCreators } from 'redux';
import { YMInitializer } from 'react-yandex-metrika';
import ym from 'react-yandex-metrika';

const UserLK = lazy(() => import('./Pages/UserLK/user'))
const Admin = lazy(() => import('./Pages/Admin/admin'))
const Login = lazy(() => import('./Pages/Login'))

class App extends React.Component {
  componentDidMount() {
    const search = this.props.location.search;
    if (search) {
      // если указан параметр fullscreen, открывается полноэкранный режим
      const regexFullscreen = /(\?|&)fullscreen(=|&|$)/;
      search.match(regexFullscreen) && this.props.setFullscreen(true)

      // если указан параметр svetofon, используется шаблон светофона
      const regexSvetofon = /(\?|&)svetofon(=|&|$)/;
      search.match(regexSvetofon) && this.props.setSvetofon(true)
    }    

    if (this.props.apiToken) {
      this.setApiToken()
    }

    // получим счетчик яндекс.метрики из системных настроек
    this.props.actions.getSystemSettings()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.apiToken !== this.props.apiToken) {
      this.setApiToken()
    }

    // событие яндекс.метрики на смену страницы
    if (!!this.props.metrika && this.props.location.pathname !== prevProps.location.pathname) {
      ym('hit', window.location.protocol + '//' + window.location.hostname + '' + this.props.location.pathname);
    }
  }

  constructor(props) {
    super(props)
    this.state = {}
  }

  setApiToken = () => {
    let defaultClient = ApiClient.instance
    // Configure Bearer (JWT) access token for authorization: jwt_token
    let jwt_token = defaultClient.authentications['jwt_token']
    jwt_token.accessToken = this.props.apiToken
  }

  render() {
    let { enableMobileMenu, fullscreen, svetofon } = this.props

    return (
      <ResizeDetector
        handleWidth
        handleHeight
        render={({ width, height }) => {
          if (fullscreen) {
            window.parent.postMessage({type: "iframe_height", value: height}, "*")
          }

          return (
            <>
              {
                !!this.props.metrika &&
                <YMInitializer 
                  accounts={[parseInt(this.props.metrika)]} 
                  options={{
                    webvisor: true, 
                    clickmap: true,
                    trackLinks: true,
                    accurateTrackBounce: true,
                  }} 
                />
              }
              <div id="prewrapper" className={cx({ 'menu-toggled': enableMobileMenu, 'fullscreen': fullscreen, 'svetofon': svetofon })}>
                {enableMobileMenu && (
                  <div
                    onClick={this.props.setEnableMobileMenu.bind(this, false)}
                    className="modal-backdrop fade show"
                  ></div>
                )}

                <Suspense
                  fallback={
                    <div className="mt-3">
                      <Loader />
                    </div>
                  }
                >
                  {/* User LK */}
                  <Route path="/user" component={UserLK} />
                  {/* Admin */}
                  <Route path="/admin" component={Admin} />
                  {/* Auth */}
                  <Route path="/login" component={Login} />
                </Suspense>

                <Route exact path="/" render={() => <Redirect to={'/login'} />} />

                <ToastContainer />
              </div>
              <Portal />
            </>
          )
        }}
      />
    )
  }
}

const mapStateToProp = (state) => ({
  enableMobileMenu: state.vatsThemeOptions.enableMobileMenu,
  fullscreen: state.vatsThemeOptions.fullscreen,
  svetofon: state.vatsThemeOptions.svetofon,
  apiToken: state.auth.token,
  metrika: state.system.settings.metrika,
})

const mapDispatchToProps = dispatch => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
  setFullscreen: (enable) => dispatch(setFullscreen(enable)),
  setSvetofon: (enable) => dispatch(setSvetofon(enable)),
  actions: bindActionCreators(
    {
      getSystemSettings,
    },
    dispatch
  ),
})

export default withRouter(connect(mapStateToProp, mapDispatchToProps)(App))