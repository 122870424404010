import initialState from '../config/initialState'
import * as types from '../config/types'

export function domainNumbers(state = initialState.domainNumbers, action) {
  switch (action.type) {
    case types.domainNumbers.GET: {
      const { domainNumbers } = action
      // внешние sip-номера идут в конце
      domainNumbers.sort(function(left, right) {
        return !left.hasOwnProperty('externalSip') ? -1 : !right.hasOwnProperty('externalSip') ? 1 : 0
      });
      return domainNumbers
    }

    case types.domainNumbers.ADD: {
      state.push(action.number)
      return state
    }

    case types.domainNumbers.UPDATE: {
      let newState = state.map(number => {
        if (number.id === action.number.id) {
          return action.number
        } else {
          return number
        }
      })
      return newState
    }

    case types.domainNumbers.DELETE: {
      let newState = state.filter(number => number.id !== action.id)
      return newState
    }

    // обновление машрута (включен/выключен) у всех номеров
    case types.domainNumbers.UPDATE_ROUTE_ENABLED: {
      let newState = state.map(number => {
        if (number.externalSip && number.externalSip.route && number.externalSip.route.id === action.number.externalSip.route.id) {
          return {...number, externalSip: {...number.externalSip, route: {...number.externalSip.route, enabled: action.number.externalSip.route.enabled}}}
        } else {
          return number;
        }
      })
      return newState
    }

    // обновление правила у номера
    case types.domainNumbers.UPDATE_RULE: {
      let newState = state.map(number => {
        if (number.id === action.rule.numberId) {
          let newRules = number.rules.map(rule => {
            if (rule.id === action.rule.id) {
              return action.rule;
            } else {
              return rule;
            }
          })
          return {...number, rules: newRules}
        } else {
          return number
        }
      })

      return newState
    }

    default:
      return state
  }
}