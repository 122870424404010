import initialState from '../config/initialState';
import * as types from '../config/types';

export function domainCallHistoryArchives(state = initialState.domainCallHistoryArchives, action) {
    switch (action.type) {
        case types.domainCallHistoryArchives.GET_RESULT: {
            return action.result;
        }
        // добавление заявки на формирование архива
        case types.domainCallHistoryArchives.ADD: {
            /*const { payload } = action;
            state['inOutData'] = state.inOutData.concat(payload.data);*/
            return state;
        }
        case types.domainCallHistoryArchives.DELETE: {
            return state.filter(archive => archive.id !== action.id);
        }

        /*//скачать 
        case types.downloadPaymentsInOutReport.GET_RESULT: {
            return {...state, downloadPaymentsInOutReport: action.payload};
        }
        // первая загрузка
        case types.domainPayments.GET_RESULT: {
            const { payload } = action;
            state['data'] = payload;
            return state;
        }
        // добавление итемов
        case types.domainPayments.ADD_ITEMS: {
            const { payload } = action;
            state['data'] = state.data.concat(payload);
            return state;
        }*/

        default:
            return state;
    }
};