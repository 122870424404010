import initialState from '../config/initialState';
import * as types from '../config/types';

export function adminHistory(state = initialState.adminHistory, action) {
    switch (action.type) {
        // первая загрузка
        case types.domainOperations.GET_RESULT: {
            const { operations } = action;
            state['data'] = operations.data;
            return state;
        }
        // добавление итемов
        case types.domainOperations.ADD_ITEMS: {
            const { operations } = action;
            state['data'] = state.data.concat(operations.data);
            return state;
        }
        
        case types.adminOperations.GET_RESULT: {
            return {...state, operations: action.payload};
        }
        case types.domainCallHistoryProtocol.GET_RESULT: {
            return {...state, currentDomainCallHistoryProtocol: action.payload};
        }
        case types.downloadDomainCallHistoryReport.GET_RESULT: {
            return {...state, downloadDomainCallHistoryReport: action.payload};
        }
        case types.downloadDomainCallHistoryRecord.GET_RESULT: {
            return {...state, downloadDomainCallHistoryRecord: action.payload};
        }
        default:
            return state;
    }
};
