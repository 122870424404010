import initialState from '../config/initialState'
import * as types from '../config/types'

export function rmo(state = initialState.rmo, action) {
  switch (action.type) {

    case types.rmo.GET_STATISTICS: {
      const newState = { ...state, statistics: action.result }
      return newState
    }

    case types.rmo.UPD_MISSED_CALL: {
      const message = action.message
      const calls_new = state.missedCalls.filter(
        (call) =>
          call.group_id !== message.group_id || call.from !== message.from,
      )
      if (message.action === 'add') {
        calls_new.unshift(message)
      }
      return { ...state, missedCalls: calls_new }
    }

    case types.rmo.GET_MESSAGES: {
      const newState = { ...state, messages: action.result }
      return newState
    }

    // прочитать сообщение
    case types.rmo.READ_MESSAGE: {
      let newMessages = state.messages.map(message => message.id === action.result.id ? action.result : message);
      return { ...state, messages: newMessages }      
    }

    // получить кампании
    case types.rmo.GET_CAMPAIGNS: {
      const newState = { ...state, campaigns: action.result }
      return newState
    }

    // получить клиента кампании
    case types.rmo.GET_CAMPAIGN_CLIENT: {
      const newState = { ...state, campaignClient: action.result }
      return newState
    }


    default:
      return state
  }
}
