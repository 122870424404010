import initialState from 'config/initialState';
import * as types from 'config/types';
import { setSessionCache } from 'actions/cache';
import { getCacheFromAny } from "../actions/cache";

/** Раздел Cache.
 * Описание работы с ним в actions\cache
 * */
export default function cache(cache = initialState.cache, action) {
  switch (action.type) {
    case types.cache.SET: {
      const oldValue = getCacheFromAny(action.key,null, null,  {cache}, true )
      const newValue = {
        ...oldValue || {},
        ...action.params
      }

      action.useSessionStorage && setSessionCache(action.key, newValue)

      return {
        ...cache,
        [action.key]: newValue
      }
    }

    default:
      return cache;
  }
};