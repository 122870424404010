import initialState from '../config/initialState';
import * as types from '../config/types';

export function faxes(state = initialState.faxes, action) {
    switch (action.type) {
        case types.faxes.GET_RESULT: {
            const { faxes } = action;
            return faxes;
        }
        default:
            return state;
    }
};

export function faxProtocol(state = initialState.faxProtocol, action) {
    switch (action.type) {
        case types.faxes.GET_FAX_PROTOCOL: {
            const { faxProtocol } = action;
            return faxProtocol;
        }
        default:
            return state;
    }
};