import initialState from '../config/initialState'
import * as types from '../config/types'
import { clone } from 'helpers'

export function outgoingCompany(state = initialState.outgoingCompany, { type, payload }) {
  switch (type) {
    // COMPANIES
    case types.outgoingCompany.GET: {
      if (state && state.length) {
        return clone(state).map(company => {
          const newCompany = payload.find(c => c.id == company.id)
          return newCompany ? { ...company, ...newCompany } : company
        })
      }

      return payload
    }
    case types.outgoingCompany.ADD: {
      return [...state, payload]
    }
    case types.outgoingCompany.DELETE: {
      return state.filter(({ id }) => id != payload.id)
    }
    case types.outgoingCompany.UPDATE: {
      return state.map(company =>
        String(company.id) === String(payload.id)
          ? { ...company, ...payload.outgoingCompany }
          : company,
      )
    }
    case types.outgoingCompany.UPDATE_STATUS: {
      return state.map(company => company.id === payload.id ? { ...company, active: payload.active ? 1 : 0 } : company)
    }

    // STATUSES
    case types.outgoingCompany.statuses.SET: {
      return state.map(company => company.id == payload.id ? { ...company, statuses: payload.statuses } : company)
    }
    case types.outgoingCompany.statuses.ADD: {
      return state.map(company => company.id == payload.id ? { ...company, statuses: [...company.statuses, payload.status] } : company)
    }
    case types.outgoingCompany.statuses.DELETE: {
      return state.map(company => company.id == payload.id ? { ...company, statuses: company.statuses.filter(s => s.id != payload.statusId) } : company)
    }

    // CLIENTS
    case types.outgoingCompany.clients.GET: {
      return state.map(company => company.id == payload.id ? { ...company, clients: payload.clients } : company)
    }
    case types.outgoingCompany.clients.ADD: {
      return state.map(company => company.id == payload.id ? { ...company, clients: [...company.clients, payload.client] } : company)
    }
    case types.outgoingCompany.clients.ADD_LIST: {
      return state.map(company => company.id == payload.id ? { ...company, clients: [...company.clients, ...(payload.list || [])] } : company)
    }
    case types.outgoingCompany.clients.DELETE: {
      return state.map(
        company =>
          company.id == payload.id ?
            {
              ...company,
              clients: company.clients.filter(client => client.id !== payload.clientId)
            }
            : company
      )
    }
    case types.outgoingCompany.clients.DELETE_ALL: {
      return state.map(company => company.id == payload.id ? { ...company, clients: [] } : company)
    }

    default:
      return state
  }
}