import initialState from '../config/initialState';
import * as types from '../config/types';

export function groupCallthemes(state = initialState.groupCallthemes, action) {
  switch (action.type) {
 
    case types.groupCallthemes.GET: {
      const { groupCallthemes } = action;
      return groupCallthemes;
    }

    case types.groupCallthemes.ADD: {
      state.push(action.groupCalltheme)
      return state
    }

    case types.groupCallthemes.DELETE: {
      let newState = state.filter(theme => theme.id !== action.themeId)
      return newState
    }

    default: return state;
  }
};