import initialState from '../config/initialState';
import * as types from '../config/types';

export function operatorMessages(state = initialState.operatorMessages, action) {
    switch (action.type) {
        case types.messages.GET: {
            const { result } = action;
            state = result;
            return state;
        }

        case types.messages.DELETE: {
            let newState = {
                data: state.data.filter(message => message.id !== action.id),
                totalCount: state.totalCount - 1,
            }
            return newState;
        }

        case types.messages.ADD: {
            let newState = {
                data: [action.result].concat(state.data),
                totalCount: state.totalCount + 1,
            }
            return newState;
        }

/*
        // добавление итемов
        case types.messages.ADD_ITEMS: {
            let newData = state['data'].concat(action.result.data)
            return {data: newData, totalCount: action.result.totalCount}
        }

        

        case types.messages.UPDATE_VIP: {
            let newData = state.data.map(vip => {
                if (vip.id === action.result.id) {
                    return action.result;
                } else {
                    return vip;
                }
            })

            const newState = Object.assign({}, state, {data: newData})
            return newState;
        }

        
   */
        default:
            return state;
    }
};