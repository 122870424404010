import initialState from '../config/initialState';
import * as types from '../config/types';

export default function apiErrors(state = initialState.apiErrors, action) {
  switch (action.type) {
    case types.app.ERROR: {
      const { error } = action;
      let apiError = {
        response: -1,
        message: "Ошибка при выполнении запроса",
        errors: [],
      };
      if (error.response && error.response.text.length > 0) {
        try {
          apiError = JSON.parse(error.response.text);
        } catch (e) {}
      }
      /* @todo Передалать работу с ошибками и возвращать целиком apiError */
      const newState = apiError.errors;
      newState.responseMessage = apiError.message;

      return newState;
    }

    // сброс ошибок
    case types.app.ERROR_RESET: {
      return [];
    }

    default:
      return state;

  }
};