import React from 'react'
import { withTranslation } from 'react-i18next';

class Loader extends React.Component {
  render() {
    return (
      <div className="loader">
        <div className="spinner-block">
          <div className="spinner"></div>
          <div className="text">{this.props.t('Загрузка')}...</div>
        </div>
      </div>
    )
  }
}

export default withTranslation()(Loader);