export const app = {
  ERROR: 'vats/app/error',
  ERROR_RESET: 'vats/app/error/reset',
}

export const auth = {
  LOGIN: 'vats/auth/login',
  CHECK: 'vats/auth/check',
  LOGOUT: 'vats/auth/logout',
  RESET_PASSWORD: 'vats/auth/reset_password',
  SET_NEW_PASSWORD: 'vats/auth/set_new_password',
  SERVICE_CHARGE_NOTICE: 'vats/auth/service_charge_notice',
  LAST_USERNAME: 'vats/auth/last_login_value',
  DATA_VIEW: 'vats/auth/data_view',
}

export const domain = {
  GET_SETTINGS: 'vats/domain/get_settings',
  UPDATE_SETTINGS: 'vats/domain/update_settings',
  GET_BALANCE: 'vats/domain/get_balance',
  GET_USERS: 'vats/domain/get_users',
  GET_GROUPS: 'vats/domain/get_groups',
  ADD_GROUP: 'vats/domain/add_group',
  INCREMENT_COUNTER: 'vats/domain/increment_counter',
  DELETE_GROUP: 'vats/domain/delete_group',
  UPDATE_GROUP: 'vats/domain/update_group',
  DELETE_USER: 'vats/domain/delete_user',
  ADD_USER: 'vats/domain/add_user',
  UPDATE_USERS: 'vats/domain/update_users',
  GET_IVRS: 'vats/domain/get_ivrs',
  ADD_IVR: 'vats/domain/add_ivr',
  REMOVE_IVR: 'vats/domain/remove_ivr',
  UPDATE_IVR: 'vats/domain/update_ivr',
  UPDATE_IVR_MENU: 'vats/domain/update_ivr_menu',
  UPDATE_USER_GROUPS: 'vats/domain/update_user_groups',
  SET_GROUP_USERS: 'vats/domain/set_group_users',
  GET_SERVICE_CHARGE: 'vats/domain/get_service_charge',
  MEDIA: {
    DELETE: 'vats/domain/media/delete',
    UPLOAD: 'vats/domain/media/upload',
  },
  SEL_RECORDING_SETTINGS: 'vats/domain/sel_recording_settings',
  EXT_LINE: {
    ADD: 'vats/domain/ext_line/add',
    DELETE: 'vats/domain/ext_line/delete',
    UPDATE: 'vats/domain/ext_line/update',
  },
  GET_TYPE: 'vats/domain/get_type',
}

export const redirection = {
  GET_NUMBERS: 'vats/redirection/get_numbers',
  GET_FMC_NUMBERS: 'vats/redirection/get_fmc_numbers',
  ADD_NUMBER: 'vats/redirection/add_number',
  UPDATE_NUMBER: 'vats/redirection/update_number',
  DELETE_NUMBER: 'vats/redirection/delete_number',
}

export const callHistory = {
  GET_RESULT: 'vats/call_history/get_result',
  ADD_ITEMS: 'vats/call_history/add_items',
  GET_STAT: 'vats/call_history/get_stat',
  DELETE_VOICEMAIL: 'vats/call_history/delete_voicemail',
}

export const domainOperations = {
  GET_RESULT: 'vats/domain_operations/get_result',
  ADD_ITEMS: 'vats/domain_operations/add_items',
}

export const domainCallHistory = {
  GET_RESULT: 'vats/domain_call_history/get_result',
  ADD_ITEMS: 'vats/domain_call_history/add_items',
  GET_STAT: 'vats/domain_call_history/get_stat',
  DELETE_VOICEMAIL: 'vats/domain_call_history/delete_voicemail',
}

export const domainCallHistoryArchives = {
  GET_RESULT: 'vats/domain_call_history_archives/get',
  ADD: 'vats/domain_call_history_archives/add',
  DELETE: 'vats/domain_call_history_archives/delete',
}

export const adminOperations = {
  GET_RESULT: 'vats/admin_operations/get_result',
  ADD_ITEMS: 'vats/admin_operations/add_items',
}

export const domainCallHistoryProtocol = {
  GET_RESULT: 'vats/domain_call_history_protocol/get_result',
}

export const downloadDomainCallHistoryReport = {
  GET_RESULT: 'vats/domain_call_history_report/get_result',
}

export const downloadDomainCallHistoryRecord = {
  GET_RESULT: 'vats/domain_call_history_record/get_result',
}

export const domainPaymentsBalance = {
  GET_BALANCE: 'vats/domain_payments_balance/get_balance',
}

export const domainPaymentsInOut = {
  GET_RESULT: 'vats/domain_payments_in_out/get_result',
  ADD_ITEMS: 'vats/domain_payments_in_out/add_items',
}

export const downloadPaymentsInOutReport = {
  GET_RESULT: 'vats/domain_payments_report/get_result',
}

export const domainPayments = {
  GET_RESULT: 'vats/domain_payments/get_result',
  ADD_ITEMS: 'vats/domain_payments/add_items',
}

export const domainCallRecord = {
  GET_RESULT: 'vats/domain/payments/charge/get_result',
  SET_USER: 'vats/domain/users/{id}/recording',
  SET_USERS: 'vats/domain/users/recording',
  GET_USERS: 'vats/domain_call_record/get_users',
  GET_GROUPS: 'vats/domain_call_record/get_groups',
}

export const services = {
  GET_SERVICES: 'vats/domain_payments/services/get_services',
  SET_STATUS: 'vats/domain_payments/services/set_status',
  UPDATE: 'vats/domain_payments/services/update',
}

export const privateRoutes = {
  GET_PRIVATE_ROUTES: 'vats/domain_payments/private_routes/get_private_routes',
}

export const contacts = {
  GET_RESULT: 'vats/contacts/get_result',
  ADD_GROUP: 'vats/contacts/add_group',
  UPDATE_GROUP: 'vats/contacts/update_group',
  DELETE_GROUP: 'vats/contacts/delete_group',
  ADD_CONTACT: 'vats/contacts/add_contact',
  UPDATE_CONTACT: 'vats/contacts/update_contact',
  DELETE_CONTACT: 'vats/contacts/delete_contact',
  GET_RESULT_DOMAIN: 'vats/contacts/get_result_domain',
  GET_RESULT_DOMAIN_USERS: 'vats/contacts/get_result_domain_users',
  DOMAIN: {
    GROUPS: {
      ADD: 'vats/contacts/domain/groups/add',
      UPDATE: 'vats/contacts/domain/groups/update',
      DELETE: 'vats/contacts/domain/groups/delete',
    },
    CONTACT: {
      ADD: 'vats/contacts/domain/contact/add',
      UPDATE: 'vats/contacts/domain/contact/update',
      DELETE: 'vats/contacts/domain/contact/delete',
    },
    LIST: {
      UPLOAD: 'vats/contacts/domain/list/upload',
      ADD: 'vats/contacts/domain/list/add',
      CLEAR: 'vats/contacts/domain/list/clear_report',
    },
  },
}

export const faxes = {
  GET_RESULT: 'vats/faxes/get_result',
  GET_FAX_PROTOCOL: 'vats/faxes/get_fax_protocol',
}

export const user = {
  GET_SETTINGS: 'vats/user/get_settings',
  UPDATE_SETTINGS: 'vats/user/update_settings',
  UPDATE_AUTH: 'vats/user/update_auth',
  DELETE_MEDIA: 'vats/user/delete_media',
  UPLOAD_MEDIA: 'vats/user/upload_media',
  DECR_VOICEMAIL_UNREAD_COUNT: 'vats/user/decr_voicemail_unread_count',
  RESET: 'vats/user/reset',
}

export const domainUser = {
  GET_SETTINGS: 'vats/domain_user/get_settings',
  UPDATE_SETTINGS: 'vats/domain_user/update_settings',
  DELETE_MEDIA: 'vats/domain_user/delete_media',
  UPLOAD_MEDIA: 'vats/domain_user/upload_media',
  DECR_VOICEMAIL_UNREAD_COUNT: 'vats/domain_user/decr_voicemail_unread_count',
}

export const group = {
  GET_SETTINGS: 'vats/group/get_settings',
  SETTINGS_RESET: 'vats/group/settings_reset',
  UPLOAD_MEDIA: 'vats/group/upload_media',
  DELETE_MEDIA: 'vats/group/delete_media',
  DELETE_USER: 'vats/group/delete_user',
}

export const vips = {
  GET_VIPS: 'vats/vips/get_vips',
  ADD_ITEMS: 'vats/vips/add_items',
  ADD_VIP: 'vats/vips/add_vip',
  UPDATE_VIP: 'vats/vips/update_vip',
  DELETE_VIP: 'vats/vips/delete_vip',
}

export const messages = {
  GET: 'vats/messages/get',
  DELETE: 'vats/messages/delete',
  ADD: 'vats/messages/add',
}

export const outgoingCompany = {
  GET: 'vats/outgoing-company/get',
  ADD: 'vats/outgoing-company/add',
  UPDATE: 'vats/outgoing-company/update',
  UPDATE_STATUS: 'vats/outgoing-company/update_status',
  DELETE: 'vats/outgoing-company/delete',
  statuses: {
    SET: 'vats/outgoing-company/statuses/set',
    ADD: 'vats/outgoing-company/statuses/add',
    DELETE: 'vats/outgoing-company/statuses/delete',
  },
  clients: {
    GET: 'vats/outgoing-company/clients/get',
    ADD: 'vats/outgoing-company/clients/add',
    ADD_LIST: 'vats/outgoing-company/clients/add_list',
    DELETE: 'vats/outgoing-company/clients/delete',
    DELETE_ALL: 'vats/outgoing-company/clients/delete_all',
  },
}

export const loadings = {
  SET: 'vats/loadings/set',
}

export const domainNumbers = {
  GET: 'vats/domain_numbers/get',
  ADD: 'vats/domain_numbers/add',
  UPDATE: 'vats/domain_numbers/update',
  DELETE: 'vats/domain_numbers/delete',
  UPDATE_ROUTE_ENABLED: 'vats/domain_numbers/update_route_enabled',
  UPDATE_RULE: 'vats/domain_numbers/update_rule',
}

export const statistics = {
  extended: {
    GET_DIRECTIONS: 'vats/statistics/extended/GET_DIRECTIONS',
    BY_COUNT: {
      GET: 'vats/statistics/extended/BY_COUNT/GET',
      MEMORY: 'vats/statistics/extended/BY_COUNT/MEMORY',
    },
    BY_DIRECTION: {
      GET: 'vats/statistics/extended/BY_DIRECTION/GET',
      MEMORY: 'vats/statistics/extended/BY_DIRECTION/MEMORY',
    },
    USERS_EFFICIENCY: {
      GET: 'vats/statistics/extended/USERS_EFFICIENCY/GET',
      MEMORY: 'vats/statistics/extended/USERS_EFFICIENCY/MEMORY',
    },
  }
}

export const clientStat = {
  calls:{
    GET_BY_USERS: 'vats/client_stat/calls/by_users/GET'
  }
}

/** Раздел Cache. Описание работы с ним в actions\cache */
export const cache = {
  SET: 'vats/cache/SET',
  statistics: {
    extended: {
      TAB: 'vats/statistics/extended/TAB',
      BY_COUNT: 'vats/statistics/extended/by_count',
      BY_DIRECTION: 'vats/statistics/extended/by_direction',
      USERS_EFFICIENCY: 'vats/statistics/extended/users_efficiency',
    },
  },
  clientStat:{
    calls:{
      BY_USERS: 'vats/client_stat/calls/by_users'
    }
  }
}

// темы вызовов
export const groupCallthemes = {
  GET: 'vats/group_callthemes/get',
  ADD: 'vats/group_callthemes/add',
  DELETE: 'vats/group_callthemes/delete',
}

// системные настройки
export const systemSettings = {
  GET: 'vats/system/settings/get',
}

//рабочее место оператора
export const rmo = {
  GET_STATISTICS: 'vats/rmo/statistics/get',
  UPD_MISSED_CALL: 'vats/rmo/upd_missed_call',
  GET_MESSAGES: 'vats/rmo/messages/get',
  READ_MESSAGE: 'vats/rmo/messages/read',
  GET_CAMPAIGNS: 'vats/rmo/campaigns/get',
  GET_CAMPAIGN_CLIENT: 'vats/rmo/campaigns/get_client',
}
