import initialState from '../config/initialState'
import * as types from '../config/types'

export const getPrivateRoutes = (state) => state.privateRoutes

export function privateRoutes(state = initialState.services, action) {
  switch (action.type) {
    case types.privateRoutes.GET_PRIVATE_ROUTES: {
      return action.payload
    }
    default:
      return state
  }
}
