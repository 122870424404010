import initialState from '../config/initialState';
import * as types from '../config/types';

export function callCenterVips(state = initialState.callCenterVips, action) {
    switch (action.type) {
        case types.vips.GET_VIPS: {
            const { result } = action;
            state = result;
            return state;
        }

        // добавление итемов
        case types.vips.ADD_ITEMS: {
            let newData = state['data'].concat(action.result.data)
            return {data: newData, totalCount: action.result.totalCount}
        }

        case types.vips.ADD_VIP: {
            let newState = {
                data: [action.result].concat(state.data),
                totalCount: state.totalCount + 1,
            }
            return newState;
        }

        case types.vips.UPDATE_VIP: {
            let newData = state.data.map(vip => {
                if (vip.id === action.result.id) {
                    return action.result;
                } else {
                    return vip;
                }
            })

            const newState = Object.assign({}, state, {data: newData})
            return newState;
        }

        case types.vips.DELETE_VIP: {
            let newState = {
                data: state.data.filter(vip => vip.id !== action.id),
                totalCount: state.totalCount - 1,
            }
            return newState;
        }
   
        default:
            return state;
    }
};