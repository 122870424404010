import initialState from '../config/initialState';
import * as types from '../config/types';

export function callHistory(state = initialState.callHistory, action) {
    switch (action.type) {
        // первая загрузка
        case types.callHistory.GET_RESULT: {
            const { callHistory } = action;
            return { ...state, data: callHistory.data};
        }
        // добавление итемов
        case types.callHistory.ADD_ITEMS: {
            const { callHistory } = action;
            state['data'] = state.data.concat(callHistory.data);
            return state;
        }
        case types.callHistory.GET_STAT: {
            const { stat } = action;
            return {
                ...state,
                totalCount: stat.totalCount,
            };
        }
        // удаление голосовой почты
        case types.callHistory.DELETE_VOICEMAIL: {
            let newState = {}
            newState['totalCount'] = state.totalCount;
            newState['data'] = state['data'].map(call => {
                if (call.voiceMail && call.voiceMail.id === action.voicemailId) {
                    return {...call, voiceMail: null}
                } else {
                    return call;
                }
            })
            return newState;
        }

        default:
            return state;
    }
};


export function callHistoryFilter(state = initialState.callHistoryFilter, action) {
    switch (action.type) {
        /*case types.callHistory.GET_RESULT: {
            const { callHistory } = action;
            const newState = Object.assign({}, state);
            newState.totalCount = callHistory.totalCount;
            for (let call of callHistory.data) {
                if (!newState.data[call.startCallDateTime]) {
                    newState.data[call.startCallDateTime] = call;
                }
            }
            return newState;
        } */
        default:
            return state;

    }
};

export function callHistoryIds(state = initialState.callHistoryIds, action) {
    switch (action.type) {
        case types.callHistory.GET_RESULT: {
            const callHistoryIds = action.callHistory.data.map(call => call.startCallDateTime);
            const newState = Array.from(state);
            for (let id of callHistoryIds) {
                if (!newState.includes(id)) {
                    newState.push(id);
                }
            }
            return newState;
        }
        default:
            return state;

    }
};