import initialState from '../config/initialState'
import * as types from '../config/types'

export const getIsBillingInUse = (state) => !!state.system.settings.isBillingInUse

export function system(state = initialState.system, action) {
  switch (action.type) {

    // получение настроек системы
    case types.systemSettings.GET: {
      return { 
      	...state, settings: {
      		...state.settings, 
      		metrika: action.result.integrations.metrika,
      		isBillingInUse: action.result.isBillingInUse,
      	} 
      }
    }

    default:
      return state
  }
}
