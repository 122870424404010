// переменные внутри строк:
// https://www.i18next.com/translation-function/interpolation

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  ru: {
    translation: {
      "brandName": "",
      "authTitle": "Виртуальная АТС",
      "Войти": "Войти",
      "Логин": "Логин",
      "Выход": "Выход",
      "Пароль": "Пароль",
      "Восстановить пароль": "Восстановить пароль",
      "Домен ВАТС": "Домен ВАТС",
      "Поле не может быть пустым": "Поле не может быть пустым",
      "Восстановление доступа": "Восстановление доступа",
      "Восстановить": "Восстановить",
      "Войти в систему": "Войти в систему",
      "Загрузка": "Загрузка",
      "Настройки пользователя": "Настройки пользователя",
      "Настройки домена": "Настройки домена",
      "Пользователь": "Пользователь",
      "Баланс": "Баланс",
      "Остаток МГП": "Остаток МГП",
      "Остаток минут": "Остаток минут",
      "Основные данные": "Основные данные",
      "Отображаемое имя": "Отображаемое имя",
      "Внутренний номер": "Внутренний номер",
      "Должность": "Должность",
      "SIP-адрес": "SIP-адрес",
      "Изменить пароль": "Изменить пароль",
      "Номер для исходящих": "Номер для исходящих",
      "Сохранить изменения": "Сохранить изменения",
      "Изменения сохранены": "Изменения сохранены",
      "Применить": "Применить",
      "Закрыть": "Закрыть",
      "Повтор пароля": "Повтор пароля",
      "Некорректный email": "Некорректный email",
      "Имя": "Имя",
      "подтвержден": "подтвержден",
      "не подтвержден": "не подтвержден",
      "Проверить": "Проверить",
      "Пароли не совпадают": "Пароли не совпадают",
      "Текущий пароль": "Текущий пароль",
      "Новый пароль": "Новый пароль",
      "Пароль не должен совпадать с логином": "Пароль не должен совпадать с логином, должен содержать не менее 6 символов: обязательно использовать строчные, заглавные латинские буквы (A-z) и цифры (0-9), из неалфавитных символов запрещено использовать ($@)",
      "После изменения учетных данных пользователя не забудьте перенастроить SIP-устройства.": "После изменения учетных данных пользователя не забудьте перенастроить SIP-устройства.",
      "При заполнении формы возникли следующие ошибки:": "При заполнении формы возникли следующие ошибки:",
      "Изменение учетных данных": "Изменение учетных данных",
      "Данные пользователя": "Данные пользователя",
      "Настройки вызовов": "Настройки вызовов",
      "Журнал вызовов": "Журнал вызовов",
      "Факсы": "Факсы",
      "Адресная книга": "Адресная книга",
      "Помощь": "Помощь",
      "Голосовое приветствие": "Голосовое приветствие",
      "Сообщение, которое проигрывается перед началом разговора.": "Сообщение, которое проигрывается перед началом разговора.",
      "Голосовая почта": "Голосовая почта",
      "При недоступности всех контактных номеров вызов будет направлен на голосовую почту.": "При недоступности всех контактных номеров вызов будет направлен на голосовую почту.",
      "Приветствие голосовой почты": "Приветствие голосовой почты",
      "Мелодия гудка": "Мелодия гудка",
      "Вы можете загрузить собственную мелодию гудка.": "Вы можете загрузить собственную мелодию гудка.",
      "Загрузить аудио": "Загрузить аудио",
      "Записать": "Записать",
      "Записать голосовое приветствие": "Записать голосовое приветствие",
      "Записать приветствие": "Записать приветствие",
      "Позвонить и записать": "Позвонить и записать",
      "Записать голосовую почту": "Записать голосовую почту",
      "Вы хотите записать приветствие?": "Вы хотите записать приветствие?",
      "Вы хотите записать голосовую почту?": "Вы хотите записать голосовую почту?",
      "Сценарий вызова для данного пользователя": "Сценарий вызова для данного пользователя",
      "Графическое отображение": "Графическое отображение",
      "Табличное отображение": "Табличное отображение",
      "Статус": "Статус",
      "Приоритет": "Приоритет",
      "Контактный номер": "Контактный номер",
      "Расписание": "Расписание",
      "Время дозвона, с": "Время дозвона, с",
      "В любое время": "В любое время",
      "Добавить номер": "Добавить номер",
      "Удалить": "Удалить",
      "Редактировать": "Редактировать",
      "IP-телефон": "IP-телефон",
      "Мобильный": "Мобильный",
      "Другой": "Другой",
      "Рабочий": "Рабочий",
      "Домашний": "Домашний",
      "Сохранить": "Сохранить",
      "Выключить номер": "Выключить номер",
      "Включить номер": "Включить номер",
      "Круглосуточно": "Круглосуточно",
      "Приоритет номера": "Приоритет номера",
      "Номер": "Номер",
      "Тип номера": "Тип номера",
      "Интервал задан неверно": "Интервал задан неверно",
      "пн": "пн",
      "вт": "вт",
      "ср": "ср",
      "чт": "чт",
      "пт": "пт",
      "сб": "сб",
      "вс": "вс",
      "Пн": "Пн",
      "Вт": "Вт",
      "Ср": "Ср",
      "Чт": "Чт",
      "Пт": "Пт",
      "Сб": "Сб",
      "Вс": "Вс",
      "заблокирован": "заблокирован",
      "проверить статус": "проверить статус",
      "Еще период": "Еще период",
      "Время": "Время",
      "Подтверждение действия": "Подтверждение действия",
      "Удалить номер?": "Удалить номер?",
      "Завершение": "Завершение",
      "Приветствие": "Приветствие",
      "В другое время": "В другое время",
      "еще": "еще",
      "Еще": "Еще",
      "Ничего не найдено": "Ничего не найдено",
      "Прослушать запись": "Прослушать запись",
      "Тип": "Тип",
      "Абонент": "Абонент",
      "Длительность": "Длительность",
      "Вызовов": "Вызовов",
      "Все": "Все",
      "Входящие": "Входящие",
      "Исходящие": "Исходящие",
      "Пропущенные": "Пропущенные",
      "Сегодня": "Сегодня",
      "Вчера": "Вчера",
      "Неделя": "Неделя",
      "Месяц": "Месяц",
      "Период – с": "Период – с",
      "Период – до": "Период – до",
      "Поиск": "Поиск",
      "Показать": "Показать",
      "Тип вызова": "Тип вызова",
      "Полученные": "Полученные",
      "Отправленные": "Отправленные",
      "Отправить факс": "Отправить факс",
      "Отправка факса": "Отправка факса",
      "Отправить": "Отправить",
      "Номер должен содержать только цифры": "Номер должен содержать только цифры",
      "Дата отправки должна быть в будущем": "Дата отправки должна быть в будущем",
      "Отложить отправку до": "Отложить отправку до",
      "Неподдерживаемый тип файла": "Неподдерживаемый тип файла",
      "Выберите файл для отправки": "Выберите файл для отправки",
      "Номер факса": "Номер факса",
      "Отменить": "Отменить",
      "Протокол факса": "Протокол факса",      
      "Дата и время": "Дата и время",
      "Направление": "Направление",
      "Получатель/отправитель": "Получатель/отправитель",
      "Факсов": "Факсов",
      "Личная адресная книга": "Личная адресная книга",
      "Личные контакты": "Личные контакты",
      "Общие контакты": "Общие контакты",
      "Пользователи": "Пользователи",
      "Новый контакт": "Новый контакт",
      "Новая группа": "Новая группа",
      "Поиск по имени или контактам": "Поиск по имени или контактам",
      "Быстрый набор": "Быстрый набор",
      "Номера": "Номера",
      "Группа": "Группа",
      "Электронная почта": "Электронная почта",
      "Номера телефонов": "Номера телефонов",
      "Создать": "Создать",
      "Еще номер": "Еще номер",
      "Новая группа контактов": "Новая группа контактов",
      "Название": "Название",
      "Редактировать группу": "Редактировать группу",
      "Редактирование группы": "Редактирование группы",
      "Удалить группу": "Удалить группу",
      "Редактирование контакта": "Редактирование контакта",
      "не заполнено": "не заполнено",
      "Не более 5 цифр": "Не более 5 цифр",
      "Укажите номер": "Укажите номер",
      "Список пользователей домена": "Список пользователей домена",
      "ФИО, должность": "ФИО, должность",
      "Оператор": "Оператор",
      "Доп. контактные номера": "Доп. контактные номера",
      "Да": "Да",
      "Домен": "Домен",
      "Журнал операций": "Журнал операций",
      "Частные маршруты": "Частные маршруты",
      "Внешние номера": "Внешние номера",
      "Управление номерами": "Управление номерами",
      "Голосовые сценарии": "Голосовые сценарии",
      "Запись разговоров": "Запись разговоров",
      "Исходящие кампании": "Исходящие кампании",
      "Автообзвон": "Автообзвон",
      "Виджеты для сайтов": "Виджеты для сайтов",
      "Интеграционный API": "Интеграционный API",
      "Интеграция с CRM": "Интеграция с CRM",
      "Аналитика речи": "Аналитика речи",
      "Отчеты контакт-центра": "Отчеты контакт-центра",
      "Клиентская статистика": "Клиентская статистика",
      "Статистика": "Статистика",
      "Финансы": "Финансы",
      "Группы обработки": "Группы обработки",
      "Услуги": "Услуги",
      "История": "История",
      "Настройки": "Настройки",
      "Администратор домена": "Администратор домена",
      "Администратор системы": "Администратор системы",
      "Новый пользователь": "Новый пользователь",
      "Новая группа перехвата": "Новая группа перехвата",
      "Новая группа обработки вызовов": "Новая группа обработки вызовов",
      "Все группы": "Все группы",
      "Имя и должность": "Имя и должность",
      "Внутренний номер и email": "Внутренний номер и email",
      "Группы и метки": "Группы и метки",
      "Добавить в группу": "Добавить в группу",
      "Сгенерировать": "Сгенерировать",
      "SIP-адрес / логин": "SIP-адрес / логин",
      "Отправить учётные данные на email": "Отправить учётные данные на email",
      "Добавить несколько пользователей": "Добавить несколько пользователей",
      "Пользователи внутри такой группы могут перехватывать вызовы своих коллег, пока звонит их телефон, набрав номер перехвата. В группы перехвата включают находящихся в одном кабинете.": "Пользователи внутри такой группы могут перехватывать вызовы своих коллег, пока звонит их телефон, набрав номер перехвата. В группы перехвата включают находящихся в одном кабинете.",
      "Номер для перехвата": "Номер для перехвата",
      "Изменить": "Изменить",
      "Добавьте в группу участников!": "Добавьте в группу участников!",
      "Выберите пользователей из окна слева.": "Выберите пользователей из окна слева.",
      "Вы также сможете сделать это позднее, после создания группы.": "Вы также сможете сделать это позднее, после создания группы.",
      "Массовое создание пользователей": "Массовое создание пользователей",
      "Для выбранного алгоритма распределения требуется наличие в группе операторов.": "Для выбранного алгоритма распределения требуется наличие в группе операторов.",
      "Не состоит в группах": "Не состоит в группах",
      "Добавить пользователей в состав группы": "Добавить пользователей в состав группы",
      "Все пользователи": "Все пользователи",
      "Алгоритм распределения вызовов": "Алгоритм распределения вызовов",
      "Введите число от 1 до 99999": "Введите число от 1 до 99999",
      "Ограничение исходящих": "Ограничение исходящих",
      "Скопировать выбранное": "Скопировать выбранное",
      "Ежемесячная плата увеличится на": "Ежемесячная плата увеличится на",
      "Ежедневная плата увеличится на": "Ежедневная плата увеличится на",
      "Ежемесячная плата уменьшится на": "Ежемесячная плата уменьшится на",
      "Ежедневная плата уменьшится на": "Ежедневная плата уменьшится на",
      "Разовая плата за подключение": "Разовая плата за подключение",
      "Номер для вызовов между сотрудниками": "Номер для вызовов между сотрудниками",
      "пользователь": "пользователь",
      "пользователя": "пользователя",
      "пользователей": "пользователей",
      "оператор": "оператор",
      "оператора": "оператора",
      "операторов": "операторов",
      "не задан": "не задан",
      "Всем пользователям одновременно": "Всем пользователям одновременно",
      "всем пользователям одновременно": "всем пользователям одновременно",
      "[ACD] случайному оператору": "[ACD] случайному оператору",
      "[ACD] Оператору, который дольше всех свободен": "[ACD] Оператору, который дольше всех свободен",
      "[ACD] оператору, который дольше всех свободен": "[ACD] оператору, который дольше всех свободен",
      "[ACD] Оператору, который меньше всех загружен разговорами": "[ACD] Оператору, который меньше всех загружен разговорами",
      "[ACD] оператору, который меньше всех загружен разговорами": "[ACD] оператору, который меньше всех загружен разговорами",
      "Выберите группу": "Выберите группу",
      "Переместить в группу перехвата": "Переместить в группу перехвата",
      "Снять выделение": "Снять выделение",
      "В группу": "В группу",
      "В группу перехвата": "В группу перехвата",
      "Вы действительно хотите удалить выбранных пользователей?": "Вы действительно хотите удалить выбранных пользователей?",
      "Выбрано {{selectedUsersCount}} из {{totalUsersCount}}": "Выбрано {{selectedUsersCount}} из {{totalUsersCount}}",
      "Удалить группу?": "Удалить группу?",
      "Название группы": "Название группы",
      "Переименовать группу": "Переименовать группу",
      "Переименовать": "Переименовать",
      "Без ограничений": "Без ограничений",
      "К списку пользователей": "К списку пользователей",
      "Основные настройки": "Основные настройки",
      "Вызовы и голосовая почта": "Вызовы и голосовая почта",
      "Оператор контакт-центра": "Оператор контакт-центра",
      "Оператор может принимать вызовы в группах с голосовыми очередями": "Оператор может принимать вызовы в группах с голосовыми очередями",
      "Администратор": "Администратор",
      "Администратору доступна возможность управлять настройками Виртуальной АТС компании": "Администратору доступна возможность управлять настройками Виртуальной АТС компании",
      "Не установлено ограничений исходящих вызовов": "Не установлено ограничений исходящих вызовов",
      "Включено ограничение международных вызовов": "Включено ограничение международных вызовов",
      "Включено ограничение международных и междугородних вызовов": "Включено ограничение международных и междугородних вызовов",
      "Включено ограничение всех вызовов кроме внутренних": "Включено ограничение всех вызовов кроме внутренних",
      "Выбор групп": "Выбор групп",
      "Удалить пользователя": "Удалить пользователя",
      "Дублировать": "Дублировать",
      "Загрузка списка из файла Excel": "Загрузка списка из файла Excel",
      "Для загрузки списка потребуется сформировать <b>Excel файл</b> по шаблону. Воспользуйтесь примером такого файла, скачав его себе на компьютер:": "Для загрузки списка потребуется сформировать <b>Excel файл</b> по шаблону. Воспользуйтесь примером такого файла, скачав его себе на компьютер:",
      "Скачать шаблон": "Скачать шаблон",
      "Занесите все нужные данные в полученный файл шаблона и сохраните его на своём компьютере.": "Занесите все нужные данные в полученный файл шаблона и сохраните его на своём компьютере.",
      "Загрузите полученный файл": "Загрузите полученный файл",
      "В результате их добавления": "В результате их добавления",
      "Будет списано со счета за первый день": "Будет списано со счета за первый день",
      "Что-то не так?": "Что-то не так?",
      "Загрузить другой список": "Загрузить другой список",
      "Шаблон": "Шаблон",
      "Файл архива не найден у этого пользователя.": "Файл архива не найден у этого пользователя.",
      "Скачивание файла должно начаться.": "Скачивание файла должно начаться.",
      "Выгрузки": "Выгрузки",
      "Все выгрузки": "Все выгрузки",
      "Формирование выгрузки": "Формирование выгрузки",
      "Сформировать": "Сформировать",
      "Выгрузить вместе с записями разговоров": "Выгрузить вместе с записями разговоров",
      "Результат вызова": "Результат вызова",
      "Входящая линия": "Входящая линия",
      "Email для уведомления": "Email для уведомления",
      "Принятые": "Принятые",
      "Не принятые": "Не принятые",
      "Внутренние": "Внутренние",
      "Очистить фильтры": "Очистить фильтры",
      "Запросить выгрузку": "Запросить выгрузку",
      "Скачать с текущими настройками фильтров": "Скачать с текущими настройками фильтров",
      "Скачать журнал": "Скачать журнал",
      "Протокол вызова": "Протокол вызова",
      "Кто": "Кто",
      "Кому": "Кому",
      "Выбор даты": "Выбор даты",
      "Действие": "Действие",
      "Поиск по объекту": "Поиск по объекту",
      "Операций": "Операций",
      "Объект": "Объект",
      "Данные": "Данные",
      "Управление услугами": "Управление услугами",
      "Ежемесячная плата": "Ежемесячная плата",
      "Подключить": "Подключить",
      "Отключить": "Отключить",
      "Ежемесячная абонентская плата": "Ежемесячная абонентская плата",
      "Ежедневная абонентская плата": "Ежедневная абонентская плата",
      "канал": "канал",
      "канала": "канала",
      "каналов": "каналов",
      "линий": "линий",
      "Услуга": "Услуга",
      "Количество": "Количество",
      "Абонентская плата": "Абонентская {{br}}плата",
      "Уровень {{br}}тарификации": "Уровень {{br}}тарификации",
      "Сумма": "Сумма",
      "Настроить": "Настроить",
      "специальный": "специальный",
      "внешний": "внешний",
      "домен": "домен",      
      "домена": "домена",
      "доменов": "доменов",
      "в месяц": "в месяц",
      "в день": "в день",
      "Льготный период - до {{date}}, далее {{sum}}": "Льготный период - до {{date}}, далее {{sum}}",
      "Плата {{br}}за подключение": "Плата {{br}}за подключение",
      "Подключение": "Подключение",
      "Ошибка сохранения": "Ошибка сохранения",
      "Прослушать записи": "Прослушать записи",
      "Включить у всех": "Включить у всех",
      "Выключить у всех": "Выключить у всех",
      "Записи звонков": "Записи звонков",
      "Включена запись": "Включена запись",
      "Срок хранения записи": "Срок хранения записи",
      "Срок хранения записей разговора": "Срок хранения записей разговора",
      "включена для {{sum1}} из {{sum2}} пользователей": "включена для {{sum1}} из {{sum2}} пользователей",
      "Включена для {{sum1}} из {{sum2}} пользователей": "Включена для {{sum1}} из {{sum2}} пользователей",
      "выключена": "выключена",
      "Включена аналитика речи": "Включена аналитика речи",
      "Включить запись": "Включить запись",
      "Отключить запись": "Отключить запись",
      "Включить запись с аналитикой": "Включить запись с аналитикой",
      "Отключить аналитику": "Отключить аналитику",
      "Номера FMC": "Номера FMC",
      "Подключено": "Подключено",
      "Отключено": "Отключено",
      "Ожидает подтверждения": "Ожидает подтверждения",
      "Заблокировано": "Заблокировано",
      "Поиск номера": "Поиск номера",
      "отключено": "отключено",
      "ожидает подтверждения": "ожидает подтверждения",
      "подключено": "подключено",
      "заблокировано": "заблокировано",
      "Маршрутизация": "Маршрутизация",
      "Купить новый номер": "Купить новый номер",
      "Подключить номер другого оператора": "Подключить номер другого оператора",
      "входящих линий": "входящих линий",
      "исходящих линий": "исходящих линий",
      "Управление линиями": "Управление линиями",
      "Ограничение": "Ограничение",
      "Черный список": "Черный список",
      "Белый список": "Белый список",
      "Номер другого оператора": "Номер другого оператора",
      "Не зарегистрирован": "Не зарегистрирован",
      "Зарегистрирован": "Зарегистрирован",
      "Номер подтвержден": "Номер подтвержден",
      "Номер не подтвержден": "Номер не подтвержден",
      "Все вызовы": "Все вызовы",
      "Завершение вызова": "Завершение вызова",
      "Голосовое меню": "Голосовое меню",
      "Новое правило": "Новое правило",
      "Настройка номера": "Настройка номера",
      "Не настроен": "Не настроен",
      "Использовать по умолчанию": "Использовать по умолчанию",
      "Настроен общий чёрный список, заданные здесь ограничения будут суммироваться с ним": "Настроен общий чёрный список, <br/>заданные здесь ограничения будут суммироваться с ним",
      "Настроен белый список. Все вызовы с номеров, не входящих в белый список, будут отбиваться.": "Настроен белый список. Все вызовы с номеров,<br/> не входящих в белый список, будут отбиваться.",
      "Правило перевода вызова": "Правило перевода вызова",
      "Вызов будет сброшен.": "Вызов будет сброшен.",
      "Абонент получит сигнал «линия занята».": "Абонент получит сигнал «линия занята».",
      "Вызовы будут переводиться только в указанное время": "Вызовы будут переводиться только в указанное время",
      "По префиксам": "По префиксам",
      "Вызовы будут переводиться только если номер звонящего содержит определённые цифры (код региона или сотовой сети)": "Вызовы будут переводиться только если номер звонящего содержит определённые цифры (код региона или сотовой сети)",
      "Изменить объект переадресации": "Изменить объект переадресации",
      "На пользователя": "На пользователя",
      "На группу обработки вызовов": "На группу обработки вызовов",
      "На голосовой сценарий IVR": "На голосовой сценарий IVR",
      "На голосовую почту пользователя или группы": "На голосовую почту пользователя или группы",
      "На факс пользователя или группы": "На факс пользователя или группы",
      "На номер частного маршрута": "На номер частного маршрута",
      "Выберите объект, на который будут переводиться пользователи": "Выберите объект, на который будут переводиться пользователи",
      "номер": "номер",
      "номера": "номера",
      "номеров": "номеров",
      "Тип получателя": "Тип получателя",
      "Получатель": "Получатель",      
      "Номер по умолчанию для исходящих": "Номер по умолчанию для исходящих",      
      "Префиксы": "Префиксы",
      "Префикс": "Префикс",
      "Не заданы": "Не заданы",
      "Добавить правило": "Добавить правило",
      "Пользователя": "Пользователя",
      "Группу": "Группу",
      "Факс": "Факс",
      "Редактирование черного списка": "Редактирование черного списка",
      "Редактирование белого списка": "Редактирование белого списка",
      "Такой номер уже есть в списке": "Такой номер уже есть в списке",
      "Введите корректный номер": "Введите корректный номер",
      "Время конца периода предшествует времени его начала": "Время конца периода предшествует времени его начала",
      "Правило активно": "Правило активно",
      "Алгоритм распределения вызовов в группе": "Алгоритм распределения вызовов в группе",
      "Группы пользователя": "Группы пользователя",
      "Действия на цифрах": "Действия на цифрах",
      "Пример: 495, 499 для Москвы": "Пример: 495, 499 для Москвы",      
      "Вызовы распределяются в случайном порядке": "Вызовы распределяются в случайном порядке",
      "Вызовы распределяются по приоритету пользователей": "Вызовы распределяются по приоритету пользователей",
      "Вызовы направляются всем пользователям одновременно": "Вызовы направляются всем пользователям одновременно",
      "меню": "меню",

      "Проверка настроенных правил": "Проверка настроенных правил",
      "Вызовы на номера, которые Вы подключили у других операторов, будут попадать на Виртуальную АТС с возможностью использования всех преимуществ услуги (маршрутизация, IVR, запись разговоров, контакт-центр и др.)": "Вызовы на номера, которые Вы подключили у других операторов, будут попадать на Виртуальную АТС с возможностью использования всех преимуществ услуги (маршрутизация, IVR, запись разговоров, контакт-центр и др.)",
      "Номер для подключения": "Номер для подключения",
      "Вариант подключения": "Вариант подключения",
      "Входящие вызовы с SIP-регистрацией": "Входящие вызовы с SIP-регистрацией",
      "Входящие и исходящие вызовы с SIP-регистрацией": "Входящие и исходящие вызовы с SIP-регистрацией",
      "Настройка переадресации на учетную запись SIP на нашем сервере": "Настройка переадресации на учетную запись SIP на нашем сервере",
      "Получите у вашего оператора учетные данные для подключения по SIP для номера и укажите их в форме ниже.": "Получите у вашего оператора учетные данные для подключения по SIP для номера и укажите их в форме ниже.",
      "Имя авторизации": "Имя авторизации",
      "Включен": "Включен",
      "Выключен": "Выключен",
      "Статус маршрута": "Статус маршрута",
      "Статус регистрации": "Статус регистрации",
      "обновить": "обновить",
      "Обновить": "Обновить",
      "SIP-сервер": "SIP-сервер",
      "порт": "порт",
      "Имя авторизации (AuthID) для SIP-регистрации. Если не указано, будет использоваться логин": "Имя авторизации (AuthID) для SIP-регистрации. Если не указано, будет использоваться логин",
      "Запросите у вашего оператора для данного номера переадресацию на адрес (SIP-URI), указанный ниже. Доступна только возможность приема входящих вызовов.": "Запросите у вашего оператора для данного номера переадресацию на адрес (SIP-URI), указанный ниже. Доступна только возможность приема входящих вызовов.",
      "SIP-URI для переадресации": "SIP-URI для переадресации",
      "Я согласен с {{changeMonthlyType}} ежемесячной абонентской платы за подключение внешнего номера {{chargeTypeText}} на сумму: {{sum}} ₽ в месяц": "Я согласен с {{changeMonthlyType}} ежемесячной абонентской платы за подключение внешнего номера {{chargeTypeText}} на сумму: {{sum}} ₽ в месяц",
      "Количество символов, которое необходимо отрезать от начала номера": "Количество символов, которое необходимо отрезать от начала номера",
      "Символы, которые необходимо добавить в начало номера": "Символы, которые необходимо добавить в начало номера",
      "Управление частными маршрутами": "Управление частными маршрутами",
      "Исходящие линии": "Исходящие линии",
      "Входящие вызовы на SIP-Trunk": "Входящие вызовы на SIP-Trunk",
      "Настроены": "Настроены",
      "Не настроены": "Не настроены",
      "Настройка количества линий": "Настройка количества линий",
      "маршрут": "маршрут",
      "маршрута": "маршрута",
      "маршрутов": "маршрутов",
      "Для подключения частного маршрута (SIP-trunk) обратитесь к Вашему менеджеру по продажам или в службу технической поддержки": "Для подключения частного маршрута (SIP-trunk) обратитесь к Вашему менеджеру по продажам или в службу технической поддержки",
      "Управление маршрутом": "Управление маршрутом",
      "Вызов в маршрут": "Вызов в маршрут",
      "Выбор маршрута": "Выбор маршрута",
      "Выражение для вызываемого номера": "Выражение для вызываемого номера",
      "Только для группы": "Только для группы",
      "Основной контактный номер": "Основной контактный номер",
      "Подстановка номера для вызывающего абонента домена": "Подстановка номера для вызывающего абонента домена",
      "Символов": "Символов",
      "Количество символов, отрезаемых от начала вызываемого номера после выбора маршрута": "Количество символов, отрезаемых от начала вызываемого номера после выбора маршрута",
      "Авторизация и переадресация": "Авторизация и переадресация",
      "Авторизационное имя": "Авторизационное имя",
      "Транспортный протокол": "Транспортный протокол",
      "Период обновления": "Период обновления",
      "Вызов из маршрута": "Вызов из маршрута",
      "Международный": "Международный",
      "Международные и междугородние вызовы": "Международные и междугородние вызовы",
      "Все, кроме внутренних": "Все, кроме внутренних",
      "Ограничение направлений транзитных вызовов в ССОП": "Ограничение направлений транзитных вызовов в ССОП",
      "Подстановка номера для транзитного вызова в ССОП": "Подстановка номера для транзитного вызова в ССОП",
      "Номера для исходящих вызовов": "Номера для исходящих вызовов",
      "Разрешение подстановки номера линии из From": "Разрешение подстановки номера линии из From",
      "Использование переадресации": "Использование переадресации",
      "Тип заголовка, принимающего авторизацию": "Тип заголовка, принимающего авторизацию",
      "Не использовать": "Не использовать",
      "Тип заголовка истории переадресации": "Тип заголовка истории переадресации",
      "Ежедневная плата": "Ежедневная плата",
      "Кроме того, со счёта будет списана разовая плата за подключение": "Кроме того, со счёта будет списана разовая плата за подключение",
      "Новая кампания": "Новая кампания",
      "Основное": "Основное",
      "Попыток дозвона": "Попыток дозвона",
      "Метод дозвона": "Метод дозвона",
      "Группа обработки вызовов": "Группа обработки вызовов",
      "Макс. время дозвона в минутах": "Макс. время дозвона в минутах",
      "Ссылка на информацию о кампании": "Ссылка на информацию о кампании",
      "Редактирование статусов будет доступно после сохранения": "Редактирование статусов будет доступно после сохранения",
      "Дата и время начала:": "Дата и время начала:",
      "Дата и время окончания:": "Дата и время окончания:",
      "Создать кампанию": "Создать кампанию",
      "Одна попытка длится 20 секунд": "Одна попытка длится 20 секунд",
      "Последовательный": "Последовательный",
      "Параллельный": "Параллельный",
      "Удаление исходящей кампании": "Удаление исходящей кампании",
      "Вы действительно хотите удалить?": "Вы действительно хотите удалить?",
      "Создание исходящей кампании": "Создание исходящей кампании",
      "Список обзвона": "Список обзвона",
      "Статусы вызовов": "Статусы вызовов",
      "Успешный вызов": "Успешный вызов",
      "Нет ответа": "Нет ответа",
      "Новый статус": "Новый статус",
      "Введите название статуса": "Введите название статуса",
      "Добавить клиента": "Добавить клиента",
      "Добавить одного": "Добавить одного",
      "Загрузить из файла": "Загрузить из файла",
      "Скачать список": "Скачать список",
      "Очистить список": "Очистить список",
      "Примечание": "Примечание",
      "Примечания": "Примечания",
      "Клиент": "Клиент",
      "В списке {{total}} клиентов. Обзвонили {{called}}": "В списке <b>{{total}}</b> клиентов. Обзвонили <b>{{called}}</b>",
      "Неизвестная группа": "Неизвестная группа",
      "Назад": "Назад",
      "Запустить": "Запустить",
      "Общие настройки": "Общие настройки",
      "SIP-устройства": "SIP-устройства",
      "Начальное состояние": "Начальное состояние",
      "Удаление": "Удаление",
      "Общие": "Общие",
      "Добавление клиента": "Добавление клиента",
      "Ошибки": "Ошибки",
      "Добавить": "Добавить",
      "Имя клиента": "Имя клиента",
      "Массовое создание клиентов": "Массовое создание клиентов",
      "Голосовые сценарии IVR": "Голосовые сценарии IVR",
      "Добавить сценарий": "Добавить сценарий",
      "Создание IVR": "Создание IVR",
      "Название IVR": "Название IVR",
      "Значение обязательно": "Значение обязательно",
      "Настроить маршрутизацию": "Настроить маршрутизацию",
      "Список меню в сценарии": "Список меню в сценарии",
      "Список меню": "Список меню",
      "новое меню": "новое меню",
      "Новое меню": "Новое меню ",
      "Использованы в сценарии:": "Использованы в сценарии:",
      "Не использованы в сценарии:": "Не использованы в сценарии:",
      "Удалить меню?": "Удалить меню?",
      "Название меню": "Название меню",
      "Поддерживаются только форматы mp3. wav": "Поддерживаются только форматы mp3. wav",
      "Аудиосообщения ": "Аудиосообщения ",
      "Сейчас система попробует до Вас дозвониться, чтобы Вы могли записать голосовое приветствие для выбранного пункта": "Сейчас система попробует до Вас дозвониться, чтобы Вы могли записать голосовое приветствие для выбранного пункта",
      "Все голосовые сценарии": "Все голосовые сценарии",
      "Полноэкранный режим": "Полноэкранный режим",
      "Выйти из полноэкранного режима": "Выйти из полноэкранного режима",
      "Удалить голосовой сценарий": "Удалить голосовой сценарий",
      "Начало звонка": "Начало звонка",
      "Основное меню": "Основное меню",
      "Данное аудиосообщение будет проигрываться целиком всякий раз при входе в данное меню": "Данное аудиосообщение будет проигрываться целиком всякий раз при входе в данное меню",
      "Выбор цифры": "Выбор цифры",
      "Удалить ветку": "Удалить ветку",
      "Все уровни меню сохранятся для повторного использования": "Все уровни меню сохранятся для повторного использования",
      "Удалить кнопку": "Удалить кнопку",
      "Изменить тип действия": "Изменить тип действия",
      "Выберите тип действия ": "Выберите тип действия ",
      "Переадресация": "Переадресация",
      "На факсы, телефоны и голосовую почту пользователей и групп, произвольные номера": "На факсы, телефоны и голосовую почту пользователей и групп, произвольные номера",
      "Вызовы распределяется в случайном порядке": "Вызовы распределяется в случайном порядке",
      "Вызовы распределяются всем пользователям одновременно": "Вызовы распределяются всем пользователям одновременно",
      "Произвольный номер": "Произвольный номер",
      "Аудиосообщение": "Аудиосообщение",
      "Например, рекламное": "Например, рекламное",
      "Другое меню": "Другое меню",
      "Любое меню из этого сценария": "Любое меню из этого сценария",
      "Выберите меню": "Выберите меню",
      "Возврат к предыдущему меню": "Возврат к предыдущему  меню",
      "Клиенту будет озвучено меню уровнем выше": "Клиенту будет озвучено меню уровнем выше",
      "Донабор внутреннего номера сотрудника": "Донабор внутреннего номера сотрудника",
      "Донабор внутреннего номера пользователя": "Донабор внутреннего номера пользователя",
      "Клиент сможет набрать внутренний номер сотрудника": "Клиент сможет набрать внутренний номер сотрудника",
      "Клиент будет отключен": "Клиент будет отключен",
      "Повтор меню": "Повтор меню",
      "Текущее меню озвучится сначала": "Текущее меню озвучится сначала",
      "Незадействованные в сценарии кнопки": "Незадействованные в сценарии кнопки",
      "Таймаут {{count}} сек": "Таймаут {{count}} сек",
      "Звуковой файл": "Звуковой файл",
      "Добавить кнопку": "Добавить кнопку",
      "Кнопка": "Кнопка",
      "Номер не назначен": "Номер не назначен",
      "Сценариев": "Сценариев",
      "Удалить сценарий IVR": "Удалить сценарий IVR",
      "Название сценария": "Название сценария",
      "Загрузите или запишите по телефону основной аудиофайл сценария": "Загрузите или запишите по телефону основной аудиофайл сценария",
      "Перевод на произвольный номер": "Перевод на произвольный номер",
      "Аудио": "Аудио",
      "По умолчанию": "По умолчанию",
      "Группы": "Группы",
      "Выберите действие": "Выберите действие",
      "Группы обработки вызовов": "Группы обработки вызовов",
      "Имя группы": "Имя группы",
      "Группы обработки вызовов нужны для приёма звонков сразу несколькими пользователями, и создания голосовых очередей": "Группы обработки вызовов нужны для приёма звонков сразу несколькими пользователями, и создания голосовых очередей",
      "Допустимы только цифро-буквенные символы, пробелы, а также символы - _ ( ) + . \"": "Допустимы только цифро-буквенные символы, пробелы, а также символы - _ ( ) + . \"",
      "Допустимо максимум 5 символов": "Допустимо максимум 5 символов",
      "Сообщения операторам": "Сообщения операторам",
      "Статистика реального времени": "Статистика реального времени",
      "VIP-номера": "VIP-номера",
      "Групп": "Групп",
      "Распределение вызовов": "Распределение вызовов",
      "Показать всех": "Показать всех",
      "Поиск группы по имени или номеру": "Поиск группы по имени или номеру",    
      "Написать сообщение": "Написать сообщение",
      "Прочитали": "Прочитали",
      "Отчет о прочтении": "Отчет о прочтении",
      "Не прочитали": "Не прочитали",
      "Заголовок": "Заголовок",
      "Текст сообщения": "Текст сообщения",
      "Группа получателей": "Группа получателей",
      "Не выбраны группы": "Не выбраны группы",
      "В выбранных группах нет операторов": "В выбранных группах нет операторов",
      "Удалить сообщение?": "Удалить сообщение?",
      "нет групп": "нет групп",
      "Сообщение операторам": "Сообщение операторам",
      "Клиенты с VIP номерами имеют приоритет перед обычными при обслуживании в голосовых очередях. Если в голосовой очереди несколько VIP клиентов, то очередность будет определяться приоритетом: чем ниже значение, тем выше приоритет и тем быстрее обслуживается вызов": "Клиенты с VIP номерами имеют приоритет перед обычными при обслуживании в голосовых очередях. Если в голосовой очереди несколько VIP клиентов, то очередность будет определяться приоритетом: чем ниже значение, тем выше приоритет и тем быстрее обслуживается вызов",
      "VIP-номер": "VIP-номер",
      "Комментарий": "Комментарий",
      "Не введен комментарий": "Не введен комментарий",
      "Заполните номер": "Заполните номер",
      "Добавить VIP-номер": "Добавить VIP-номер",
      "По группам": "По группам",
      "По операторам": "По операторам",
      "В сети": "В сети",
      "Не в сети": "Не в сети",
      "Операции": "Операции",
      "Очередь": "Очередь",
      "На работе": "На работе",
      "Ожидает звонок": "Ожидает звонок",
      "Вх. вызов": "Вх. вызов",
      "Исх. вызов": "Исх. вызов",
      "Поствыз. обр": "Поствыз. обр",
      "Перерыв": "Перерыв",
      "Исх. кампания": "Исх. кампания",
      "Подключиться": "Подключиться",
      "Конференция": "Конференция",
      "Расход": "Расход",
      "Приход": "Приход",
      "За текущие сутки": "За текущие сутки",
      "Детализация расходов": "Детализация расходов",
      "Состояние на начало текущего месяца": "Состояние на начало текущего месяца",
      "Приход в текущем месяце": "Приход в текущем месяце",
      "Текущее состояние": "Текущее состояние",
      "Расход МГП за текущие сутки": "Расход МГП за текущие сутки",
      "Суммы указаны без учета скидки по тарифу": "Суммы указаны без учета скидки по тарифу",
      "Состояние счетчиков минут": "Состояние счетчиков минут",
      "Остаток пакета минут": "Остаток пакета минут",
      "Обещанный платеж": "Обещанный платеж",
      "Не введена сумма обещанного платежа": "Не введена сумма обещанного платежа",
      "Допустимы только цифры": "Допустимы только цифры",
      "Величина обещанного платежа должна быть от 50 до 5000 рублей": "Величина обещанного платежа должна быть от 50 до 5000 рублей",
      "Снятие обещанного платежа произойдет автоматически через 5 календарных дней. Максимальная сумма обещанного платежа 5000.00 руб": "Снятие обещанного платежа произойдет автоматически через 5 календарных дней. Максимальная сумма обещанного платежа 5000.00 руб",
      "Предел кредита": "Предел кредита",
      "Минимальный баланс": "Минимальный баланс",
      "Параметр обязателен": "Параметр обязателен",
      "Текущая схема взаиморасчетов [Авансовая схема] не позволяет выполнить операцию": "Текущая схема взаиморасчетов [Авансовая схема] не позволяет выполнить операцию",
      "Списания": "Списания",
      "Сутки": "Сутки",
      "Полгода": "Полгода",
      "Год": "Год",
      "Операция": "Операция",
      "Возврат обещанного платежа": "Возврат обещанного платежа",
      "Обычный документ": "Обычный документ",
      "Банк": "Банк",
      "Суммы указаны без учёта скидки по тарифу": "Суммы указаны без учёта скидки по тарифу",
      "Платежи": "Платежи",
      "Выставить счет": "Выставить счёт",
      
      "Адресная книга компании": "Адресная книга компании",
      "Создать контакт": "Создать контакт",
      "Номер для быстрого созвона": "Номер для быстрого созвона",
      "Для вызова контакта наберите *<короткий номер>. Короткий номер может содержать до пяти цифр": "Для вызова контакта наберите *<короткий номер>. Короткий номер может содержать до пяти цифр",
      "Имя пользователя для SIP-URI содержит запрещенные символы": "Имя пользователя для SIP-URI содержит запрещенные символы",
      "Массовое создание контактов": "Массовое создание контактов",
      "Загруженный блок успешно обработан!": "Загруженный блок успешно обработан!",
      "Готовится к созданию": "Готовится к созданию",
      "Готовы к созданию": "Готовы к созданию",
      "С ошибкой": "С ошибкой",
      "Список контактов с ошибками": "Список контактов с ошибками",
      "Домашний телефон": "Домашний телефон",
      "Мобильный телефон": "Мобильный телефон",
      "Рабочий телефон": "Рабочий телефон",
      "Другой телефон": "Другой телефон",
      "Контакт с таким именем уже существует": "Контакт с таким именем уже существует",
      "Номер занят общим контактом": "Номер занят общим контактом",
      "Загрузить другой файл?": "Загрузить другой файл?",
      "контакт": "контакт",
      "контакта": "контакта",
      "контактов": "контактов",
      "Список позиций с ошибками": "Список позиций с ошибками",
      "Неопознанная ошибка PIN": "Неопознанная ошибка PIN",
      "Номера для быстрого созвона": "Номера для быстрого созвона",
      "Ошибка": "Ошибка",
      "Переместить в группу": "Переместить в группу",
      "Контактные номера": "Контактные номера",
      "Имя не может длиннее 200 символов": "Имя не может длиннее 200 символов",
      "Вы действительно хотите удалить пользователя?": "Вы действительно хотите удалить пользователя?",
      "Редактировать контакт": "Редактировать контакт",
      "Удалить контакт": "Удалить контакт",
      "Отчеты": "Отчеты",
      "Аналитика речи выключена": "Аналитика речи выключена",
      "Включить аналитику": "Включить аналитику",
      "Посмотреть отчет": "Посмотреть отчет",
      "Включение Записи разговоров": "Включение Записи разговоров",
      "Отключение Записи разговоров": "Отключение Записи разговоров",
      "Включение Аналитики речи": "Включение Аналитики речи",
      "Отключение Аналитики речи": "Отключение Аналитики речи",
      "Включение": "Включение",
      "Отключение": "Отключение",
      "Отключение бесплатное": "Отключение бесплатное",
      "Не уведомлять меня об изменении стоимости в течение данной сессии": "Не уведомлять меня об изменении стоимости в течение данной сессии",
      "Установив данный пункт вы подтверждаете, что информированы о том, что изменения настроек влекут за собой изменение стоимости услуг": "Установив данный пункт вы подтверждаете, что информированы о том, что изменения настроек влекут за собой изменение стоимости услуг",
      "Включение {{service}} для <b>{{count}} {{usersString}}</b> увеличит платежи": "Включение {{service}} для <b>{{count}} {{usersString}}</b> увеличит платежи",
      "Аналитики речи": "Аналитики речи",
      "Записи разговоров": "Записи разговоров",
      "Выключение {{service}} для <b>{{count}} {{usersString}}</b> уменьшит ежемесячные платежи": "Выключение {{service}} для <b>{{count}} {{usersString}}</b> уменьшит ежемесячные платежи",
      "Дополнительно будет выключена услуга {{service}} для": "Дополнительно будет выключена услуга {{service}} для",
      "Дополнительно будет включена услуга {{service}} для": "Дополнительно будет включена услуга {{service}} для",
      "Отчеты и статистика": "Отчеты и статистика",
      "Тип отчета": "Тип отчета",
      "По операторам в группе": "По операторам в группе",
      "Хронологический по группам": "Хронологический по группам",
      "Интервал разбиения": "Интервал разбиения",
      "мин": "мин",
      "сек": "сек",
      "Параметр": "Параметр",
      "Число вызовов": "Число вызовов",
      "Среднее время разговора": "Среднее время разговора",
      "Среднее время реакции": "Среднее время реакции",
      "Среднее время до потери вызова": "Среднее время до потери вызова",
      "Среднее время до приёма вызова": "Среднее время до приёма вызова",
      "Уровень обслуживания": "Уровень обслуживания",
      "Уровень QoS": "Уровень QoS",
      "Хронологический по состояниям": "Хронологический по состояниям",
      "По вызовам": "По вызовам",
      "Занятие входящих линий": "Занятие входящих линий",
      "Вход. линии": "Вход. линии",
      "Интервалы": "Интервалы",
      "По дням": "По дням",
      "По часам": "По часам",     
      "Вызовы из частных маршрутов": "Вызовы из частных маршрутов",
      "Часы": "Часы",
      "В отчет попадают вызовы, пришедшие из частных маршрутов и ушедшие в обычный маршрут.": "В отчет попадают вызовы, пришедшие из частных маршрутов и ушедшие в обычный маршрут.",
      "Прогноз количества операторов": "Прогноз количества операторов",
      "Время ожидания (сек.)": "Время ожидания (сек.)",
      "По темам соединений": "По темам соединений",
      "Сравнительный по группам": "Сравнительный по группам",
      "Интервал QoS": "Интервал QoS",
      "По сменам оператора": "По сменам оператора",
      "Сводный по состояниям": "Сводный по состояниям",
      "Операторы": "Операторы",     
      "За сегодня": "За сегодня",
      "За 7 дней": "За 7 дней",
      "За 30 дней": "За 30 дней",
      "Группы операторов": "Группы операторов",
      "Число принятых вызовов": "Число принятых вызовов",
      "Число потерянных вызовов": "Число потерянных вызовов",
      "Общее время разговоров": "Общее время разговоров",
      "Среднее время разговоров": "Среднее время разговоров",
      "Среднее время реакции на вызов": "Среднее время реакции на вызов",
      "Потерянные": "Потерянные",
      "Успешные": "Успешные",
      "Общая сумма": "Общая сумма",
      "Состояние": "Состояние",
      "Вызывается": "Вызывается",
      "Ожидает звонка": "Ожидает звонка",
      "Поствызывная обработка": "Поствызывная обработка",
      "Отошёл": "Отошёл",
      "Начало интервала": "Начало интервала",
      "Конец интервала": "Конец интервала",
      "Продолжительность": "Продолжительность",
      "Номер телефона": "Номер телефона",
      "Время звонка": "Время звонка",
      "Статус звонка": "Статус звонка",
      "Завершён абонентом": "Завершён абонентом",
      "Завершён оператором": "Завершён оператором",
      "Время ожидания": "Время ожидания",
      "Время разговора": "Время разговора",
      "№ интервала": "№ интервала",
      "Максимальное число одновременно занятых линий": "Максимальное число одновременно занятых линий",
      "Число соединений": "Число соединений",
      "Общее число соединений": "Общее число соединений",
      "Всего вызовов": "Всего вызовов",
      "Количество операторов": "Количество операторов",
      "Вероятность соединения с оператором без постановки в очередь": "Вероятность соединения с оператором без постановки в очередь",
      "Среднее время ожидания клиента в очереди": "Среднее время ожидания клиента в очереди",
      "Средняя длина очереди": "Средняя длина очереди",
      "Интенсивность нагрузки на оператора": "Интенсивность нагрузки на оператора",
      "Темы соединений": "Темы соединений",
      "Число поступивших вызовов": "Число поступивших вызовов",  
      "Max длина очереди": "Max длина очереди",
      "Среднее время ожидания до потери вызова": "Среднее время ожидания до потери вызова",
      "Максимальное время ожидания до потери вызова": "Максимальное время ожидания до потери вызова",
      "Начало смены": "Начало смены",
      "Конец смены": "Конец смены",
      "Длительность смены": "Длительность смены",
      "Всего вызовов за смену": "Всего вызовов за смену",
      "Принято вызовов": "Принято вызовов",
      "Пропущено вызовов": "Пропущено вызовов",
      "Суммарная длительность разговоров": "Суммарная длительность разговоров",
      "Средняя длительность разговоров": "Средняя длительность разговоров",
      "Входящий вызов": "Входящий вызов",
      "Исходящий вызов": "Исходящий вызов",     
      "В перерыве": "В перерыве",
      "Отошел": "Отошел",
      "Исходящая кампания": "Исходящая кампания",
      "Итого": "Итого",     
      "Нет данных": "Нет данных",      
      "Время QoS, сек": "Время QoS, сек",
      "нет операторов": "нет операторов",
      "нет линий": "нет линий",
      "По оператору": "По оператору",
      "Сред": "Сред",
      "Макс": "Макс",
      "Кол-во": "Кол-во",
      "Не дождался": "Не дождался",
      "нет": "нет",
      "Заполните все поля": "Заполните все поля",
      "Полный список": "Полный список",
      "Расходы за": "Расходы за",
      "Полная статистика": "Полная статистика",
      "Подключено пользователей": "Подключено пользователей",
      "бесплатных": "бесплатных",
      "Пополнения - {{income}} руб., расход - {{expenses}} руб.": "Пополнения - {{income}} руб., расход - {{expenses}} руб.",
      "Подключенные услуги": "Подключенные услуги",
      "Можно подключить": "Можно подключить",
      "Узнать больше": "Узнать больше",
      "Быстрый переход": "Быстрый переход",
      "Управление": "Управление",
      "декабрь": "декабрь",
      "январь": "январь",
      "февраль": "февраль",
      "март": "март",
      "апрель": "апрель",
      "май": "май",
      "июнь": "июнь",
      "июль": "июль",
      "август": "август",
      "сентябрь": "сентябрь",
      "ноябрь": "ноябрь",
      
      "Автоинформатор": "Автоинформатор",
      "Заказ звонка": "Заказ звонка",
      "Исходящие каналы": "Исходящие каналы",
      "Интеграция с системами CRM": "Интеграция с системами CRM",
      "Входящие каналы": "Входящие каналы",
      "Использование внешнего номера для входящих вызовов": "Использование внешнего номера для входящих вызовов",
      "Функции оператора ВКЦ": "Функции оператора ВКЦ",
      "Использование внешнего номера для исходящих вызовов": "Использование внешнего номера для исходящих вызовов",
      "Частный маршрут": "Частный маршрут",
      "Выборочная запись разговоров": "Выборочная запись разговоров",
      "Дополнительный пользователь": "Дополнительный пользователь",
      "Виртуальный Контакт Центр": "Виртуальный Контакт Центр",
      "Ежемесячная абонентская плата за услугу «Новая телефония»": "Ежемесячная абонентская плата за услугу «Новая телефония»",
      "Добровольная блокировка": "Добровольная блокировка",
      "Коллтрекинг, Лид и Чат": "Коллтрекинг, Лид и Чат",
      "Обещанный платёж": "Обещанный платёж",
      "Речевая аналитика": "Речевая аналитика",
      "Данные всех звонков автоматически попадают в вашу CRM для комфортной работы ваших сотрудников с клиентами": "Данные всех звонков автоматически попадают в вашу CRM для комфортной работы ваших сотрудников с клиентами",
      "Аналитические данные и отчеты по вызовам, эффективности сотрудников компании": "Аналитические данные и отчеты по вызовам, эффективности сотрудников компании",
      "Новые возможности контактного центра: организация очереди абонентов, обслуживание VIP-клиентов первыми, расширенная отчетность по операторам, возможность супервизора подключиться в разговор к оператору.": "Новые возможности контактного центра: организация очереди абонентов, обслуживание VIP-клиентов первыми, расширенная отчетность по операторам, возможность супервизора подключиться в разговор к оператору.",
      "Абонентская плата за каждый дополнительный номер АВС": "Абонентская плата за каждый дополнительный номер АВС",
      "Для каждого сотрудника, отдела или филиала можно выделить отдельный городской номер, вам всегда смогут дозвониться": "Для каждого сотрудника, отдела или филиала можно выделить отдельный городской номер, вам всегда смогут дозвониться",
      "Ежемесячная абонентская плата за услугу «Виртуальная АТС»": "Ежемесячная абонентская плата за услугу «Виртуальная АТС»",
      "Пакет минут исходящей связи, который расходуется на исходящие и переадресованные вызовы на территории Российской Федерации": "Пакет минут исходящей связи, который расходуется на исходящие и переадресованные вызовы на территории Российской Федерации",
      "Обратный звонок": "Обратный звонок",
      "Виджет позволяет посетителям сайта быстро оставить заявку на обратный звонок в любое время": "Виджет позволяет посетителям сайта быстро оставить заявку на обратный звонок в любое время",
      "Подключено сценариев": "Подключено сценариев",
      "Активных": "Активных",
      "запланированных": "запланированных",
      "Подключено каналов": "Подключено каналов",
      "Количество услуг": "Количество услуг",
      "Не подключено": "Не подключено",
      
      "Отображаемое в системе название": "Отображаемое в системе название",
      "Часовой пояс": "Часовой пояс",
      "Номер для исходящих вызовов по умолчанию": "Номер для исходящих вызовов по умолчанию",
      "Не указан": "Не указан",
      "Номер для перехвата вызовов": "Номер для перехвата вызовов",
      "Номер для перехвата вызовов - Электронная почта для уведомлений": "Номер для перехвата вызовов - Электронная почта для уведомлений",
      "Электронная почта для уведомлений - Адрес электронной почты должен быть в формате имя_пользователя@имя_домена": "Электронная почта для уведомлений - Адрес электронной почты должен быть в формате имя_пользователя@имя_домена",
      "Уведомлять, когда баланс ниже": "Уведомлять, когда баланс ниже",
      "Для опции «Уведомлять, когда баланс ниже» поле email не может быть пустым ": "Для опции «Уведомлять, когда баланс ниже» поле email не может быть пустым ",
      "Ограничение направления вызовов для новых пользователей": "Ограничение направления вызовов для новых пользователей",
      "Запрет международных вызовов": "Запрет международных вызовов",
      "Запрет всех вызовов, кроме внутренних": "Запрет всех вызовов, кроме внутренних",
      "Запрет международных и междугородних вызовов": "Запрет международных и междугородних вызовов",
      "Запрет всех вызовов кроме внутренних": "Запрет всех вызовов кроме внутренних",
      "Вызовы по всем направлениям, доступным по тарифному плану ": "Вызовы по всем направлениям, доступным по тарифному плану ",
      "Вызовы по всем направлениям, кроме международных": "Вызовы по всем направлениям, кроме международных",
      "Вызовы по всем направлениям, кроме международных и междугородних по отношению к текущему домену": "Вызовы по всем направлениям, кроме международных и междугородних по отношению к текущему домену",
      "Только внутренние вызовы внутри домена": "Только внутренние вызовы внутри домена",
      "Вы можете выбрать один из сценариев ограничения исходящих вызовов, он будет использоваться по умолчанию для всех пользователей домена. Можно переопределить эти ограничения в": "Вы можете выбрать один из сценариев ограничения исходящих вызовов, он будет использоваться по умолчанию для всех пользователей домена. Можно переопределить эти ограничения в",
      "Настройки голосовых сценариев": "Настройки голосовых сценариев",

      "Количество ошибок ввода": "Количество ошибок ввода",
      "Количество ошибок, которое вызывающий абонент может совершить в голосовом сценарии (например, нажатие неопределенной клавиши). При превышении установленного параметра телефонный вызов сбрасывается.": "Количество ошибок, которое вызывающий абонент может совершить в голосовом сценарии (например, нажатие неопределенной клавиши). При превышении установленного параметра телефонный вызов сбрасывается.",
      "Таймаут": "Таймаут",
      "Промежуток времени, в течение которого вызывающему абоненту следует выбрать интересующий его пункт голосового сценария.": "Промежуток времени, в течение которого вызывающему абоненту следует выбрать интересующий его пункт голосового сценария.",
      "Команды тонового набора": "Команды тонового набора",
      "Перевод вызова": "Перевод вызова",
      "Укажите комбинацию клавиш в тональном наборе для перевода вызова на другого пользователя или произвольный телефонный номер. Во время разговора набирается: <заданная комбинация> <телефонный номер> #": "Укажите комбинацию клавиш в тональном наборе для перевода вызова на другого пользователя или произвольный телефонный номер. Во время разговора набирается: <заданная комбинация> <телефонный номер> #",
      "Перевод на факс-аппарат": "Перевод на факс-аппарат",
      "Укажите комбинацию клавиш в тональном наборе для подключения другого пользователя к конференции. Во время разговора набирается: <заданная комбинация> <телефонный номер> #": "Укажите комбинацию клавиш в тональном наборе для подключения другого пользователя к конференции. Во время разговора набирается: <заданная комбинация> <телефонный номер> #",
      "Отключение от конференции": "Отключение от конференции",
      "Укажите комбинацию клавиш в тональном наборе для отключения участника конференции. Во время разговора набирается: <заданная комбинация> <телефонный номер участника> #": "Укажите комбинацию клавиш в тональном наборе для отключения участника конференции. Во время разговора набирается: <заданная комбинация> <телефонный номер участника> #",
      "Удержание вызова": "Удержание вызова",
      "Сценарий по умолчанию.": "Сценарий по умолчанию.",
      "E-mail для уведомлений": "E-mail для уведомлений",
      "Укажите комбинацию клавиш в тональном наборе для перевода вызова на факс. Во время разговора набирается: <заданная комбинация>": "Укажите комбинацию клавиш в тональном наборе для перевода вызова на факс. Во время разговора набирается: <заданная комбинация>",
      "Укажите комбинацию клавиш в тональном наборе для постановки вызова на удержание и снятия вызова с удержания. Во время разговора (или на удержании) набирается: <заданная комбинация>": "Укажите комбинацию клавиш в тональном наборе для постановки вызова на удержание и снятия вызова с удержания. Во время разговора (или на удержании) набирается: <заданная комбинация>",
      "Выберите номер": "Выберите номер",
      "Входит в пул доменов": "Входит в пул доменов",
      "маршрутный номер": "маршрутный номер",
      "настройках пользователя": "настройках пользователя",

      "Приветствия и музыка": "Приветствия и музыка",
      "Здесь можно настроить голосовые сообщения и мелодии, применяемые на весь домен, по умолчанию.": "Здесь можно настроить голосовые сообщения и мелодии, применяемые на весь домен, по умолчанию.",
      "Мелодия на удержании вызова": "Мелодия на удержании вызова",
      "При постановке собеседника на удержание ему будет проигрываться загруженная мелодия.": "При постановке собеседника на удержание ему будет проигрываться загруженная мелодия.",
      "Выбор мелодии": "Выбор мелодии",
      "Мелодия": "Мелодия",
      "Настройки по умолчанию можно изменить для отдельных пользователей.": "Настройки по умолчанию можно изменить для отдельных пользователей.",
      "файл не загружен": "файл не загружен",
      "Удалить загруженный файл?": "Удалить загруженный файл?",

      "Номера других операторов": "Numbers of other operators",
      "В списке отображаются маршруты домена(с исходящей SIP-регистрацией), которые используются для внешних номеров.": "В списке отображаются маршруты домена(с исходящей SIP-регистрацией), которые используются для внешних номеров.",
      "Маршрут удаляется автоматически при удалении всех связанных с ним внешних номеров.": "Маршрут удаляется автоматически при удалении всех связанных с ним внешних номеров.",
      "Статус SIP-регистрации": "Статус SIP-регистрации",
      "Допустимы латинские буквы, цифры и символы \"-\", \"_\", \".\"": "Допустимы латинские буквы, цифры и символы \"-\", \"_\", \".\"",
      "Невозможно определить адрес сервера": "Невозможно определить адрес сервера",
      "Преобразование B-номера": "Преобразование B-номера",
      "Скопировать": "Скопировать",
      "Скопировано!": "Скопировано!",
      "При совершении исходящих вызовов набранный номер автоматически преобразуется в формате E.164 (например, \"+79161234567\", \"+74991234567\"). Если для внешнего оператора требуется другой формат номера (например, \"9161234567\" или \"89161234567\"), то можно сделать соответствующее преобразование.": "При совершении исходящих вызовов набранный номер автоматически преобразуется в формате E.164 (например, \"+79161234567\", \"+74991234567\"). Если для внешнего оператора требуется другой формат номера (например, \"9161234567\" или \"89161234567\"), то можно сделать соответствующее преобразование.",
      "Удалить группу у пользователя?": "Удалить группу у пользователя?",
      "Максимальная длина 100 символов": "Максимальная длина 100 символов",
      "Запрос успешно выполнен": "Запрос успешно выполнен",
      "Домен, логин и пароль используются для входа в личный кабинет, регистрации в SIP-телефоне и программном телефоне": "Домен, логин и пароль используются для входа в личный кабинет, регистрации в SIP-телефоне и программном телефоне",
      "Всегда онлайн": "Всегда онлайн",
      "Изменить логин / пароль": "Изменить логин / пароль",
      "Запись будет храниться дней": "Запись будет храниться дней",
      "Комбинированный показатель": "Комбинированный показатель",
      "Не установлено ограничений исходящих вызовов для всех пользователей домена": "Не установлено ограничений исходящих вызовов для всех пользователей домена",
      "Включено общее ограничение международных вызовов для всех пользователей домена": "Включено общее ограничение международных вызовов для всех пользователей домена",
      "Включено общее ограничение международных и междугородних вызовов для всех пользователей домена": "Включено общее ограничение международных и междугородних вызовов для всех пользователей домена",
      "Включено общее ограничение всех вызовов кроме внутренних для всех пользователей домена": "Включено общее ограничение всех вызовов кроме внутренних для всех пользователей домена",
      "Отправлять уведомление о голосовых сообщениях на": "Отправлять уведомление о голосовых сообщениях на",
      "Темы вызовов": "Темы вызовов",
      "Добавить тему": "Добавить тему",
      "Название темы": "Название темы",
      "Настройка рабочего места оператора": "Настройка рабочего места оператора",
      "Оповещать об успешных вызовах": "Оповещать об успешных вызовах",
      "Оповещать о пропущенных вызовах": "Оповещать о пропущенных вызовах",
      "Настроить прием входящих вызовов": "Настроить прием входящих вызовов",
      "Текстовое приветствие": "Текстовое приветствие",
      "Время на прием звонка, с": "Время на прием звонка, с",
      "Время поствызывной обработки, с": "Время поствызывной обработки, с",
      "Среднее время разговора, с": "Среднее время разговора, с",
      "Время, в течение которого на РМО не распределяются вызовы по окончании обслуживания вызова": "Время, в течение которого на РМО не распределяются вызовы по окончании обслуживания вызова",
      "При неответе оператора в течение заданного времени, его РМО переходит в состояние технического перерыва": "При неответе оператора в течение заданного времени, его РМО переходит в состояние технического перерыва",
      "Текстовое приветствие отображается в РМО при поступлении вызова на оператора": "Текстовое приветствие отображается в РМО при поступлении вызова на оператора",
      "Значение средней продолжительности вызова в секундах, используемое при произнесении примерного времени ожидания в очереди": "Значение средней продолжительности вызова в секундах, используемое при произнесении примерного времени ожидания в очереди",
      "URL для взаимодействия": "URL для взаимодействия",
      "Ссылка, открываемая встроенным браузером РМО при поступлении вызова на оператора": "Ссылка, открываемая встроенным браузером РМО при поступлении вызова на оператора",
      "Состав группы": "Состав группы",
      "Добавить участников": "Добавить участников",
      "Внутренний номер, группы и метки": "Внутренний номер, группы и метки",
      "Чем меньше число в поле, тем выше приоритет пользователя при распределении вызовов внутри группы": "Чем меньше число в поле, тем выше приоритет пользователя при распределении вызовов внутри группы",
      "Нет прочих групп": "Нет прочих групп",
      "Сделать оператором": "Сделать оператором",
      "Голосовое приветствие перед началом разговора": "Голосовое приветствие перед началом разговора",
      "При недоступности всех контактах номеров вызовов будет направлен на голосовую почту": "При недоступности всех контактах номеров вызовов будет направлен на голосовую почту",
      "Конференция, приглашение от...": "Конференция, приглашение от...",
      "Исходящий неудачный": "Исходящий неудачный",
      "Входящий пропущенный": "Входящий пропущенный",
      "Исходящий": "Исходящий",
      "Исходящий, перенаправлен к...": "Исходящий, перенаправлен к...",
      "Групповой пропущенный": "Групповой пропущенный",
      "Групповой": "Групповой",
      "мин, сек": "мин, сек",
      "К списку групп":"К списку групп",
      "Действительно исключить выбранных пользователей из группы?": "Действительно исключить выбранных пользователей из группы?",
      "Исключить из группы": "Исключить из группы",
      "Добавить пользователя в группу": "Добавить пользователя в группу",      
      "Исключить": "Исключить",
      "Удалить сообщение голосовой почты": "Удалить сообщение голосовой почты",
      "Удалить сообщение голосовой почты?": "Удалить сообщение голосовой почты?",
      "изменением": "изменением",
      "увеличением": "увеличением",
      "уменьшением": "уменьшением",
      "с входящей связью": "с входящей связью",
      "с входящей и исходящей связью": "с входящей и исходящей связью",
      "Приложения для ПК и Мобильного телефона": "Приложения для ПК и Мобильного телефона",
      "Приложение для Windows": "Приложение для Windows",
      "Приложение для iOS": "Приложение для iOS",
      "Приложение для MacOS": "Приложение для MacOS",
      "Приложение для Android": "Приложение для Android",
      "Плата за дополнительный номер": "Плата за дополнительный номер",
      "Запись и хранение всех разговоров сотрудников, которые можно прослушать в режиме онлайн или скачать в виде файлов": "Запись и хранение всех разговоров сотрудников, которые можно прослушать в режиме онлайн или скачать в виде файлов",
      "Сотрудники вашей компании, использующие Виртуальную АТС": "Сотрудники вашей компании, использующие Виртуальную АТС",
      "Мобильные рабочие места позволяют объединить городские и мобильные номера в единую корпоративную сеть с внутренней нумерацией для бесплатных звонков внутри сети. Подключить услугу можно в правилах переадресации пользователя": "Мобильные рабочие места позволяют объединить городские и мобильные номера в единую корпоративную сеть с внутренней нумерацией для бесплатных звонков внутри сети. Подключить услугу можно в правилах переадресации пользователя",
      "Установив данный пункт, вы подтверждаете, что информированы о том, что изменения настроек влекут за собой изменение стоимости услуг": "Установив данный пункт, вы подтверждаете, что информированы о том, что изменения настроек влекут за собой изменение стоимости услуг",
      "Подключение бесплатное": "Подключение бесплатное",
      "Изменение бесплатное": "Изменение бесплатное",
      "{{action1}} услуги для <b>{{count}} {{usersString}}</b> {{action2}} ежемесячные платежи": "{{action1}} услуги для <b>{{count}} {{usersString}}</b> {{action2}} ежемесячные платежи",
      "увеличит": "увеличит",
      "уменьшит": "уменьшит",
      "Сообщение, которое проигрывается перед началом переадресации на голосовую почту в случае недоступности всех номеров.": "Сообщение, которое проигрывается перед началом переадресации на голосовую почту в случае недоступности всех номеров.",
      "switchLanguage": "English",
      "Пользователь не найден": "Пользователь не найден",
      "У пользователя не настроен адрес электронной почты": "У пользователя не настроен адрес электронной почты",
      "Неверный логин или пароль": "Неверный логин или пароль",
      "Базовый пакет": "Базовый пакет",
      "Абонентская плата за пакет пользователей": "Абонентская плата за пакет пользователей",
      "Абонентская плата за пакет операторов": "Абонентская плата за пакет операторов",
      "Управление IVR": "Управление IVR",
      "Контакт-центр": "Контакт-центр",
      "Управление вызовами": "Управление вызовами",
      "Настройка приветствия": "Настройка приветствия",
      "На Ваш почтовый ящик выслано письмо с дальнейшими инструкциями.": "На Ваш почтовый ящик выслано письмо с дальнейшими инструкциями.",
      "Домен не найден": "Домен не найден",
      "Восстановление пароля": "Восстановление пароля",
      "Нажмите на изображение, чтобы обновить его": "Нажмите на изображение, чтобы обновить его",
      "Введите текст с картинки": "Введите текст с картинки",
      "1 месяц": "1 месяц",
      "по умолчанию": "по умолчанию",
      "Неправильный код с картинки": "Неправильный код с картинки",
      "Ограничение исходящих вызовов для новых пользователей": "Ограничение исходящих вызовов для новых пользователей",
      "Не достаточно прав для совершения операции": "Не достаточно прав для совершения операции",
      "Собеседник": "Собеседник",      
      "Дублирование пользователя": "Дублирование пользователя",
      "Редактирование пользователя": "Редактирование пользователя",
      "Копия": "Копия",
      "После отключения услуги для пользователя уже существующие записи разговоров будут храниться в соответствии с выбранными настройками, новые разговоры пользователя записываться не будут.": "После отключения услуги для пользователя уже существующие записи разговоров будут храниться в соответствии с выбранными настройками, новые разговоры пользователя записываться не будут.",
      "Названия групп": "Названия групп",
      "АОН для исходящих вызовов": "АОН для исходящих вызовов",
      "Внутренний": "Внутренний",
      "Исходящий неуспешный": "Исходящий неуспешный",
      "Входящий": "Входящий",
      "Удалить голосовую почту": "Удалить голосовую почту",
      "{{action1}} услуги {{action2}} платежи": "{{action1}} услуги {{action2}} платежи",
      "Услуга «Добровольная блокировка» может быть подключена на срок не более 180 дней. По истечении этого срока услуга автоматически отключится.": "Услуга «Добровольная блокировка» может быть подключена на срок не более 180 дней. По истечении этого срока услуга автоматически отключится.",
      "Ежемесячная плата не изменится": "Ежемесячная плата не изменится",
      "Ежедневная плата не изменится": "Ежедневная плата не изменится",
      "проверить": "проверить",
      "Вызов будет сброшен. Абонент получит сигнал «линия занята».": "Вызов будет сброшен. Абонент получит сигнал «линия занята».",
      "Перевод на пользователя": "Перевод на пользователя",
      "Перевод на группу": "Перевод на группу",
      "Редактировать сценарий": "Редактировать сценарий",
      "Время – с": "Время – с",
      "Время – до": "Время – до",
      "Номер по умолчанию удалять нельзя": "Номер по умолчанию удалять нельзя",
      "Показать полный список": "Показать полный список",
      "В АОН номер домена": "В АОН номер домена",
      "Всегда подставлять в АОН номер домена при вызовах FMC-ТФОП": "Всегда подставлять в АОН номер домена при вызовах FMC-ТФОП",

      "Номер этого типа должен содержать 11 цифр и может начинаться с \"+\"": "Номер этого типа должен содержать 11 цифр и может начинаться с \"+\"",
      "Номер этого типа должен содержать только цифры и может начинаться с \"+\"": "Номер этого типа должен содержать только цифры и может начинаться с \"+\"",
      "Допустимы значения от 0 до 120": "Допустимы значения от 0 до 120",
      "Допустимы значения от 0 до 10000": "Допустимы значения от 0 до 10000",
      "Ошибка при сохранении файла": "Ошибка при сохранении файла",
      "Поддерживаются только форматы mp3, wav": "Поддерживаются только форматы mp3, wav",
      "Ошибка во время выполнения.": "Ошибка во время выполнения.",

      "DISA отключен для входящей линии": "DISA отключен для входящей линии",
      "DISA отключен для пользователя": "DISA отключен для пользователя",
      "Ввод пароля в DISA": "Ввод пароля в DISA",
      "Входящий переадресованный аутентифицированный": "Входящий переадресованный аутентифицированный",
      "Вызов Click-To-Record": "Вызов Click-To-Record",
      "Вызов внешнего номера": "Вызов внешнего номера",
      "Вызов внутреннего номера": "Вызов внутреннего номера",
      "Вызов на входящую линию": "Вызов на входящую линию",
      "Вызов неудачен (код SIP)": "Вызов неудачен (код SIP)",
      "Вызов отменен": "Вызов отменен",
      "Вызов с виджета CallMe": "Вызов с виджета CallMe",
      "Вызов спецслужбы": "Вызов спецслужбы",
      "Достигнуто максимальное число ошибок ввода": "Достигнуто максимальное число ошибок ввода",
      "Начало записи голосового сообщения": "Начало записи голосового сообщения",
      "Неверный пароль DISA": "Неверный пароль DISA",
      "Нет активных контактов": "Нет активных контактов",
      "Нет активных контактов для обратного вызова": "Нет активных контактов для обратного вызова",
      "Нет активных операторов": "Нет активных операторов",
      "Отмена перевода": "Отмена перевода",
      "Ошибка ввода внутреннего номера": "Ошибка ввода внутреннего номера",
      "Пароль для доступа в DISA не задан": "Пароль для доступа в DISA не задан",
      "Переадресация SIP": "Переадресация SIP",
      "Переадресация на IVR-сценарий": "Переадресация на IVR-сценарий",
      "Переадресация на голосовую почту": "Переадресация на голосовую почту",
      "Переадресация на группу ACD": "Переадресация на группу ACD",
      "Переадресация на группу «Вызов всем»": "Переадресация на группу «Вызов всем»",
      "Переадресация на пользователя": "Переадресация на пользователя",
      "Перевод неудачен": "Перевод неудачен",
      "Перехват выполнен": "Перехват выполнен",
      "Перехват не выполнен": "Перехват не выполнен",
      "Перехват отвергнут": "Перехват отвергнут",
      "Переход на IVR меню": "Переход на IVR меню",
      "Подтверждение перевода": "Подтверждение перевода",
      "Попытка входа в DISA": "Попытка входа в DISA",
      "Попытка перевода": "Попытка перевода",
      "Постановка вызова в очередь": "Постановка вызова в очередь",
      "Постановка на удержание": "Постановка на удержание",
      "Проигрывание приветствия": "Проигрывание приветствия",
      "Слепой перевод": "Слепой перевод",
      "Снятие с удержания": "Снятие с удержания",
      "Соединение завершено": "Соединение завершено",
      "Соединение перехвачено": "Соединение перехвачено",
      "Соединение установлено": "Соединение установлено",
      "Таймаут ввода": "Таймаут ввода",
      "Таймаут голосового сообщения": "Таймаут голосового сообщения",
      "Успешная попытка перехвата": "Успешная попытка перехвата",
      "Успешный вход в DIS": "Успешный вход в DIS",
      "Контакты домена": "Контакты домена",
      "Неизвестная ошибка": "Неизвестная ошибка",
      "Администратор контакт-центра": "Администратор контакт-центра",
      "Администратору контакт-центра доступна возможность контроля за работой операторов": "Администратору контакт-центра доступна возможность контроля за работой операторов",
      // английский текст ошибки капчи приходит с сервака, пока сделаем его ключом
      "Value is required and can't be empty": "Значение обязательно",
      ", запланированных": ", запланированных",
      "Дополнительные поля пользователей": "Дополнительные поля пользователей",
      "Поле": "Поле",
      "Допустимо максимум 50 символов": "Допустимо максимум 50 символов",
      "Разрешить ввод комментариев": "Разрешить ввод комментариев",
      "Операторы смогут при обработке вызовов добавлять комментарии, которые будут доступны в отчете": "Операторы смогут при обработке вызовов добавлять комментарии, которые будут доступны в отчете",
      "Уведомление о пропущенных вызовах на e-mail": "Уведомление о пропущенных вызовах на e-mail",
      "Отправка уведомлений на email": "Отправка уведомлений на email",
      "Не отправлять": "Не отправлять",
      "Выберите тип": "Выберите тип",
      "Отправлять ссылку": "Отправлять ссылку",
      "Отправлять файл": "Отправлять файл",
      "Интеграция с внешними системами": "Интеграция с внешними системами",
      "Виртуальный колцентр": "Виртуальный колцентр",
      "Базовый пакет по-умолчанию": "Базовый пакет по-умолчанию",
      "Частные маршруты (PRIVATE_ROUTE)": "Частные маршруты (PRIVATE_ROUTE)",
      "Универсальный API": "Универсальный API",
      "На Ваш адрес электронной почты отправлено письмо. Для проверки указанного адреса электронной почты перейдите, пожалуйста, по ссылке из письма.": "На Ваш адрес электронной почты отправлено письмо. Для проверки указанного адреса электронной почты перейдите, пожалуйста, по ссылке из письма.",
      "Использование FMC возможно только для номеров Ростелекома": "Использование FMC возможно только для номеров Ростелекома",
      "Тип домена": "Тип домена",
      "Изменить тип домена": "Изменить тип домена",
      "Административные настройки": "Административные настройки",
      'Статистика по пользователям домена' : 'Статистика по пользователям домена',
      'В тарифном плане отсутствует данная услуга' : 'В тарифном плане отсутствует данная услуга',

      "xxx": "xxx",
      
      "Достигнуто предельное число контактов общей адресной книги домена": "Достигнуто предельное число контактов общей адресной книги домена",
      "Достигнуто лицензионное ограничение на общее число пользователей в системе. Пожалуйста, обратитесь в службу поддержки.": "Достигнуто лицензионное ограничение на общее число пользователей в системе. Пожалуйста, обратитесь в службу поддержки.",
      "Достигнуто предельное число пользователей в домене": "Достигнуто предельное число пользователей в домене",
      'Достигнуто максимальное число пользователей для группы с алгоритмом распределения "Всем пользователям"': 'Достигнуто максимальное число пользователей для группы с алгоритмом распределения "Всем пользователям"',
      "Достигнуто предельное число операторов в системе. Пожалуйста, обратитесь в службу поддержки.": "Достигнуто предельное число операторов в системе. Пожалуйста, обратитесь в службу поддержки.",
      "Превышено ограничение на число контактов общей адресной книги": "Превышено ограничение на число контактов общей адресной книги",
      'Превышено максимальное число пользователей для группы с алгоритмом распределения "Всем пользователям"': 'Превышено максимальное число пользователей для группы с алгоритмом распределения "Всем пользователям"',
      "Превышено лицензионное ограничение на общее число пользователей в системе": "Превышено лицензионное ограничение на общее число пользователей в системе",
      "В результате операции будет превышен общий лимит операторов в системе. Пожалуйста, обратитесь в службу поддержки.": "В результате операции будет превышен общий лимит операторов в системе. Пожалуйста, обратитесь в службу поддержки.",
      "В результате операции будет превышено предельное число операторов в домене.": "В результате операции будет превышено предельное число операторов в домене.",
      'В результате операции будет превышено максимальное число пользователей для группы с алгоритмом распределения "Всем пользователям"': 'В результате операции будет превышено максимальное число пользователей для группы с алгоритмом распределения "Всем пользователям"',

      "Список номеров не должен быть пустым": "Список номеров не должен быть пустым",
      "Отправка... ({{totalNumbersCount}} номеров)": "Отправка... ({{totalNumbersCount}} номеров)",
      "Конвертирование невозможно": "Конвертирование невозможно",
      "Номер не может быть пустым": "Номер не может быть пустым",
      "Введите номер": "Введите номер",
      "Загрузить список номеров (максимум {{phonesLimit}})": "Загрузить список номеров (максимум {{phonesLimit}})",
      "Шаблон списка": "Шаблон списка",
      "Загрузить": "Загрузить",
      "Ошибки валидации файла": "Ошибки валидации файла",
      "подробнее": "подробнее",

      /// Графики для отчётов колл-центра
      "Таблица": "Таблица",
      "График":  "График",
      "Длительность": "Длительность",
      "Нач.": "Нач.",
      "Кон.": "Кон.",
      "Группа": "Группа",
      "Уровень обслуживания": "Уровень обслуживания",
      "Время [минут]": "Время [минут]",
      "Время [секунд]": "Время [секунд]",
      "выз": "выз",
      "По количеству вызовов": "По количеству вызовов",
      "Общее время разговора": "Общее время разговора",
      "Состояния по сменам": "Состояния по сменам",
      "Тип визуализации не определён": "Тип визуализации не определён",
      "Максимальная длина очереди": "Максимальная длина очереди",

      "день":     "день",
      "месяц":    "месяц",
      "год":      "год",
      "дня":      "дня",
      "месяца":   "месяца",
      "года":     "года",
      "дней":     "дней",
      "месяцев":  "месяцев",
      "лет":      "лет",

      "Принятые вызовы за период построения отчёта": "Принятые вызовы за период построения отчёта",
      "Пропущенные вызовы за период построения отчёта": "Пропущенные вызовы за период построения отчёта",
      "Принятые вызовы": "Принятые вызовы",
      "Пропущенные вызовы": "Пропущенные вызовы",
      "Максимальное число одновременно занятых линий": "Максимальное число одновременно занятых линий",
      "Принято": "Принято",
      "Потеряно": "Потеряно",
      "Среднее": "Среднее",
      "Максимальное": "Максимальное",
      "Продолжительность нахождения в статусе": "Продолжительность нахождения в статусе",

      "Нажмите мышкой на пункт в легенде,<br/> чтобы управлять соответствующим объектом на диаграмме.": "Нажмите мышкой на пункт в легенде,<br/> чтобы управлять соответствующим объектом на диаграмме.",
      "Нажмите мышкой на элемент диаграммы, чтобы увидеть информацию о принятых / пропущенных вызовах для {{infoLabelPart}}.": "Нажмите мышкой на элемент диаграммы, чтобы увидеть информацию о принятых / пропущенных вызовах для {{infoLabelPart}}.",
      "каждого из [элементов]": "каждого из [элементов]",
      "каждой из групп": "каждой из групп",
      "каждого из операторов": "каждого из операторов",

      "Соотношение принятых / потерянных вызовов": "Соотношение принятых / потерянных вызовов",
      "Общее число вызовов": "Общее число вызовов",
      "Общее количество вызовов": "Общее количество вызовов",
      "Всего по оператору": "Всего по оператору",
      "Всего по группе": "Всего по группе",
      "Количество вызовов": "Количество вызовов",
      "Max время до потери вызова": "Max время до потери вызова",
      "Время реакции": "Время реакции",
      "Всего за период": "Всего за период",
      "Всего за интервал": "Всего за интервал",
      "Вызовы по группам за весь период": "Вызовы по группам за весь период",
      "Среднее за весь период": "Среднее за весь период",
      "Среднее за интервал": "Среднее за интервал",
      "Среднее время {{talkType}} по группам за весь период": "Среднее время {{talkType}} по группам за весь период",
      "разговора": "разговора",
      "реакции": "реакции",
      "За интервал": "За интервал",
      "За весь период": "За весь период",
      "Среднее / Максимальное время до {{callType}} вызова по группам за весь период [секунд]": "Среднее / Максимальное время до {{callType}} вызова по группам за весь период [секунд]",
      "потери": "потери",
      "приёма": "приёма",
      "Сред.": "Сред.",
      "Макс.": "Макс.",
      "Уровень обслуживания по группам за весь период": "Уровень обслуживания по группам за весь период",
      "Средний уровень обслуживания по выбранным группам за весь период": "Средний уровень обслуживания по выбранным группам за весь период",

      "В отчет попадают вызовы, пришедшие из частных маршрутов<br/>и ушедшие в обычный маршрут.": "В отчет попадают вызовы, пришедшие из частных маршрутов<br/>и ушедшие в обычный маршрут.",

    }
  },




  en: {
    translation: {
      "brandName": "",
      "authTitle": "Virtual PBX",
      "Войти": "Sign in",
      "Логин": "Login",
      "Выход": "Exit",
      "Пароль": "Password",
      "Восстановить пароль": "Forgot your password?",
      "Домен ВАТС": "Domain VPBX",
      "Поле не может быть пустым": "The field cannot be empty",
      "Восстановление доступа": "Restore access",
      "Восстановить": "Restore",
      "Войти в систему": "Sign in",
      "Загрузка": "Loading",
      "Настройки пользователя": "User settings",
      "Настройки домена": "Domain settings",
      "Пользователь": "User",
      "Баланс": "Balance",
      "Остаток МГП": "Payment balance MGP",
      "Остаток минут": "Minutes left",
      "Основные данные": "Basic data",
      "Отображаемое имя": "Nickname",
      "Внутренний номер": "Internal number",
      "Должность": "Position",
      "SIP-адрес": "SIP address",
      "Изменить пароль": "Change a password",
      "Номер для исходящих": "Outgoing calls number",
      "Сохранить изменения": "Save changes",
      "Изменения сохранены": "Your changes have been saved successfully",
      "Применить": "Apply",
      "Закрыть": "Close",
      "Повтор пароля": "Repeat password",
      "Некорректный email": "Incorrect email",
      "Имя": "Name",
      "подтвержден": "approved",
      "не подтвержден": "not approved",
      "Проверить": "Check",
      "Пароли не совпадают": "Passwords do not match",
      "Текущий пароль": "Current password",
      "Новый пароль": "New password",
      "Пароль не должен совпадать с логином": "Password must not match the login, must contain at least 6 characters: it is necessary to use lowercase, uppercase latin letters (a-z) and numbers (0-9), it is forbidden to use non-alphabetic characters ($@)",
      "После изменения учетных данных пользователя не забудьте перенастроить SIP-устройства.": "After changing the user credentials, don't forget to reconfigure the SIP devices.",
      "При заполнении формы возникли следующие ошибки:": "During form filling, followed errors occured:",
      "Изменение учетных данных": "Changing credentials",
      "Данные пользователя": "User data",
      "Настройки вызовов": "Calls settings",
      "Журнал вызовов": "Calls log",
      "Факсы": "Faxes",
      "Адресная книга": "Address book",
      "Помощь": "Help",
      "Голосовое приветствие": "Voice greeting",
      "Сообщение, которое проигрывается перед началом разговора.": "The message that is played before the conversation starts",
      "Голосовая почта": "Voicemail",
      "При недоступности всех контактных номеров вызов будет направлен на голосовую почту.": "If all contact numbers are unavailable, the call will be sent to voicemail.",
      "Приветствие голосовой почты": "Voicemail greeting",
      "Мелодия гудка": "The melody of the beep",
      "Вы можете загрузить собственную мелодию гудка.": "You can upload your own beep melody.",
      "Загрузить аудио": "Upload audio",
      "Записать": "Record",
      "Записать голосовое приветствие": "Record a voicemail greeting",
      "Записать приветствие": "Record a greeting",
      "Позвонить и записать": "Call and record",
      "Записать голосовую почту": "Record a voicemail",
      "Вы хотите записать приветствие?": "Do you want to record a greeting?",
      "Вы хотите записать голосовую почту?": "Do you want to record a voicemail?",
      "Сценарий вызова для данного пользователя": "The call script for this user",
      "Графическое отображение": "Graphical display",
      "Табличное отображение": "Table display",
      "Статус": "Status",
      "Приоритет": "Priority",
      "Контактный номер": "Contact number",
      "Расписание": "Schedule",
      "Время дозвона, с": "Dial-up time, sec",
      "В любое время": "Anytime",
      "Добавить номер": "Add number",
      "Удалить": "Delete",
      "Редактировать": "Edit",
      "IP-телефон": "IP phone",
      "Мобильный": "Mobile",
      "Другой": "Another",
      "Рабочий": "Work",
      "Домашний": "Home",
      "Сохранить": "Save",
      "Выключить номер": "Disable number",
      "Включить номер": "Enable number",
      "Круглосуточно": "Around the clock",
      "Приоритет номера": "Number priority",
      "Номер": "Number",
      "Тип номера": "Number type",
      "Интервал задан неверно": "Incorrect interval",
      "пн": "mon",
      "вт": "tue",
      "ср": "wed",
      "чт": "thu",
      "пт": "fri",
      "сб": "sat",
      "вс": "sun",
      "Пн": "Mon",
      "Вт": "Tue",
      "Ср": "Wed",
      "Чт": "Thu",
      "Пт": "Fri",
      "Сб": "Sat",
      "Вс": "Sun",
      "заблокирован": "blocked",
      "проверить статус": "check status",
      "Еще период": "Add period",
      "Время": "Time",
      "Подтверждение действия": "Action confirmation",
      "Удалить номер?": "Do you want to delete a number?",
      "Завершение": "Termination",
      "Приветствие": "Greeting",
      "В другое время": "Another time",
      "еще": "more",
      "Еще": "More",
      "Ничего не найдено": "Nothing found",
      "Прослушать запись": "Play record",
      "Тип": "Type",
      "Абонент": "Subscriber",
      "Длительность": "Duration",
      "Вызовов": "Calls total",
      "Все": "All",
      "Входящие": "Incoming",
      "Исходящие": "Outgoing",
      "Пропущенные": "Missed",
      "Сегодня": "Today",
      "Вчера": "Yesterday",
      "Неделя": "Week",
      "Месяц": "Month",
      "Период – с": "Period from",
      "Период – до": "Period up to",
      "Поиск": "Search",
      "Показать": "Show",
      "Тип вызова": "Call type",
      "Полученные": "Received",
      "Отправленные": "Sent",
      "Отправить факс": "Send fax",
      "Отправка факса": "Fax sending",
      "Отправить": "Send",
      "Номер должен содержать только цифры": "The number must contain only digits",
      "Дата отправки должна быть в будущем": "The date of dispatch should be in the future",
      "Отложить отправку до": "Postpone sending until",
      "Неподдерживаемый тип файла": "Unsupported file type",
      "Выберите файл для отправки": "Select a file for sending",
      "Номер факса": "Fax number",
      "Отменить": "Cancel",
      "Протокол факса": "Fax protocol",
      "Дата и время": "Date and time",
      "Направление": "Direction",
      "Получатель/отправитель": "Receiver/sender",
      "Факсов": "Faxes total",
      "Личная адресная книга": "Private address book",
      "Личные контакты": "Personal contacts",
      "Общие контакты": "Shared contact",
      "Пользователи": "Users",
      "Новый контакт": "New contact",
      "Новая группа": "New group",
      "Поиск по имени или контактам": "Search name or contacts",
      "Быстрый набор": "Speed dial",
      "Номера": "Numbers",
      "Группа": "Group",
      "Электронная почта": "E-mail",
      "Номера телефонов": "Phone numbers",
      "Создать": "Create",
      "Еще номер": "One more number",
      "Новая группа контактов": "New contacts group",
      "Название": "Title",
      "Редактировать группу": "Edit group",
      "Редактирование группы": "Group editing",
      "Удалить группу": "Delete group",
      "Редактирование контакта": "Edit contact",
      "не заполнено": "not filled",
      "Не более 5 цифр": "No more than 5 figures",
      "Укажите номер": "Enter number",
      "Список пользователей домена": "Domain users list",
      "ФИО, должность": "Name, position",
      "Оператор": "Operator",
      "Доп. контактные номера": "Additional contact numbers",
      "Yes": "Yes",
      "Домен": "Domain",
      "Журнал операций": "Operations log",
      "Частные маршруты": "SIP-trunks",
      "Внешние номера": "External numbers",
      "Управление номерами": "Numbers management",
      "Голосовые сценарии": "Voice scripts",
      "Запись разговоров": "Recordings of conversations",
      "Исходящие кампании": "Outgoing campaigns",
      "Автообзвон": "Auto-call",
      "Виджеты для сайтов": "Website widgets",
      "Интеграционный API": "Integrational API",
      "Интеграция с CRM": "CRM integration",
      "Аналитика речи": "Speech analytics",
      "Отчеты контакт-центра": "Contact center reports",
      "Клиентская статистика": "Customer statistics",
      "Статистика": "Statistics",
      "Финансы": "Finances",
      "Группы обработки": "Call processing groups",
      "Услуги": "Services",
      "История": "History",
      "Настройки": "Settings",
      "Администратор домена": "Domain administrator",
      "Администратор системы": "System administrator",
      "Новый пользователь": "New user",
      "Новая группа перехвата": "New interception group",
      "Новая группа обработки вызовов": "New call processing group",
      "Все группы": "All groups",
      "Имя и должность": "Name and position",
      "Внутренний номер и email": "Internal number and email",
      "Группы и метки": "Groups and marks",
      "Добавить в группу": "Add to group",
      "Сгенерировать": "Generate",
      "SIP-адрес / логин": "SIP address / login",
      "Отправить учётные данные на email": "Send credentials to email",
      "Добавить несколько пользователей": "Add multiple users",
      "Пользователи внутри такой группы могут перехватывать вызовы своих коллег, пока звонит их телефон, набрав номер перехвата. В группы перехвата включают находящихся в одном кабинете.": "Users of such a group can pick up calls from their colleagues while their phone rings by dialing the pickup number.",
      "Номер для перехвата": "Interception number",
      "Изменить": "Change",
      "Добавьте в группу участников!": "Add members to a group!",
      "Выберите пользователей из окна слева.": "Select users from the left window.",
      "Вы также сможете сделать это позднее, после создания группы.": "You can also do it later, after creating a group.",
      "Массовое создание пользователей": "Bulk user creation",
      "Для выбранного алгоритма распределения требуется наличие в группе операторов.": "Selected distribution algorithm requires the presence of operators in the group.",
      "Не состоит в группах": "No groups",
      "Добавить пользователей в состав группы": "Add users to group",
      "Все пользователи": "All users",
      "Алгоритм распределения вызовов": "Calls distrubution algorithm",
      "Введите число от 1 до 99999": "Enter a number from 1 to 99999",
      "Ограничение исходящих": "Outgoing calls limitation",
      "Скопировать выбранное": "Copy selected",
      "Ежемесячная плата увеличится на": "Monthly fee will increase by",
      "Ежедневная плата увеличится на": "Daily fee will increase by",
      "Ежемесячная плата уменьшится на": "Monthly fee will decrease by",
      "Ежедневная плата уменьшится на": "Daily fee will decrease by",
      "Разовая плата за подключение": "One-time connection fee",
      "Номер для вызовов между сотрудниками": "Number for calls between employees",
      "пользователь": "user",
      "пользователя": "users",
      "пользователей": "users",
      "оператор": "operator",
      "оператора": "operators",
      "операторов": "operators",
      "не задан": "not set",
      "Всем пользователям одновременно": "To all users at the same time",
      "всем пользователям одновременно": "to all users at the same time",
      "[ACD] случайному оператору": "[ACD] to a random operator",
      "[ACD] Оператору, который дольше всех свободен": "[ACD] to the operator who is free the longest",
      "[ACD] оператору, который дольше всех свободен": "[ACD] to the operator who is free the longest",
      "[ACD] Оператору, который меньше всех загружен разговорами": "[ACD] to the operator who is least loaded with conversations",
      "[ACD] оператору, который меньше всех загружен разговорами": "[ACD] to the operator who is least loaded with conversations",
      "Выберите группу": "Select group",
      "Переместить в группу перехвата": "Move to a group of interception",
      "Снять выделение": "Remove the selection",
      "В группу": "Add in a group",
      "В группу перехвата": "To interception group",
      "Вы действительно хотите удалить выбранных пользователей?": "Do you really want to delete the selected users?",
      "Выбрано {{selectedUsersCount}} из {{totalUsersCount}}": "Selected  {{selectedUsersCount}} from {{totalUsersCount}}",
      "Удалить группу?": "Do you want to delete the group?",
      "Название группы": "Group title",
      "Переименовать группу": "Rename the group",
      "Rename": "Rename",
      "Без ограничений": "No limitations", 
     "К списку пользователей": "Back to users list",
      "Основные настройки": "Basic settings",
      "Вызовы и голосовая почта": "Calls and voicemail",
      "Оператор контакт-центра": "Оператор контакт-центра",
      "Оператор может принимать вызовы в группах с голосовыми очередями": "Operator can receive calls in groups with voice queues",
      "Администратор": "Administrator",
      "Администратору доступна возможность управлять настройками Виртуальной АТС компании": "The administrator has the ability to manage the settings of the company's Virtual PBX",
      "Не установлено ограничений исходящих вызовов": "No restrictions on outgoing calls",
      "Включено ограничение международных вызовов": "International call restriction enabled",
      "Включено ограничение международных и междугородних вызовов": "Restriction of international and long distance calls enabled",
      "Включено ограничение всех вызовов кроме внутренних": "Restriction of all calls except internal calls is enabled",
      "Выбор групп": "Groups selection",
      "Удалить пользователя": "Delete users",
      "Дублировать": "Duplicate",
      "Загрузка списка из файла Excel": "Import a list from Excel file",
      "Для загрузки списка потребуется сформировать <b>Excel файл</b> по шаблону. Воспользуйтесь примером такого файла, скачав его себе на компьютер:": "To download the list, you will need to generate an <b>Excel file</b> using a template. Use an example of such a file by downloading it to your computer:",
      "Скачать шаблон": "Download template",
      "Занесите все нужные данные в полученный файл шаблона и сохраните его на своём компьютере.": "Enter all the necessary data into the received template file and save it on your computer.",
      "Загрузите полученный файл": "Upload a received file",
      "В результате их добавления": "As a result of the addition",
      "Будет списано со счета за первый день": "Will be debited from the account on the first day",
      "Что-то не так?": "Something went wrong?",
      "Загрузить другой список": "Upload another list",
      "Шаблон": "Template",
      "Файл архива не найден у этого пользователя.": "Archive file not found for this user.",
      "Скачивание файла должно начаться.": "File download should start.",
      "Выгрузки": "Unloads",
      "Все выгрузки": "All unloads",
      "Сформировать": "Create",
      "Выгрузить вместе с записями разговоров": "Unload with conversation recordings",
      "Результат вызова": "Result of the call",
      "Входящая линия": "Incoming line",
      "Email для уведомления": "E-mail for notifications",
      "Принятые": "Accepted",
      "Не принятые": "Unaccepted",
      "Внутренние": "Internal",
      "Очистить фильтры": "Clear filters",
      "Запросить выгрузку": "Request an Unload",
      "Скачать с текущими настройками фильтров": "Download with current filters settings",
      "Скачать журнал": "Download log",
      "Протокол вызова": "Call protocol",
      "Кто": "Who",
      "Кому": "To whom",
      "Выбор даты": "Date selection",
      "Действие": "Action",
      "Поиск по объекту": "Search by object",
      "Операций": "Operations total",
      "Объект": "Object",
      "Данные": "Data",
      "Управление услугами": "Services management",
      "Ежемесячная плата": "Monthly fee",
      "Подключить": "Activate",
      "Отключить": "Deactivate",
      "Ежемесячная абонентская плата": "Monthly subscription fee",
      "Ежедневная абонентская плата": "Daily subscription fee",
      "канал": "channel",
      "канала": "channels",
      "каналов": "channels",
      "линий": "lines",
      "Услуга": "Service",
      "Количество": "Amount",
      "Абонентская плата": "Subscription fee",
      "Уровень {{br}}тарификации": "Tariffication {{br}}level",
      "Сумма": "Total",
      "Настроить": "Configure",
      "специальный": "special",
      "внешний": "external",
      "домен": "domain",
      "домена": "domains",
      "доменов": "domains",
      "в месяц": "per month",
      "в день": "per day",
      "Льготный период - до {{date}}, далее {{sum}}": "Grace period - up to {{date}}, further {{sum}}",
      "Плата {{br}}за подключение": "Connection {{br}}fee",
      "Подключение": "Activation",
      "Ошибка сохранения": "Saving failed",
      "Прослушать записи": "Play records",
      "Включить у всех": "Activate for all users",
      "Выключить у всех": "Deactivate for all users",
      "Записи звонков": "Call records",
      "Включена запись": "Recording enabled",
      "Срок хранения записи": "Record retention period",
      "Срок хранения записей разговора": "Conversation records retention period",
      "включена для {{sum1}} из {{sum2}} пользователей": "activated for {{sum1}} of {{sum2}} users",
      "Включена для {{sum1}} из {{sum2}} пользователей": "Activated for {{sum1}} of {{sum2}} users",
      "выключена": "deactivated",
      "Включена аналитика речи": "Speech analytics activated",
      "Включить запись": "Enable recording",
      "Отключить запись": "Disable recording",
      "Включить запись с аналитикой": "Enable recording with analytics",
      "Отключить аналитику": "Deactivate analytics",
      "Номера FMC": "FMC numbers",
      "Подключено": "Activated",
      "Отключено": "Deactivated",
      "Ожидает подтверждения": "Awaiting approvement",
      "Заблокировано": "Blocked",
      "Поиск номера": "Search number",
      "отключено": "deactivated",
      "ожидает подтверждения": "awaiting approvement",
      "подключено": "activated",
      "заблокировано": "blocked",      
      "Маршрутизация": "Routing",
      "Купить новый номер": "Get a new number",
      "Подключить номер другого оператора": "Connect the number of other operator",
      "входящих линий": "incoming call lines",
      "исходящих линий": "outgoing call lines",
      "Управление линиями": "Lines management",
      "Ограничение": "Limitation",
      "Черный список": "Blacklist",
      "Белый список": "Whitelist",
      "Номер другого оператора": "Number of other operator",
      "Не зарегистрирован": "Not registered",
      "Зарегистрирован": "Registered",
      "Номер подтвержден": "Number confirmed",
      "Номер не подтвержден": "Number not confirmed",
      "Все вызовы": "All calls",
      "Завершение вызова": "Call termination",
      "Голосовое меню": "Voice menu",
      "Новое правило": "New rule",
      "Настройка номера": "Number settings",
      "Не настроен": "Is not configured",
      "Использовать по умолчанию": "Use as default",
      "Настроен общий чёрный список, заданные здесь ограничения будут суммироваться с ним": "A common blacklist is set up.<br/>The restrictions set here will be summed up with it.",
      "Настроен белый список. Все вызовы с номеров, не входящих в белый список, будут отбиваться.": "A whitelist is configured.<br/>All calls from non-whitelisted numbers will be blocked.",
      "Правило перевода вызова": "Call Transfer rule",
      "Вызов будет сброшен.": "Сall will be dropped.",
      "Абонент получит сигнал «линия занята».": "The subscriber  user will receive a signal «the line is busy».",
      "Вызовы будут переводиться только в указанное время": "Calls will be transferred only at the specified time",
      "По префиксам": "By prefixes",
      "Вызовы будут переводиться только если номер звонящего содержит определённые цифры (код региона или сотовой сети)": "Calls can be transferred only if the caller's number contains certain digits numbers (region or cellular network code  code mobile connection)",
      "Изменить объект переадресации": "Change the forwarding object",
      "На пользователя": "On the user",
      "На группу обработки вызовов": "To the call processing group",
      "На голосовой сценарий IVR": "To the IVR voice script",
      "На голосовую почту пользователя или группы": "To the voice mail of the user or group",
      "На факс пользователя или группы": "To the fax of the user or group",
      "На номер частного маршрута": "To the private route number",
      "Выберите объект, на который будут переводиться пользователи": "Select the object to which users will be transferred",
      "номер": "number",
      "номера": "numbers",
      "номеров": "numbers",
      "Тип получателя": "Reciever type",
      "Получатель": "Reciever",
      "Номер по умолчанию для исходящих": "Default number for outgoing calls",
      "Префиксы": "Prefixes",
      "Префикс": "Prefix",
      "Не заданы": "Not set",
      "Добавить правило": "Add rule",
      "Пользователя": "User",
      "Группу": "Group",
      "Факс": "Fax",
      "Редактирование черного списка": "Edit a blacklist",
      "Редактирование белого списка": "Edit a whitelist",
      "Такой номер уже есть в списке": "This number is already in the list",
      "Введите корректный номер": "Please enter the correct number",
      "Время конца периода предшествует времени его начала": "The end time of the period is before the start time",
      "Правило активно": "Rule enabled",
      "Алгоритм распределения вызовов в группе": "Group calls distrubution algorithm",
      "Группы пользователя": "User groups",
      "Действия на цифрах": "Action on numbers",
      "Пример: 495, 499 для Москвы": "Example: 495, 499 for Moscow",
      "Вызовы распределяются в случайном порядке": "Calls are distributed randomly",
      "Вызовы распределяются по приоритету пользователей": "Calls are distributed according to the users' priority",
      "Вызовы направляются всем пользователям одновременно": "Calls are routed to all users at the same time",
      "меню": "menus",
      "Проверка настроенных правил": "Checking configured rules",
      "Вызовы на номера, которые Вы подключили у других операторов, будут попадать на Виртуальную АТС с возможностью использования всех преимуществ услуги (маршрутизация, IVR, запись разговоров, контакт-центр и др.)": "Calls to the numbers of other operators will get to the Virtual PBX with the possibility of using all the advantages of the service (routing, IVR, call recording, contact center, etc.)",
      "Номер для подключения": "Number for connection",
      "Вариант подключения": "Connection option",
      "Входящие вызовы с SIP-регистрацией": "Incoming calls with SIP registration",
      "Входящие и исходящие вызовы с SIP-регистрацией": "Incoming and outgoing calls with SIP registration",
      "Настройка переадресации на учетную запись SIP на нашем сервере": "Setting up forwarding to a SIP account on our server",
      "Получите у вашего оператора учетные данные для подключения по SIP для номера и укажите их в форме ниже.": "Get the SIP connection credentials for the number from your operator and specify them in the form below.",
      "Имя авторизации": "Authorization name",
      "Включен": "Activated",
      "Выключен": "Deactivated",
      "Статус маршрута": "Route status",
      "Статус регистрации": "Registration status",
      "обновить": "refresh",
      "Обновить": "Refresh",
      "SIP-сервер": "SIP-server",
      "порт": "port",
      "Имя авторизации (AuthID) для SIP-регистрации. Если не указано, будет использоваться логин": "Authorization name (AuthID) for SIP registration. If not specified, login will be used",
      "Запросите у вашего оператора для данного номера переадресацию на адрес (SIP-URI), указанный ниже. Доступна только возможность приема входящих вызовов.": "Request forwarding from your operator for this number to the address (SIP-URI) below. Only the ability to receive incoming calls is available.",
      "SIP-URI для переадресации": "SIP-URI for redirect",
      "Я согласен с {{changeMonthlyType}} ежемесячной абонентской платы за подключение внешнего номера {{chargeTypeText}} на сумму: {{sum}} ₽ в месяц": "I agree with {{changeMonthlyType}} of the monthly subscription fee for connecting an external number {{chargeTypeText}} in the amount of: {{sum}} ₽ per month",
      "Количество символов, которое необходимо отрезать от начала номера": "The number of characters to be cut off from the beginning of the number",
      "Символы, которые необходимо добавить в начало номера": "Characters to be added to the beginning of the number",
      "Управление частными маршрутами": "Manage private routes",
      "Исходящие линии": "Outgoing lines",
      "Входящие вызовы на SIP-Trunk": "Incoming calls to SIP-trunk",
      "Настроены": "Configured",
      "Не настроены": "Not configured",
      "Настройка количества линий": "Set the number of lines",
      "маршрут": "route",
      "маршрута": "routes",
      "маршрутов": "routes",
      "Для подключения частного маршрута (SIP-trunk) обратитесь к Вашему менеджеру по продажам или в службу технической поддержки": "To connect a private route (SIP-trunk), contact your sales manager or technical support",
      "Управление маршрутом": "Route management",
      "Вызов в маршрут": "Call to route",
      "Выбор маршрута": "Route selection",
      "Выражение для вызываемого номера": "Expression for the called number",
      "Только для группы": "For group only",
      "Основной контактный номер": "Main contact number",
      "Подстановка номера для вызывающего абонента домена": "Domain Caller ID Substitution",
      "Символов": "Symbols",
      "Количество символов, отрезаемых от начала вызываемого номера после выбора маршрута": "Number of characters to be cut from the beginning of the called number after route selection",
      "Авторизация и переадресация": "Authorization and forwarding",
      "Авторизационное имя": "Authorization name",
      "Транспортный протокол": "Transport protocol",
      "Период обновления": "Renewal period",
      "Вызов из маршрута": "Call from route",
      "Международный": "International",
      "Международные и междугородние вызовы": "International and long distance calls",
      "Все, кроме внутренних": "Everything except internal",
      "Ограничение направлений транзитных вызовов в ССОП": "Restricting the directions of transit calls in the SSN",
      "Подстановка номера для транзитного вызова в ССОП": "Number substitution for a transit call in the SSN",
      "Номера для исходящих вызовов": "Outgoing call numbers",
      "Разрешение подстановки номера линии из From": "Allow line number substitution from From",
      "Использование переадресации": "Using call forwarding",
      "Тип заголовка, принимающего авторизацию": "The type of header accepting the authorization",
      "Не использовать": "Не использовать",
      "Тип заголовка истории переадресации": "Forwarding history header type",
      "Ежедневная плата": "Daily fee",
      "Кроме того, со счёта будет списана разовая плата за подключение": "Besides, a one-time connection fee will be debited from the account",      
      "Новая кампания": "New campaign",
      "Основное": "General",
      "Попыток дозвона": "Attempts to dial",
      "Метод дозвона": "Dial-up method",
      "Группа обработки вызовов": "Call processing group",
      "Макс. время дозвона в минутах": "Maximum dial-up time in minutes",
      "Ссылка на информацию о кампании": "Link to campaign information",
      "Редактирование статусов будет доступно после сохранения": "Editing statuses will be available after saving",
      "Дата и время начала:": "Start date and time:",
      "Дата и время окончания:": "End date and time:",
      "Создать кампанию": "Create campaign",
      "Одна попытка длится 20 секунд": "One attempt lasts 20 seconds",
      "Последовательный": "Consistent",
      "Параллельный": "Parallel",
      "Удаление исходящей кампании": "Deleting an outgoing campaign",
      "Вы действительно хотите удалить?": "Do you really want to delete?",
      "Создание исходящей кампании": "Outgoing campaign creation",
      "Список обзвона": "Call list",
      "Статусы вызовов": "Call statuses",
      "Успешный вызов": "Successful call",
      "Нет ответа": "No answer",
      "Новый статус": "New status",
      "Введите название статуса": "Enter the status name",
      "Добавить клиента": "Add a client",
      "Добавить одного": "Add one",
      "Загрузить из файла": "Upload from file",
      "Скачать список": "Download the list",
      "Очистить список": "Clear the list",
      "Примечание": "Remark",
      "Примечания": "Remarks",
      "Клиент": "Client",
      "В списке {{total}} клиентов. Обзвонили {{called}}": "List of <b>{{total}}</b> clients. Called <b>{{called}}</b>",
      "Неизвестная группа": "Unknown group",
      "Назад": "Back",
      "Запустить": "Launch",
      "Общие настройки": "General settings",
      "SIP-устройства": "SIP devices",
      "Начальное состояние": "Initial state",
      "Удаление": "Deleting",
      "Общие": "General",
      "Добавление клиента": "Add client",
      "Ошибки": "Errors",
      "Добавить": "Add",
      "Имя клиента": "Client name",
      "Массовое создание клиентов": "Mass creation of clients",
      "Голосовые сценарии IVR": "Voice scripts",
      "Добавить сценарий": "Add a script",
      "Создание IVR": "IVR creation",
      "Название IVR": "IVR name",
      "Значение обязательно": "This value is required",
      "Настроить маршрутизацию": "Route configuration",
      "Список меню в сценарии": "Menu list in the script",
      "Список меню": "Menu list",
      "новое меню": "new menu",
      "Новое меню": "New menu",
      "Использованы в сценарии:": "Used in the script:",
      "Не использованы в сценарии:": "Not used in the script:",
      "Удалить меню?": "Do you really want to delete a menu?",
      "Название меню": "Menu name",
      "Поддерживаются только форматы mp3. wav": "Only mp3. wav formats are supported",
      "Аудиосообщения ": "Audio messages",
      "Сейчас система попробует до Вас дозвониться, чтобы Вы могли записать голосовое приветствие для выбранного пункта": "Now the system will try to reach you so that you can record a voice greeting for the selected item",
      "Все голосовые сценарии": "All voice scripts",
      "Полноэкранный режим": "Fullscreen mode",
      "Выйти из полноэкранного режима": "Exit Fullscreen mode",
      "Удалить голосовой сценарий": "Delete a voice script",
      "Начало звонка": "Beginning of the call",
      "Основное меню": "Main menu",
      "Данное аудиосообщение будет проигрываться целиком всякий раз при входе в данное меню": "This audio message will be played in its entirety every time you enter this menu",
      "Выбор цифры": "Number selection",
      "Удалить ветку": "Delete a branch",
      "Все уровни меню сохранятся для повторного использования": "All menu levels will be saved for reuse",
      "Удалить кнопку": "Delete a button",
      "Изменить тип действия": "Change the action type",
      "Выберите тип действия ": "Select the action type",
      "Переадресация": "Redirection",
      "На факсы, телефоны и голосовую почту пользователей и групп, произвольные номера": "To faxes, phones and voicemail of users and groups, arbitrary numbers",
      "Вызовы распределяется в случайном порядке": "Calls are distributed randomly",
      "Вызовы распределяются всем пользователям одновременно": "Calls are distributed to all users at the same time",
      "Произвольный номер": "Arbitrary number",
      "Аудиосообщение": "Audiomessage",
      "Например, рекламное": "For example, advertising one",
      "Другое меню": "Another menu",
      "Любое меню из этого сценария": "Any menu from this scenario",
      "Выберите меню": "Select menu",
      "Возврат к предыдущему меню": "Return to the previous menu",
      "Клиенту будет озвучено меню уровнем выше": "The menu will be announced to the client at a higher level",
      "Донабор внутреннего номера сотрудника": "Dialing an employee's internal number",
      "Донабор внутреннего номера пользователя": "Dialing an user's internal number",
      "Клиент сможет набрать внутренний номер сотрудника": "Customer will be able to dial the employee's internal number",
      "Клиент будет отключен": "The client will be disconnected",
      "Повтор меню": "Repeat menu",
      "Текущее меню озвучится сначала": "The current menu will be announced first",
      "Незадействованные в сценарии кнопки": "Buttons not used in the script",
      "Таймаут {{count}} сек": "Timeout {{count}} sec",
      "Звуковой файл": "Audio file",
      "Добавить кнопку": "Add a button",
      "Кнопка": "Button",
      "Номер не назначен": "Number not assigned",
      "Сценариев": "Scripts total",
      "Удалить сценарий IVR": "Delete script",
      "Название сценария": "Script name",
      "Загрузите или запишите по телефону основной аудиофайл сценария": "Upload or record via your phone the main audiofile of script",
      "Перевод на произвольный номер": "Redirect to arbitrary number",
      "Аудио": "Audio",
      "По умолчанию": "By default",
      "Группы": "Groups",
      "Выберите действие": "Select action",
      "Группы обработки вызовов": "Groups of call processing",
      "Имя группы": "Group name",
      "Группы обработки вызовов нужны для приёма звонков сразу несколькими пользователями, и создания голосовых очередей": "Groups of call processing are needed to receive calls from several users at once, and to create voice queues",
      "Допустимы только цифро-буквенные символы, пробелы, а также символы - _ ( ) + . \"": "Only alphanumeric characters, spaces, and symbols are allowed - _ ( ) + . \"",
      "Допустимо максимум 5 символов": "Maximum of 5 characters is allowed",
      "Сообщения операторам": "Messages to operators",
      "Статистика реального времени": "Real-time statistics",
      "VIP-номера": "VIP-numbers",
      "Групп": "Groups",
      "Распределение вызовов": "Calls distrubution",
      "Показать всех": "Show all",
      "Поиск группы по имени или номеру": "Search group by name or number",
      "Написать сообщение": "Write a message",
      "Прочитали": "Read",
      "Отчет о прочтении": "Report of read",
      "Не прочитали": "Not read",
      "Заголовок": "Header",
      "Текст сообщения": "Message text",
      "Группа получателей": "Receiver group",
      "Не выбраны группы": "Groups are not selected",
      "В выбранных группах нет операторов": "There are no operators in the selected groups",
      "Удалить сообщение?": "Do you really want to delete a message?",
      "нет групп": "no groups",
      "Сообщение операторам": "Message to operators",
      "Клиенты с VIP номерами имеют приоритет перед обычными при обслуживании в голосовых очередях. Если в голосовой очереди несколько VIP клиентов, то очередность будет определяться приоритетом: чем ниже значение, тем выше приоритет и тем быстрее обслуживается вызов": "Customers with VIP numbers have priority over regular ones when serving in voice queues. If there are several VIP clients in the voice queue, the priority will be determined by the priority: the lower the value, the higher the priority and the faster the call is served",
      "VIP-номер": "VIP-number",
      "Комментарий": "Comment",
      "Не введен комментарий": "No comment entered",
      "Заполните номер": "Enter the number",
      "Добавить VIP-номер": "Add VIP-number",
      "По группам": "By groups",
      "По операторам": "By operators",
      "В сети": "Online",
      "Не в сети": "Offline",
      "Операции": "Operations",
      "Очередь": "Queue",
      "На работе": "At work",
      "Ожидает звонок": "Waiting for a call",
      "Вх. вызов": "Incoming call",
      "Исх. вызов": "Outgoing call",
      "Поствыз. обр": "Post-call processing",
      "Перерыв": "Break",
      "Исх. кампания": "Outgoing campaign",
      "Подключиться": "Connect",
      "Конференция": "Conference",      
      "Расход": "Expenditure",
      "Приход": "Income",
      "За текущие сутки": "For the current day",
      "Детализация расходов": "Detailing expenses",
      "Состояние на начало текущего месяца": "Status at the beginning of the current month",
      "Приход в текущем месяце": "Income in the current month",
      "Текущее состояние": "Current status",
      "Расход МГП за текущие сутки": "MGP consumption for the current day",
      "Суммы указаны без учета скидки по тарифу": "The amounts are indicated without taking into account the discount on the tariff",
      "Состояние счетчиков минут": "Status of minute counters",
      "Остаток пакета минут": "The rest of the package minutes",
      "Обещанный платеж": "Promised payment",
      "Не введена сумма обещанного платежа": "Promised payment amount has not been entered",
      "Допустимы только цифры": "Only numbers are allowed",
      "Величина обещанного платежа должна быть от 50 до 5000 рублей": "Promised payment amount should be from 50 to 5000 rubles",
      "Снятие обещанного платежа произойдет автоматически через 5 календарных дней. Максимальная сумма обещанного платежа 5000.00 руб": "Promised payment will be withdrawn automatically after 5 calendar days. The maximum amount of the promised payment is 5000.00 rubles",
      "Предел кредита": "Credit limit",
      "Минимальный баланс": "Minimum balance",
      "Параметр обязателен": "Parameter is required",
      "Текущая схема взаиморасчетов [Авансовая схема] не позволяет выполнить операцию": "The current settlement scheme [Advance Scheme] does not allow the operation to be performed",
      "Списания": "Write-off",
      "Сутки": "24-hours",
      "Полгода": "Half a year",
      "Год": "Year",
      "Операция": "Operation",
      "Возврат обещанного платежа": "Refund of promised payment",
      "Обычный документ": "Standard document",
      "Банк": "Bank",
      "Суммы указаны без учёта скидки по тарифу": "The amounts are indicated without taking into account the discount on the tariff",
      "Платежи": "Payments",
      "Выставить счет": "Invoice for payment",
      "Адресная книга компании": "Company address book",
      "Создать контакт": "Add a contact",
      "Номер для быстрого созвона": "Speed dial number",
      "Для вызова контакта наберите *<короткий номер>. Короткий номер может содержать до пяти цифр": "To call a contact, dial *<short number>. A short number can contain up to five figures.",
      "Имя пользователя для SIP-URI содержит запрещенные символы": "The username for the SIP-URI contains forbidden characters",
      "Массовое создание контактов": "Mass creation of contacts",
      "Загруженный блок успешно обработан!": "The uploaded block has been processed successfully!",
      "Готовится к созданию": "Preparing to create",
      "Готовы к созданию": "Ready to create",
      "С ошибкой": "With an error",
      "Список контактов с ошибками": "List of contacts with errors",
      "Домашний телефон": "Home number",
      "Мобильный телефон": "Mobile phone",
      "Рабочий телефон": "Work number",
      "Другой телефон": "Another telephone",
      "Контакт с таким именем уже существует": "A contact with this name already exists",
      "Номер занят общим контактом": "The number is occupied by a common contact",
      "Загрузить другой файл?": "Do you want to upload another file?",
      "контакт": "contact",
      "контакта": "contacts",
      "контактов": "contacts",
      "Список позиций с ошибками": "List of positions with errors",
      "Неопознанная ошибка PIN": "Unknown PIN error",
      "Номера для быстрого созвона": "Speed dial numbers",
      "Ошибка": "Error",
      "Переместить в группу": "Move to group",
      "Контактные номера": "Contact numbers",
      "Имя не может длиннее 200 символов": "The name cannot be longer than 200 characters",
      "Вы действительно хотите удалить пользователя?": "Do you really want to delete user?",
      "Редактировать контакт": "Edit contact",
      "Удалить контакт": "Delete contact",
      "Отчеты": "Reports",
      "Аналитика речи выключена": "Speech analytics disabled",
      "Включить аналитику": "Activate analytics",
      "Посмотреть отчет": "View reports",      
      "Включение Записи разговоров": "Recordings of conversations activation",
      "Отключение Записи разговоров": "Recordings of conversations deactivation",
      "Включение Аналитики речи": "Speech analytics activation",
      "Отключение Аналитики речи": "Speech analytics deactivation",
      "Включение": "Activate",
      "Отключение": "Deactivation",
      "Отключение бесплатное": "Deactivation is free of charge",
      "Не уведомлять меня об изменении стоимости в течение данной сессии": "Do not notify me, if the fee will change during the session",
      "Установив данный пункт вы подтверждаете, что информированы о том, что изменения настроек влекут за собой изменение стоимости услуг": "By setting this item, you confirm that you are informed that changes to the settings entail a change in the cost of services",
      "Включение {{service}} для <b>{{count}} {{usersString}}</b> увеличит платежи": "Activation of {{service}} for <b>{{count}} {{usersString}}</b> will increase fee",
      "Аналитики речи": "Speech analytics",
      "Записи разговоров": "Recordings of conversations",
      "Выключение {{service}} для <b>{{count}} {{usersString}}</b> уменьшит ежемесячные платежи": "Deactivation of {{service}} for <b>{{count}} {{usersString}}</b> will decrease fee",
      "Дополнительно будет выключена услуга {{service}} для": "Additionally, {{service}} service will be deactivated for",
      "Дополнительно будет включена услуга {{service}} для": "Additionally, {{service}} service will be activated for",
      "Отчеты и статистика": "Reports and statistics",
      "Тип отчета": "Report type",
      "По операторам в группе": "By operators in the group",
      "Хронологический по группам": "Chronological by groups",
      "Интервал разбиения": "Allocation interval",
      "мин": "min",
      "сек": "sec",
      "Параметр": "Option",
      "Число вызовов": "Number of calls",
      "Среднее время разговора": "Average talk time",
      "Среднее время реакции": "Average reaction time",
      "Среднее время до потери вызова": "Average time until call loss",
      "Среднее время до приёма вызова": "Average time till receive a call",
      "Уровень обслуживания": "Service level",
      "Уровень QoS": "QoS level",
      "Хронологический по состояниям": "Chronological by state",
      "По вызовам": "By calls",
      "Занятие входящих линий": "Occupation of incoming lines",
      "Вход. линии": "Incoming lines",
      "Интервалы": "Intervals",
      "По дням": "By days",
      "По часам": "By hours",     
      "Вызовы из частных маршрутов": "Calls from private routes",
      "Часы": "Hours",
      "В отчет попадают вызовы, пришедшие из частных маршрутов и ушедшие в обычный маршрут.": "The report includes calls that came from private routes and went to the usual route.",
      "Прогноз количества операторов": "Forecast of the number of operators",
      "Время ожидания (сек.)": "Waiting time (sec.)",
      "По темам соединений": "By connection subjects",
      "Сравнительный по группам": "Comparative by groups",
      "Интервал QoS": "Qos Interval",
      "По сменам оператора": "By operator shifts",
     "Сводный по состояниям": "Summary by states",
      "Операторы": "Operators",     
      "За сегодня": "For today",
      "За 7 дней": "In 7 days",
      "За 30 дней": "In 30 days",
      "Группы операторов": "Operator groups",
      "Число принятых вызовов": "Number of calls received",
      "Число потерянных вызовов": "Number of lost calls",
      "Общее время разговоров": "Total conversation time",
      "Среднее время разговоров": "Average conversation time",
      "Среднее время реакции на вызов": "Average response time to a call",
      "Потерянные": "Lost",
      "Успешные": "Successful",
      "Общая сумма": "Total amount",
      "Состояние": "State",
      "Вызывается": "Calling",
      "Ожидает звонка": "Waiting for a call",
      "Поствызывная обработка": "Post-call processing",
      "Отошёл": "Moved away",
      "Начало интервала": "Start of the interval",
      "Конец интервала": "End of the interval",
      "Продолжительность": "Duration",
      "Номер телефона": "Phone number",
      "Время звонка": "Call time",
      "Статус звонка": "Call status",
      "Завершён абонентом": "Completed by the user",
      "Завершён оператором": "Completed by the operator",
      "Время ожидания": "Waiting time",
      "Время разговора": "Talk time",
      "№ интервала": "№ Interval",
      "Максимальное число одновременно занятых линий": "Maximum number of lines occupied at the same time",
      "Число соединений": "Number of connections",
      "Общее число соединений": "Total number of connections",
      "Всего вызовов": "Total calls",
      "Количество операторов": "Number of operators",
      "Вероятность соединения с оператором без постановки в очередь": "The probability of connecting to the operator without queuing",
      "Среднее время ожидания клиента в очереди": "Average waiting time of the client in the queue",
      "Средняя длина очереди": "Average queue length",
      "Интенсивность нагрузки на оператора": "The intensity of the load on the operator",
      "Темы соединений": "Connection subjects",
      "Число поступивших вызовов": "Number of incoming calls",  
      "Max длина очереди": "Max queue length",
      "Среднее время ожидания до потери вызова": "Average waiting time before call loss",
      "Максимальное время ожидания до потери вызова": "Maximum waiting time before call loss",
      "Начало смены": "Start of shift",
      "Конец смены": "End of shift",
      "Длительность смены": "Shift duration",
      "Всего вызовов за смену": "Total calls per shift",
      "Принято вызовов": "Calls received",
      "Пропущено вызовов": "Missed calls",
      "Суммарная длительность разговоров": "Total duration of conversations",
      "Средняя длительность разговоров": "Average duration of conversations",
      "Входящий вызов": "Incoming call",
      "Исходящий вызов": "Outgoing call",     
      "В перерыве": "On a break",
      "Отошел": "Moved away",
      "Исходящая кампания": "Outgoing campaign",
      "Итого": "Total",     
      "Нет данных": "No data", 
      "Время QoS, сек": "QoS time, sec",
      "нет операторов": "no operators",
      "нет линий": "no lines",
      "По оператору": "By operator",
      "Сред": "Average",
      "Макс": "Max",
      "Кол-во": "Amount",
      "Не дождался": "Not wait",
      "нет": "no",
      "Заполните все поля": "Fill all fields",
      "Полный список": "Full list",
      "Расходы за": "Expenditure",
      "Полная статистика": "Full statistics",
      "Подключено пользователей": "Connected users",
      "бесплатных": "free",
      "Пополнения - {{income}} руб., расход - {{expenses}} руб.": "Deposits - {{income}} rub., expense - {{expenses}} rub.",
      "Подключенные услуги": "Service package",
      "Можно подключить": "You can connect",
      "Узнать больше": "Learn more",
      "Быстрый переход": "Quick pass",
      "Управление": "Management",
      "декабрь": "december",
      "январь": "january",
      "февраль": "february",
      "март": "march",
      "апрель": "april",
      "май": "may",
      "июнь": "june",
      "июль": "july",
      "август": "august",
      "сентябрь": "september",
      "ноябрь": "november",
      "Автоинформатор": "Autoinformator",
      "Заказ звонка": "Call order",
      "Исходящие каналы": "Outgoing channels",
      "Интеграция с системами CRM": "Integration with CRM systems",
      "Входящие каналы": "Incoming channels",
      "Использование внешнего номера для входящих вызовов": "Using an external number for incoming calls",
      "Функции оператора ВКЦ": "Operator functions VCC",
      "Использование внешнего номера для исходящих вызовов": "Using an external number for outgoing calls",
      "Частный маршрут": "Private route",
      "Выборочная запись разговоров": "Selective recording of conversations",
      "Дополнительный пользователь": "Additional user",
      "Виртуальный Контакт Центр": "Virtual Contact Center",
      "Ежемесячная абонентская плата за услугу «Новая телефония»": "Monthly subscription fee for the service",
      "Добровольная блокировка": "Voluntary blocking",
      "Коллтрекинг, Лид и Чат": "Calltracking, Lead and Chat",
      "Обещанный платёж": "Promised payment",
      "Речевая аналитика": "Speech analytics",
      "Данные всех звонков автоматически попадают в вашу CRM для комфортной работы ваших сотрудников с клиентами": "All calls data automatically gets into your CRM for the comfortable work of your employees with customers",
      "Аналитические данные и отчеты по вызовам, эффективности сотрудников компании": "Analytical data and reports on calls, efficiency of the company's employees",
      "Новые возможности контактного центра: организация очереди абонентов, обслуживание VIP-клиентов первыми, расширенная отчетность по операторам, возможность супервизора подключиться в разговор к оператору.": "New features of the contact center: organization of a queue of subscribers, VIP customer service first, expanded reporting on operators, the ability of the supervisor to connect to the operator in a conversation.",
      "Абонентская плата за каждый дополнительный номер АВС": "Subscription fee for each additional ABC number",
      "Для каждого сотрудника, отдела или филиала можно выделить отдельный городской номер, вам всегда смогут дозвониться": "For each employee, department or branch, you can allocate a separate city number, you will always be able to get through",
      "Ежемесячная абонентская плата за услугу «Виртуальная АТС»": "Monthly subscription fee for «Virtual PBX» service",
      "Пакет минут исходящей связи, который расходуется на исходящие и переадресованные вызовы на территории Российской Федерации": "A packet of minutes of outgoing communication, which is spent on outgoing and forwarded calls on the territory of the Russian Federation",
      "Обратный звонок": "Callback ",
      "Виджет позволяет посетителям сайта быстро оставить заявку на обратный звонок в любое время": "The widget allows site visitors to quickly leave a request for a callback at any time",
      "Подключено сценариев": "Scripts connected",
      "Активных": "Active",
      "запланированных": "planned",
      "Подключено каналов": "Channels connected",
      "Количество услуг": "Number of services",
      "Не подключено": "Not connected",
      "Отображаемое в системе название": "Name displayed in the system",
      "Часовой пояс": "Time zone",
      "Номер для исходящих вызовов по умолчанию": "The default number for outgoing calls",
      "Не указан": "Not specified",
      "Номер для перехвата вызовов": "Number for intercepting calls",
      "Номер для перехвата вызовов - Электронная почта для уведомлений": "Number for intercepting calls - Email for notifications",
      "Электронная почта для уведомлений - Адрес электронной почты должен быть в формате имя_пользователя@имя_домена": "Email for notifications - The email address must be in the format user_name@domain_name",
      "Уведомлять, когда баланс ниже": "Notify when the balance is lower",
      "Для опции «Уведомлять, когда баланс ниже» поле email не может быть пустым ": "For the option «Notify when the balance is lower», the email field cannot be empty",
      "Ограничение направления вызовов для новых пользователей": "Limiting the direction of calls for new users",
      "Запрет международных вызовов": "Prohibition of international calls",
      "Запрет всех вызовов, кроме внутренних": "Prohibition all calls except internal ones",
      "Запрет международных и междугородних вызовов": "Prohibition of international calls",
      "Запрет всех вызовов кроме внутренних": "Prohibition all calls except internal ones",
      "Вызовы по всем направлениям, доступным по тарифному плану": "Calls in all directions available under the tariff plan",
      "Вызовы по всем направлениям, кроме международных": "Calls in all directions, except international",
      "Вызовы по всем направлениям, кроме международных и междугородних по отношению к текущему домену": "Calls in all directions, except international calls in relation to the current domain",
      "Только внутренние вызовы внутри домена": "Only internal calls within the domain",
      "Вы можете выбрать один из сценариев ограничения исходящих вызовов, он будет использоваться по умолчанию для всех пользователей домена. Можно переопределить эти ограничения в": "You can choose one of the scenarios for limiting outgoing calls, it will be used by default for all domain users. Override these restrictions in",
      "Настройки голосовых сценариев": "Voice scripts settings",
      "Количество ошибок ввода": "Number of input errors",
      "Количество ошибок, которое вызывающий абонент может совершить в голосовом сценарии (например, нажатие неопределенной клавиши). При превышении установленного параметра телефонный вызов сбрасывается.": "The number of errors that the caller can make in the voice script (for example, pressing an undefined key). If the set parameter is exceeded the phone call is reset",
      "Таймаут": "Timeout",
      "Промежуток времени, в течение которого вызывающему абоненту следует выбрать интересующий его пункт голосового сценария.": "The period of time during which the caller should select the item of the voice script that interests him.",
      "Команды тонового набора": "Tone dial commands",
      "Перевод вызова": "Call transfer",
      "Укажите комбинацию клавиш в тональном наборе для перевода вызова на другого пользователя или произвольный телефонный номер. Во время разговора набирается: <заданная комбинация> <телефонный номер> #": "Specify the key combination in the tone dial to transfer the call to another user or an arbitrary phone number. During a call the following is dialed: <preset combination> <phone number> #",
      "Перевод на факс-аппарат": "Transfer to a fax",
      "Укажите комбинацию клавиш в тональном наборе для подключения другого пользователя к конференции. Во время разговора набирается: <заданная комбинация> <телефонный номер> #": "Specify the key combination in the tone set to connect another user to the conference. During a call the following is dialed: <preset combination> <phone number> #",
      "Отключение от конференции": "Disconnect from the conference",
      "Укажите комбинацию клавиш в тональном наборе для отключения участника конференции. Во время разговора набирается: <заданная комбинация> <телефонный номер участника> #": "Specify the key combination in the tone set to turn off the conference participant. During the conversation the following is typed: specified combination > <participant's phone number> #",
      "Удержание вызова": "Call hold",
      "Сценарий по умолчанию.": "Default script.",
      "E-mail для уведомлений": "Notifications e-mail",
      "Укажите комбинацию клавиш в тональном наборе для перевода вызова на факс. Во время разговора набирается: <заданная комбинация>": "Specify the key combination in the tone dial to transfer the call to the fax. During a conversation the following is typed: <specified combination>",
      "Укажите комбинацию клавиш в тональном наборе для постановки вызова на удержание и снятия вызова с удержания. Во время разговора (или на удержании) набирается: <заданная комбинация>": "Specify a touchtone key combination to place a call on hold and take a call off hold. During a call (or on hold), the following is dialed: <preset combination>",
      "Выберите номер": "Select number",
      "Входит в пул доменов": "Included in domains pool",
      "маршрутный номер": "route number",
      "настройках пользователя": "user settings",
      "Приветствия и музыка": "Greetings and music",
      "Здесь можно настроить голосовые сообщения и мелодии, применяемые на весь домен, по умолчанию.": "Here you can configure voice messages and ringtones applied to the entire domain by default.",
      "Мелодия на удержании вызова": "Ringtone on call hold",
      "При постановке собеседника на удержание ему будет проигрываться загруженная мелодия.": "When the interlocutor is put on hold, the downloaded melody will be played to him",
      "Выбор мелодии": "Choose a melody",
      "Мелодия": "Melody",
      "Настройки по умолчанию можно изменить для отдельных пользователей.": "The default settings can be changed for certain users.",
      "файл не загружен": "file not uploaded",
      "Удалить загруженный файл?": "Delete uploaded file?",
      "Номера других операторов": "Numbers of other operators",
      "В списке отображаются маршруты домена(с исходящей SIP-регистрацией), которые используются для внешних номеров.": "The list shows the domain routes (with outgoing SIP registration) that are used for external numbers.",
      "Маршрут удаляется автоматически при удалении всех связанных с ним внешних номеров.": "Маршрут удаляется автоматически при удалении всех связанных с ним внешних номеров.",
      "Статус SIP-регистрации": "SIP registration status",
      "Допустимы латинские буквы, цифры и символы \"-\", \"_\", \".\"": "Latin letters, numbers and \"-\", \"_\", \".\" symbols are allowed",
      "Невозможно определить адрес сервера": "It is impossible to determine the server address",
      "Преобразование B-номера": "B-number transformation",
      "Скопировать": "Copy",
      "Скопировано!": "Copied!",
      "При совершении исходящих вызовов набранный номер автоматически преобразуется в формате E.164 (например, \"+79161234567\", \"+74991234567\"). Если для внешнего оператора требуется другой формат номера (например, \"9161234567\" или \"89161234567\"), то можно сделать соответствующее преобразование.": "When making outgoing calls, the dialed number is automatically converted to E.164 format (for example, \"+79161234567\", \"+74991234567\"). If the external operator requires a different number format (for example, \"9161234567 \" or \"89161234567\"), then you can make the appropriate conversion.",
      "Удалить группу у пользователя?": "Do yor really want to delete user group?",
      "Максимальная длина 100 символов": "Maximum field length is 100 characters",
      "Запрос успешно выполнен": "Successful request",
      "Домен, логин и пароль используются для входа в личный кабинет, регистрации в SIP-телефоне и программном телефоне": "Domain, login and password are used to enter your personal account, register in the SIP phone and softphone",
      "Всегда онлайн": "Always online",
      "Изменить логин / пароль": "Change login / password",
      "Запись будет храниться дней": "The record to be stored, days",
      "Комбинированный показатель": "Combined indicator",
      "Не установлено ограничений исходящих вызовов для всех пользователей домена": "No restrictions on outgoing calls",
      "Включено общее ограничение международных вызовов для всех пользователей домена": "Outgoing calls restrictions enabled for all domain users",
      "Включено общее ограничение международных и междугородних вызовов для всех пользователей домена": "Enabled general restriction of international and long distance calls for all domain users",
      "Включено общее ограничение всех вызовов кроме внутренних для всех пользователей домена": "Enabled general restriction of all calls except internal calls for all domain users",
      "Отправлять уведомление о голосовых сообщениях на": "Send voice messages notification to",
      "Темы вызовов": "Calls themes",
      "Добавить тему": "Add theme",
      "Название темы": "Theme name",      
      "Настройка рабочего места оператора": "Operator's workplace settings",
      "Оповещать об успешных вызовах": "Notify about successful calls",
      "Оповещать о пропущенных вызовах": "Notify about missed calls",
      "Настроить прием входящих вызовов": "Set up incoming calls",
      "Текстовое приветствие": "Text greeting",
      "Время на прием звонка, с": "Time to receive a call, s",
      "Время поствызывной обработки, с": "After Call Work Time, s",
      "Среднее время разговора, с": "Average talk time, s",
      "Время, в течение которого на РМО не распределяются вызовы по окончании обслуживания вызова": "Time during which calls are not propagated to the OWS after the end of call service",
      "При неответе оператора в течение заданного времени, его РМО переходит в состояние технического перерыва": "If the operator does not answer within a specified time, his OWS goes into a technical break state",
      "Текстовое приветствие отображается в РМО при поступлении вызова на оператора": "A text greeting is displayed in OWS when an operator is called",
      "Значение средней продолжительности вызова в секундах, используемое при произнесении примерного времени ожидания в очереди": "The average call duration value in seconds used when pronouncing the approximate waiting time in the queue",
      "URL для взаимодействия": "URL for interaction",
      "Ссылка, открываемая встроенным браузером РМО при поступлении вызова на оператора": "Link opened by the built-in OWS browser when a call comes to an operator",
      "Состав группы": "Group composition",
      "Добавить участников": "Add users",
      "Внутренний номер, группы и метки": "Extension number, groups and tags",
      "Чем меньше число в поле, тем выше приоритет пользователя при распределении вызовов внутри группы": "The lower the number in the field, the higher the user's priority when distributing calls within the group",
      "Нет прочих групп": "No other groups",
      "Сделать оператором": "Make an operator",
      "Голосовое приветствие перед началом разговора": "Voice greeting before starting a conversation",
      "Конференция, приглашение от...": "Conference, invitation from...",
      "Исходящий неудачный": "Outgoing call failed",
      "Входящий пропущенный": "Incoming call missed",
      "Исходящий": "Outgoing call",
      "Исходящий, перенаправлен к...": "Outgoing, redirected to...",
      "Групповой пропущенный": "Missed group call",
      "Групповой": "Group",
      "мин, сек": "Min, sec",
      "К списку групп":"Back to groups list",
      "Действительно исключить выбранных пользователей из группы?": "Do you really want to remove selected users from group?",
      "Исключить из группы": "Remove from group",
      "Добавить пользователя в группу": "Add users to group",
      "Исключить": "Remove",
      "Удалить сообщение голосовой почты": "Delete voicemail message",
      "Удалить сообщение голосовой почты?": "Do you really want to delete voicemail message?",
      "изменением": "a changing",
      "увеличением": "an increasing",
      "уменьшением": "a decreasing",
      "с входящей связью": "with an incoming call",
      "с входящей и исходящей связью": "with an incoming and outgoing call",
      "Приложения для ПК и Мобильного телефона": "PC and Mobile Phone Applications",
      "Приложение для Windows": "Windows",
      "Приложение для iOS": "iOS",
      "Приложение для MacOS": "MacOS",
      "Приложение для Android": "Android",
      "Плата за дополнительный номер": "Additional number fee",
      "Запись и хранение всех разговоров сотрудников, которые можно прослушать в режиме онлайн или скачать в виде файлов": "Recording and storage of all conversations of employees, which can be listened to online or downloaded as files",
      "Сотрудники вашей компании, использующие Виртуальную АТС": "Employees of your company using a Virtual PBX",
      "Мобильные рабочие места позволяют объединить городские и мобильные номера в единую корпоративную сеть с внутренней нумерацией для бесплатных звонков внутри сети. Подключить услугу можно в правилах переадресации пользователя": "Mobile workplaces allow combining landline and mobile numbers into a single corporate network with internal numbering for free calls within the network. You can activate the service in the user forwarding rules",
      "Установив данный пункт, вы подтверждаете, что информированы о том, что изменения настроек влекут за собой изменение стоимости услуг": "By setting this item, you confirm that you are informed that changes to the settings entail a change in the cost of services",
      "Подключение бесплатное": "Activation is free of charge",
      "Изменение бесплатное": "Changing is free of charge",
      "{{action1}} услуги для <b>{{count}} {{usersString}}</b> {{action2}} ежемесячные платежи": "{{action1}} of service for <b>{{count}} {{usersString}}</b> will {{action2}} fee",
      "увеличит": "increase",
      "уменьшит": "decrease",
      "Сообщение, которое проигрывается перед началом переадресации на голосовую почту в случае недоступности всех номеров.": "The message that is played before the start of forwarding to voicemail if all numbers are unavailable.",
      "switchLanguage": "Русский",
      "Пользователь не найден": "User not found",
      "У пользователя не настроен адрес электронной почты": "User has no email",
      "Неверный логин или пароль": "Invalid username or password",
      "Базовый пакет": "Basic package of services",
      "Абонентская плата за пакет пользователей": "Subscription fee for users",
      "Абонентская плата за пакет операторов": "Subscription fee for operators",
      "Управление IVR": "IVR management",
      "Контакт-центр": "Contact center",
      "Управление вызовами": "Call control",
      "Настройка приветствия": "Setting up greetings",
      "На Ваш почтовый ящик выслано письмо с дальнейшими инструкциями.": "We have sent you an e-mail with further instructions.",
      "Домен не найден": "Domain not found",
      "Восстановление пароля": "Restore password",
      "Нажмите на изображение, чтобы обновить его": "Click image to update it",
      "Введите текст с картинки": "Enter captcha",
      "1 месяц": "1 month",
      "по умолчанию": "by default",
      "Неправильный код с картинки": "Captcha error",
      "Ограничение исходящих вызовов для новых пользователей": "Outgoing calls restrictions for new users",
      "Не достаточно прав для совершения операции": "You are not authorized to perform this operation",
      "Собеседник": "Interlocutor",
      "Дублирование пользователя": "Duplicate user",
      "Редактирование пользователя": "Edit user",
      "Копия": "Copy of",
      "После отключения услуги для пользователя уже существующие записи разговоров будут храниться в соответствии с выбранными настройками, новые разговоры пользователя записываться не будут.": "After disconnecting the service for the user, the existing call records will be stored in accordance with the selected settings, new user conversations will not be recorded.",
      "Названия групп": "Groups names",
      "АОН для исходящих вызовов": "Caller ID for outgoing calls",
      "Внутренний": "Internal",
      "Исходящий неуспешный": "Outgoing failed",
      "Входящий": "Incoming",
      "Удалить голосовую почту": "Delete voicemail",
      "{{action1}} услуги {{action2}} платежи": "{{action1}} of service will {{action2}} fee",
      "Услуга «Добровольная блокировка» может быть подключена на срок не более 180 дней. По истечении этого срока услуга автоматически отключится.": "The «Voluntary blocking» service can be activated for a period not exceeding 180 days. After this period, the service will be automatically deactivated.",
      "Ежемесячная плата не изменится": "Monthly fee will not change",
      "Ежедневная плата не изменится": "Daily fee will not change",
      "проверить": "check",
      "Вызов будет сброшен. Абонент получит сигнал «линия занята».": "Сall will be dropped. The subscriber  user will receive a signal «the line is busy».",
      "Перевод на пользователя": "Transfer to user",
      "Перевод на группу": "Transfer to group",
      "Редактировать сценарий": "Edit script",
      "Время – с": "Time from",
      "Время – до": "Time up to",
      "Номер по умолчанию удалять нельзя": "You can't delete the default number",
      "Показать полный список": "Show full list",
      "В АОН номер домена": "In caller ID number of domain",
      "Всегда подставлять в АОН номер домена при вызовах FMC-ТФОП": "Always substitute the domain number in the caller ID when making FMC-PSTN calls",
      "Номер этого типа должен содержать 11 цифр и может начинаться с \"+\"": "This type of number must be 11 digits long and may start with \"+\"",
      "Номер этого типа должен содержать только цифры и может начинаться с \"+\"": "This type of number must contain only numbers and may start with \"+\"",
      "Допустимы значения от 0 до 120": "Valid values are from 0 to 120",
      "Допустимы значения от 0 до 10000": "Valid values are from 0 to 10000",
      "Ошибка при сохранении файла": "Saving file error",
      "Поддерживаются только форматы mp3, wav": "Only mp3, wav formats are supported",
      "Ошибка во время выполнения.": "Error processing request.",
      "DISA отключен для входящей линии": "DISA disabled for incoming line",
      "DISA отключен для пользователя": "DISA disabled for user",
      "Ввод пароля в DISA": "Entering a password in DISA",
      "Входящий переадресованный аутентифицированный": "Incoming redirected authenticated",
      "Вызов Click-To-Record": "Click-To-Record call",
      "Вызов внешнего номера": "External number call",
      "Вызов внутреннего номера": "Internal number call",
      "Вызов на входящую линию": "Calling an incoming line",
      "Вызов неудачен (код SIP)": "Call failed (SIP code)",
      "Вызов отменен": "Call cancelled",
      "Вызов с виджета CallMe": "Call via CallMe widget",
      "Вызов спецслужбы": "Special service call",
      "Достигнуто максимальное число ошибок ввода": "Maximum number of input errors reached",
      "Начало записи голосового сообщения": "Start recording a voice message",
      "Неверный пароль DISA": "DISA wrong password",
      "Нет активных контактов": "No active contacts",
      "Нет активных контактов для обратного вызова": "No active contacts to call back",
      "Нет активных операторов": "No active operators",
      "Отмена перевода": "Transfer cancel",
      "Ошибка ввода внутреннего номера": "Extension number input error",
      "Пароль для доступа в DISA не задан": "DISA access password not set",
      "Переадресация SIP": "SIP Forwarding",
      "Переадресация на IVR-сценарий": "IVR-script forwarding",
      "Переадресация на голосовую почту": "Voicemail forwarding",
      "Переадресация на группу ACD": "ACD group forwarding",
      "Переадресация на группу «Вызов всем»": "Forwarding to the «Call to all» group",
      "Переадресация на пользователя": "Forwarding to user",
      "Перевод неудачен": "Forwarding failed",
      "Перехват выполнен": "Interception completed",
      "Перехват не выполнен": "Interception failed",
      "Перехват отвергнут": "Interception rejected",
      "Переход на IVR меню": "Switch to IVR menu",
      "Подтверждение перевода": "Confirmation of transfer",
      "Попытка входа в DISA": "Attempting to login to DISA",
      "Попытка перевода": "Attempt to transfer",
      "Постановка вызова в очередь": "Adding a call to the queue",
      "Постановка на удержание": "Put on hold",
      "Проигрывание приветствия": "Playing a greeting",
      "Слепой перевод": "Blind transfer",
      "Снятие с удержания": "Removal from hold",
      "Соединение завершено": "Connection completed",
      "Соединение перехвачено": "Connection intercepted",
      "Соединение установлено": "Connection established",
      "Таймаут ввода": "Input timeout",
      "Таймаут голосового сообщения": "Voice message timeout",
      "Успешная попытка перехвата": "Successful interception attempt",
      "Успешный вход в DIS": "Successful DIS login",
      "Контакты домена": "Domain contacts",
      "Неизвестная ошибка": "Unknown error",
      "Администратор контакт-центра": "Contact center administrator",
      "Администратору контакт-центра доступна возможность контроля за работой операторов": "The contact center administrator has the ability to control the work of operators",
      "Value is required and can't be empty": "Value is required and can't be empty",
      "Скачать отчет": "Download report",
      ", запланированных": ", planned",
      "Дополнительные поля пользователей": "Additionall user fields",
      "Поле": "Field",
      "Допустимо максимум 50 символов": "Maximum of 50 characters is allowed",
      "Разрешить ввод комментариев": "Allow comments",
      "Операторы смогут при обработке вызовов добавлять комментарии, которые будут доступны в отчете": "Operators will be able to add a comments when processing calls, which will be available in the report",
      "Уведомление о пропущенных вызовах на e-mail": "Missed calls e-mail notifications",
      "Отправка уведомлений на email": "Send notifications to email",
      "Не отправлять": "Do not send",
      "Выберите тип": "Choose type",
      "Отправлять ссылку": "Send link",
      "Отправлять файл": "Send file",
      "Интеграция с внешними системами": "External systems integration",
      "Виртуальный колцентр": "Virtual call center",
      "Базовый пакет по-умолчанию": "Default base package",
      "Частные маршруты (PRIVATE_ROUTE)": "SIP-trunks (PRIVATE_ROUTE)",
      "Универсальный API": "Universal API",
      "На Ваш адрес электронной почты отправлено письмо. Для проверки указанного адреса электронной почты перейдите, пожалуйста, по ссылке из письма.": "An email has been sent to your email address. To check the specified e-mail address, please follow the link from the letter.",
      "Использование FMC возможно только для номеров Ростелекома": "FMC using is possible only for Rostelecom numbers",
      "Тип домена": "Domain type",
      "Изменить тип домена": "Change domain type",
      "Административные настройки": "Administrative settings",
      'Статистика по пользователям домена': 'Statistics on domain users',
      'В тарифном плане отсутствует данная услуга': 'This service is not included in the tariff plan',
      'Имя сотрудника': 'Employee\'s name',
      'Кол-во принятых вызовов': 'Number of calls received',
      'Кол-во пропущенных вызовов': 'Number of missed calls',
      'Кол-во исходящих вызовов': 'Number of outgoing calls',
      'Среднее кол-во принятых за день': 'Average number of accepted per day',
      'Среднее кол-во пропущенных за день': 'Average number of missed per day',
      'Среднее кол-во исходящих за день': 'Average number of outgoing calls per day',
      'Все сотрудники': 'All employees',
      'Скачать': 'Download',
      'По вашему запросу нет данных': 'There is no data for your request',
      'Скачать за выбранный период': 'Download for the selected period',
      'Дата От': 'Date From',
      'Дата До': 'Date Before',
      'Текущий день': 'Current Day',
      'Предыдущий день': 'Previous day',
      'Текущая неделя': 'Current week',
      'Предыдущая неделя': 'Previous week',
      'Текущий месяц': 'Current Month',
      'Предыдущий месяц': 'Previous Month',
      'День': 'Day',
      'Пред. день': 'Pre-day',
      'Пред. неделя': 'Pre-week',
      'Пред. Месяц': 'Prev. Month',

      "Достигнуто предельное число контактов общей адресной книги домена": "The maximum number of contacts in the domain's shared address book has been reached",
      "Достигнуто лицензионное ограничение на общее число пользователей в системе. Пожалуйста, обратитесь в службу поддержки.": "The license limit on the total number of users in the system has been reached. Please contact customer support.",
      "Достигнуто предельное число пользователей в домене": "The maximum number of users in the domain has been reached",
      'Достигнуто максимальное число пользователей для группы с алгоритмом распределения "Всем пользователям"': 'The maximum number of users for a group with the "All users" distribution algorithm has been reached',
      "Достигнуто предельное число операторов в системе. Пожалуйста, обратитесь в службу поддержки.": "The maximum number of operators in the system has been reached. Please contact customer support.",
      "Превышено ограничение на число контактов общей адресной книги": "The limit on the number of contacts in the shared address book has been exceeded",
      'Превышено максимальное число пользователей для группы с алгоритмом распределения "Всем пользователям"': 'Exceeded the maximum number of users for a group with the "All users" distribution algorithm',
      "Превышено лицензионное ограничение на общее число пользователей в системе": "Exceeded the license limit on the total number of users in the system",
      "В результате операции будет превышен общий лимит операторов в системе. Пожалуйста, обратитесь в службу поддержки.": "As a result of the operation, the total limit of operators in the system will be exceeded. Please contact customer support.",
      "В результате операции будет превышено предельное число операторов в домене.": "As a result of the operation, the maximum number of operators in the domain will be exceeded.",
      'В результате операции будет превышено максимальное число пользователей для группы с алгоритмом распределения "Всем пользователям"': 'As a result of the operation, the maximum number of users for the group with the "All users" distribution algorithm will be exceeded',

      "Список номеров не должен быть пустым": "The list of numbers should not be empty",
      "Отправка... ({{totalNumbersCount}} номеров)": "Sending... ({{totalNumbersCount}} numbers)",
      "Конвертирование невозможно": "Conversion is not possible",
      "Номер не может быть пустым": "The number cannot be empty",
      "Введите номер": "Enter the number",
      "Загрузить список номеров (максимум {{phonesLimit}})": "Download the list of numbers (maximum {{phonesLimit}})",
      "Шаблон списка": "List template",
      "Загрузить": "Download",
      "Ошибки валидации файла": "File validation errors",
      "подробнее": "in detail",

      /// Графики для отчётов колл-центра
      "Таблица": "Table",
      "График":  "Chart",
      "Длительность": "Duration",
      "Нач.": "Start",
      "Кон.": "End ",
      "Группа": "Group",
      "Уровень обслуживания": "Service level",
      "Время [минут]": "Time [min]",
      "Время [секунд]": "Time [sec]",
      "выз": "call(s)",
      "По количеству вызовов": "By calls count",
      "Общее время разговора": "Common talk time",
      "Состояния по сменам": "States by shifts",
      "Тип визуализации не определён": "Visualisation type is not defined",
      "Максимальная длина очереди": "Max queue length",
    
      "день":     "day",
      "месяц":    "month",
      "год":      "year",
      "дня":      "days",
      "месяца":   "months",
      "года":     "years",
      "дней":     "days",
      "месяцев":  "months",
      "лет":      "years",
      
      "Принятые вызовы за период построения отчёта": "Accepted calls during the reporting period",
      "Пропущенные вызовы за период построения отчёта": "Abandoned calls during the reporting period",
      "Принятые вызовы": "Accepted calls",
      "Пропущенные вызовы": "Abandoned calls",
      "Максимальное число одновременно занятых линий": "Maximum number of lines occupied at the same time",
      "Принято": "Accepted",
      "Потеряно": "Abandoned",
      "Среднее": "Average",
      "Максимальное": "Maximum",
      "Продолжительность нахождения в статусе": "Duration of stay in the status",
    
      "Нажмите мышкой на пункт в легенде,<br/> чтобы управлять соответствующим объектом на диаграмме.": "Click on the item in the legend,<br/> to control the corresponding object on the diagram.",
      "Нажмите мышкой на элемент диаграммы, чтобы увидеть информацию о принятых / пропущенных вызовах для {{infoLabelPart}}.": "Click on the diagram element to see information about accepted / abandoned calls for {{infoLabelPart}}.",
      "каждого из [элементов]": "each of the [elements]",
      "каждой из групп": "each of the groups",
      "каждого из операторов": "each of the operators",
    
      "Соотношение принятых / потерянных вызовов": "The ratio of accepted / abandoned calls",
      "Общее число вызовов": "Total number of calls",
      "Общее количество вызовов": "Total number of calls",
      "Всего по оператору": "Total by operators",
      "Всего по группе": "Total by group",
      "Количество вызовов": "Number of calls",
      "Max время до потери вызова": "Max time to call loss",
      "Время реакции": "Reaction Time",
      "Всего за период": "Total by period",
      "Всего за интервал": "Total by interval",
      "Вызовы по группам за весь период": "Calls by groups for the whole period",
      "Среднее за весь период": "Average by whole period",
      "Среднее за интервал": "Average by interval",
      "Среднее время {{talkType}} по группам за весь период": "Average {{talkType}} time by group for the whole period",
      "разговора": "talk",
      "реакции": "reaction",
      "За интервал": "By interval",
      "За весь период": "By whole period",
      "Среднее / Максимальное время до {{callType}} вызова по группам за весь период [секунд]": "Average / Maximum time to {{callType}} by group for the whole period [seconds]",
      "потери": "call loss",
      "приёма": "receive a call",
      "Сред.": "Avg",
      "Макс.": "Max",
      "Уровень обслуживания по группам за весь период": "Service level by group for the whole period",
      "Средний уровень обслуживания по выбранным группам за весь период": "The average level of service for the selected groups for the whole period",
    
      "В отчет попадают вызовы, пришедшие из частных маршрутов<br/>и ушедшие в обычный маршрут.": "The report includes calls that came from private routes<br/>and went to the usual route."

    }
  },
};


i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    /*fallbackLng: window.localStorage.getItem("language") ? window.localStorage.getItem("language") : "ru",
    detection: {
      order: ["localStorage", "cookie"],
    },*/
    lng: window.localStorage.getItem("language") ? window.localStorage.getItem("language") : "ru", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;