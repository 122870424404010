import * as types from '../config/types'
import initialState from "../config/initialState";

/** Раздел Cache.
 * Данный раздел (cache) хранилища служит для хранения данных не требующих применения для разных задач
 * В своей работе опираться на данные по чужим ключам крайне не рекомендуется.
 * Каждый ключ служит для своей конкретной интерфейсной цели, носит лишь вспомогательный характер
 * Каждый ключ требуется определить в types.cache.* и в initialState.cache.* и всю работу с ним вести через ключ
 * @method cacheSet
 * @method cacheGet
 * @const checkAvailableKeys
 * @const KeyNotDefinedException
 * @const getAllKeys
 * */

/**
 * Запись данных в кеш по заранее определенному ключу
 * @param {types.cache} key - Ключ хранилища cache
 * @param {Object} newState - Новое состояние
 * @param {string[]|null} [only] - Массив аттрибутов из newState, которые нужно сохранять (удобно, когда передается полное состояние, но не все надо обновлять)
 * @param useSessionStorage - Использовать sessionStorage? будет смотреть туда приоритетно. Из минусов - не может кешировать классы и методы (простые типы и объекты может)
 */
export function cacheSet(key, newState, only = Object.keys(newState), useSessionStorage = false) {
  return dispatch => {
    const params = {}

    // Проверка корректности ключа кеша
    if (!checkAvailableKeys(key)) {
      throw new KeyNotDefinedException(key)
    }

    only.forEach(param => {
      if (newState.hasOwnProperty(param)) params[param] = newState[param]
    });

    if (Object.keys(params).length) {
      dispatch({
        type: types.cache.SET,
        key, params, useSessionStorage
      })
    }
  }
}

/**
 * Получение данных из кеша по заранее определенному ключу
 * @param {types.cache} key - Ключ хранилища cache
 * @param {string|null} [name] - Имя аттрибута хранимого состояния, который будет возвращен
 * @param {string|null} [def] - Значение по умолчанию
 * @param useSessionStorage - Использовать sessionStorage?
 */
export function cacheGet(key, name = null, def = null, useSessionStorage = false) {
  return (dispatch, getState) => getCacheFromAny(key, name, def, getState(), useSessionStorage)
}

/**
 * Получение значения кеша по ключу из State или sessionStorage (если используется)
 * @param key
 * @param name
 * @param def
 * @param state
 * @param useSessionStorage
 * @returns {*|boolean|string|string}
 */
export const getCacheFromAny = (key, name, def, state, useSessionStorage = false) => {
  const cache = state.cache[key] || (useSessionStorage && getSessionCache(key))

  return name === null ? cache : (cache?.[name] || def)
}

/**
 * Сохраняет кеш по ключу в sessionStorage
 * @param key
 * @param val
 */
export const setSessionCache = (key, val) => {
  if (typeof val === 'object') {
    sessionStorage.setItem(getSessionStorageCacheKey(key), JSON.stringify(val))
  } else {
    sessionStorage.setItem(getSessionStorageCacheKey(key), val)
  }
}

/**
 * Создает ключ для хранения в sessionStorage
 * @param key
 * @returns {string}
 */
const getSessionStorageCacheKey = key => 'cache:' + key

/**
 * Получает кеш по ключу из sessionStorage
 * @param key
 * @returns {string|string}
 */
export const getSessionCache = (key) => {
  const valStr = sessionStorage.getItem(getSessionStorageCacheKey(key)) || '{}'
  let val      = valStr

  try {
    val = JSON.parse(valStr)
  } catch (e) {
    //nothing
  }

  return val
}

/** Определяет корректность определения ключа в initialState.cache.* и types.cache.* */
const checkAvailableKeys = key => {
  const systemKeys = [
    types.cache.SET,
  ]

  return getAllKeys(types.cache).includes(key) && !systemKeys.includes(key) && Object.keys(initialState.cache).includes(key)
}

/** Объект ошибки когда ключ неопределен корректно */
const KeyNotDefinedException = function (key) {
  this.value    = key;
  this.message  = `Ключ ${key} не определен! Нельзя использовать систему кеширования  
      не создав в initialState свой ключ (key) в разделе 
      initialState.cache.*, а так же в types.cache.*`;
  this.toString = function () {
    return this.message
  };
}

/** Ищет все ключи */
const getAllKeys = o => {
  const toArr = obj => Object.values(obj).map(prop => typeof prop === 'string' ? prop : toArr(prop))
  return toArr(o).flat(Infinity)

}