import initialState from 'config/initialState'
import * as types from 'config/types'

export const getDomainSettings = (state) => state.domainSettings

export default function reducer(state = initialState.domainSettings, action) {
	switch (action.type) {
		case types.domain.GET_SETTINGS: {
			const { domainSettings } = action
			const newState = Object.assign({}, state, domainSettings)
			return newState
		}

		case types.domain.GET_BALANCE: {
			const { balance } = action
			const newState = Object.assign({}, state, { balance: balance })
			return newState
		}

		case types.domain.GET_SERVICE_CHARGE: {
			const { serviceCharge } = action
			const newState = Object.assign({}, state, { serviceCharge: serviceCharge })
			return newState
		}

		// изменить счетчик групп / пользователей
		case types.domain.INCREMENT_COUNTER: {
			let newCounters = Object.assign({}, state.counters, {[action.value.counter]: state.counters[action.value.counter] + action.value.change})
			const newState = Object.assign({}, state, { counters: newCounters })
			return newState            
		}

		// получить / обновить опции периодов хранения записей звонков
		case types.domain.SEL_RECORDING_SETTINGS: {
			const newState = Object.assign({}, state, { selRecordingSettings: action.result })
			return newState;
		}

		// получить типы домена
		case types.domain.GET_TYPE: {
			const newState = Object.assign({}, state, { type: action.result })
			return newState
		}

		default:
			return state
	}
}
