import initialState from '../config/initialState'

export const getFullscreen = (state) => state.vatsThemeOptions.fullscreen;

export const SET_ENABLE_MOBILE_MENU = 'THEME_OPTIONS/SET_ENABLE_MOBILE_MENU';
export const SET_FULLSCREEN         = 'THEME_OPTIONS/SET_FULLSCREEN';
export const SET_SVETOFON           = 'THEME_OPTIONS/SET_SVETOFON';

export const setEnableMobileMenu = enableMobileMenu => ({
    type: SET_ENABLE_MOBILE_MENU,
    enableMobileMenu
});

// полноэкранный просмотр данных
export const setFullscreen = fullscreen => ({
    type: SET_FULLSCREEN,
    fullscreen
})

// шаблон для светофона
export const setSvetofon = svetofon => ({
    type: SET_SVETOFON,
    svetofon
})

export default function reducer(state = initialState.vatsThemeOptions, action) {
    switch (action.type) {
        case SET_ENABLE_MOBILE_MENU:
            return {...state, enableMobileMenu: action.enableMobileMenu};

        case SET_FULLSCREEN:
            return {...state, fullscreen: action.fullscreen};

        case SET_SVETOFON:
            if (window.sessionStorage) window.sessionStorage.setItem('svetofon', true)
            return {...state, svetofon: action.svetofon};

        default: return state;
    }
}
