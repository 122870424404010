import initialState from '../config/initialState';
import * as types from '../config/types';


export function redirection(state = initialState.redirection, action) {
    switch (action.type) {
        case types.redirection.GET_NUMBERS: {
            const { redirectionNumberList } = action;
            return redirectionNumberList;
        }
        case types.redirection.GET_FMC_NUMBERS: {
            const { fmcNumbers } = action;
            return fmcNumbers;
        }
        case types.redirection.ADD_NUMBER: {
            const {number} = action;
            const newState = Array.from(state);
            newState.push(number);
            return newState;
        }
        case types.redirection.UPDATE_NUMBER: {
            const {number} = action;
            const newState = Array.from(state);
            newState.forEach( (num, i) => {
                if (num.id === number.id) {
                    newState[i] = number;
                }
            });
            return newState;
        }
        case types.redirection.DELETE_NUMBER: {
            const {id} = action;
            const newState = Array.from(state);
            const delIndex = newState.findIndex( el => el.id === id);
            newState.splice(delIndex, 1);
            return newState;
        }       
        default:
            return state;

    }
};