import initialState from '../config/initialState';
import * as types from '../config/types';

export function domainCallHistory(state = initialState.domainCallHistory, action) {
    switch (action.type) {
        case types.domainCallHistory.GET_RESULT: {
            const { callHistory }              = action;
            return {
                ...state,
                data: callHistory.data,
            };
        }
      // добавление итемов
        case types.domainCallHistory.ADD_ITEMS: {
            const { callHistory } = action;
            return {
                ...state,
                data: state.data.concat(callHistory.data)
            };
        }
        case types.domainCallHistory.GET_STAT: {
            const { stat } = action;
            return {
                ...state,
                totalCount: stat.totalCount,
            };
        }

        case types.domainCallHistory.DELETE_VOICEMAIL: {
            let newState = {}
            newState['totalCount'] = state.totalCount;
            newState['data'] = state['data'].map(call => {
                if (call.voiceMail && call.voiceMail.id === action.voicemailId) {
                    return {...call, voiceMail: null}
                } else {
                    return call;
                }
            })
            return newState;
        }

        default:
            return state;
    }
}
