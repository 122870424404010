// import ThemeOptions from './ThemeOptions'
import vatsThemeOptions from './vatsThemeOptions'
import auth from './auth'
import domainSettings from './domain/settings'
import domainUsers from './domainUsers'
import domainUserSettings from './domainUserSettings'
import userSettings from './user/settings'
import { redirection } from './redirection'
import { callHistory, callHistoryIds, callHistoryFilter } from './callHistory'
import { contacts } from './contacts'
import { faxes, faxProtocol } from './faxes'
import apiErrors from './apiErrors'
import { adminHistory } from './adminHistory'
import { domainCallHistory } from './domainCallHistory'
import { domainCallHistoryArchives } from './domainCallHistoryArchives'
import groupSettings from './groupSettings'
import { domainPayments } from './domainPayments'
import { callCenterVips } from './callCenterVips'
import { services } from './services'
import { outgoingCompany } from './outgoingCampany'
import { privateRoutes } from './privateRoutes'
import { operatorMessages } from './operatorMessages'
import loadings from './loadings'
import { domainNumbers } from './domainNumbers'
import { groupCallthemes } from './groupCallthemes'
import { system } from './systemSettings'
import { rmo } from './rmo'
import cache from './cache'

export default {
  // ThemeOptions,
  vatsThemeOptions,
  apiErrors,
  auth,
  domainSettings,
  userSettings,
  domainUserSettings,
  domainUsers,
  redirection,
  outgoingCompany,
  privateRoutes,
  callHistory,
  callHistoryIds,
  callHistoryFilter,
  contacts,
  faxes,
  faxProtocol,
  adminHistory,
  domainCallHistory,
  domainCallHistoryArchives,
  groupSettings,
  domainPayments,
  callCenterVips,
  services,
  operatorMessages,
  loadings,
  domainNumbers,
  cache,
  groupCallthemes,
  system,
  rmo,
}
