import initialState from '../config/initialState'
import * as types from '../config/types'

export const getAuth = (state) => state.auth
export const getAuthReadOnly = (state) => !!state.auth.readOnly
export const getDataView = (state) => state.auth.dataView;
export const getIsSupervisor = (state) => state.auth.isSupervisor;

export default function reducer(state = initialState.auth, action) {
  switch (action.type) {
    case types.auth.LOGIN: {
      const {
        user_id: userId,
        is_supervisor: isSupervisor,
        is_root: isRoot,
        read_only: readOnly,
        user_type: userType,
        domainName,
        token,
      } = action.authData
      if (window.sessionStorage) {
        window.sessionStorage.setItem('userId', userId)
        window.sessionStorage.setItem('isSupervisor', isSupervisor ? 1 : 0)
        window.sessionStorage.setItem('readOnly', readOnly ? readOnly : false)
        // язык по умолчанию (если не указано иного)
        if (!window.localStorage.getItem('language')){
          window.localStorage.setItem('language','ru')
        }
        window.sessionStorage.setItem('userType', userType)
      }
      window.localStorage.setItem('domainName', domainName)
      window.localStorage.setItem('token', token || '')
      const newState = Object.assign({}, state, {
        userId,
        isSupervisor,
        isRoot,
        domainName,
        readOnly,
        token,
        userType,
      })
      return newState
    }

    case types.auth.CHECK: {
      const { 
        user_id, 
        is_supervisor = null, 
        is_root = false, 
        read_only = false,
        token,
        user_type = 0, 
        captcha_image 
      } = action.data
      if (window.sessionStorage) {
        window.sessionStorage.setItem('userId', user_id)
        window.sessionStorage.setItem('isSupervisor', is_supervisor ? 1 : 0)
        window.sessionStorage.setItem('readOnly', read_only ? read_only : false)
        window.sessionStorage.setItem('userType', user_type)
      }
      window.localStorage.setItem('token', token || '')
      const newState = Object.assign({}, state, {
        userId: user_id,
        isSupervisor: is_supervisor,
        isRoot: is_root,
        readOnly: read_only,
        token,
        userType: user_type,
        captcha_image: captcha_image,
      })
      return newState
    }

    case types.auth.LOGOUT: {
      if (window.sessionStorage) {
        window.sessionStorage.clear()
      }
      window.localStorage.removeItem('token')
      const newState = Object.assign({}, state, { 
        userId: 0,
        isSupervisor: false,
        isRoot: false,
        readOnly: false,
        token: null,
        captcha_image: null,
        logoutReason: 'logout',
      })
      return newState
    }

    case types.app.ERROR: {
      const { error } = action
      var newState
      if (error.status === 401) {
        if (window.sessionStorage) {
          window.sessionStorage.clear()
        }
        newState = Object.assign({}, state, { 
          userId: 0,
          isSupervisor: false,
          isRoot: false,
          readOnly: false,
          token: null,
          captcha_image: null,
          logoutReason: '401',
        })
      } else {
        newState = Object.assign({}, state)
      }
      return newState
    }

    case types.auth.RESET_PASSWORD: {
      const newState = Object.assign({}, state, {
        passwordResetMessage: action.data.message,
      })
      return newState
    }

    case types.auth.SET_NEW_PASSWORD: {
      const newState = Object.assign({}, state, {
        setNewPasswordMessage: action.data.message,
      })
      return newState
    }

    // переключение уведомления о стоимости услуг
    case types.auth.SERVICE_CHARGE_NOTICE: {
      const newState = Object.assign({}, state, {
        serviceChargeNotice: action.notice,
      })
      return newState
    }

    // переключение уведомления о стоимости услуг
    case types.auth.LAST_USERNAME: {
      return { ...state, lastUsername: action.value }
    }

    // смена типа просмотра данных (граф / таблица)
    case types.auth.DATA_VIEW: {
      const newState = Object.assign({}, state, {
        dataView: {...state.dataView, [action.result.type]: action.result.dataView} ,
      })
      return newState
    }

    default:
      return state
  }
}
