import * as types from '../config/types';

export function createError(error) {
  return {
    type: types.app.ERROR,
    error
  };
}

export function resetError() {
  return {
    type: types.app.ERROR_RESET,
  };
}

export function parseError(error) {
  let newError = {
    response: -1,
    message: "Ошибка при выполнении запроса",
    errors: [],
  };
  if (error.response && error.response.text.length > 0) {
    try {
      newError = JSON.parse(error.response.text);
    } catch (e) {}
  }
  return newError;
}