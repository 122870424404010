// в редукторе принимается исходное состояние и экшен. В зависимости от типа экшена мы изменяем исходное состояние и возвращаем новое

import initialState from '../config/initialState';
import * as types from '../config/types';
import {clone} from './../helpers/Helpers';

export function contacts(state = initialState.contacts, action) {
    switch (action.type) {
        // получение списка контактов пользователя
        case types.contacts.GET_RESULT: {
            return {...state, userContacts: action.userContacts};
        }

        // добавление новой группы
        case types.contacts.ADD_GROUP: {
            const newState = Array.from(state.userContacts);

            newState.push(action.result)

            // сортировка по имени группы
            newState.sort((a, b) => {
                if (a.id === 0) return 1
                if (b.id === 0) return -1
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });

            return {...state, userContacts: newState};
        }

        // редактирование группы
        case types.contacts.UPDATE_GROUP: {
            const newState = Array.from(state.userContacts);

            newState.forEach((group, groupIndex) => {
                if (group.id === action.result.id) {
                    newState[groupIndex] = action.result;
                }
            })
            return {...state, userContacts: newState};
        }

        // удаление группы
        case types.contacts.DELETE_GROUP: {
            const newState = Array.from(state.userContacts);
            newState.forEach((group, groupIndex) => {
                if (group.id === action.groupId) {
                    newState.splice(groupIndex, 1);
                }
            })
            return {...state, userContacts: newState};
        }

        // добавление контакта
        case types.contacts.ADD_CONTACT: {
            const contact  = action.result;
            contact.selected = false;
            const newState = Array.from(state.userContacts);

            // контакт без группы, пройдемся по всем группам и проверим, создана ли noGroup
            if (contact.groupId === 0) {
                let noGroupExists = false;
                newState.forEach(group => {
                    if (group.id === 0) noGroupExists = true;
                })
                // группы "Контакты вне групп" нет, создадим
                if (!noGroupExists) {
                    newState.push({
                        id: 0,
                        name: 'Контакты вне групп',
                        ownerType: 'usergroup',
                        contacts: [],
                    });
                }
            }
            
            // поместим контакт в нужную группу
            newState.forEach(group => {
                if (group.id === contact.groupId) group.contacts.push(contact);
            })

            return {...state, userContacts: newState};
        }

        // удаление контакта
        case types.contacts.DELETE_CONTACT: {
            let newState = Array.from(state.userContacts);
            newState.forEach((group, groupIndex) => {
                if (group.contacts.length) {
                    let contacts = group.contacts.filter(contact => contact.id !== action.contactId)
                    newState[groupIndex].contacts = contacts;
                }
            })

            // не оставляем пустую группу "Контакты вне групп"
            if (newState.length === 1) {
            	newState.forEach(group => {
            		if (group.id === 0) newState = [];
            	})
            }

            return {...state, userContacts: newState};
        }

        // обновление контакта
        case types.contacts.UPDATE_CONTACT: {
            const newState         = Array.from(state.userContacts);
            let groupChange        = false;
            action.result.selected = false;

            newState.forEach(group => {
                group.contacts.forEach((contact, contactIndex) => {
                    if (contact.id === action.result.id) {
                        // перенос в другую группу
                        if (action.result.groupId !== group.id) {
                            groupChange = true;
                            group.contacts.splice(contactIndex, 1);
                        } else {
                            // обновление контакта
                            group.contacts[contactIndex] = action.result;
                        }
                    }
                })
            })

            // перенос контакта в другую группу
            if (groupChange) {
                newState.forEach(group => {
                    if (group.id === action.result.groupId) {
                        group.contacts.push(action.result);
                    }
                })
            }

            return {...state, userContacts: newState};
        }

        // получение списка пользователей контактов домена
        case types.contacts.GET_RESULT_DOMAIN_USERS: {
            return {...state, domainUsers: action.result};
        }

        // получение списка контактов домена
        case types.contacts.GET_RESULT_DOMAIN: {
            return {...state, domainContacts: action.result};
        }

        // добавление новой общей группы
        case types.contacts.DOMAIN.GROUPS.ADD: {
            const domainContacts = Array.from(state.domainContacts);

            domainContacts.push(action.result)

            // сортировка по имени группы
            domainContacts.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });

            return {...state, domainContacts: domainContacts};
        }

        // Обновление группы контактов
        case types.contacts.DOMAIN.GROUPS.UPDATE: {
            const newGroup        = action.result
            const domainContacts  = clone(state.domainContacts)
            const index           = domainContacts.findIndex(group => group.id === newGroup.id)
            domainContacts[index] = newGroup

            // сортировка по имени группы
            domainContacts.sort((a, b) => {
                if (a.name < b.name) return -1;
                if (a.name > b.name) return 1;
                return 0;
            });

            ///
            return {...state, domainContacts};
        }

        // Удаление группы контактов
        case types.contacts.DOMAIN.GROUPS.DELETE: {
            const deletedGroupId = action.result
            const domainContacts = clone(state.domainContacts).filter(group => group.id !== deletedGroupId)
            return {...state, domainContacts};
        }

        // Создание нового контакта домена
        case types.contacts.DOMAIN.CONTACT.ADD: {
            const newContact = action.result
            const contacts   = clone(state.domainContacts)
            const group      = contacts.find(g => g.id === newContact.groupId) || null

            if (group) {
                group.contacts.push(newContact)
            }

            return {...state, domainContacts: contacts};
        }

        // Создание нового контакта домена
        case types.contacts.DOMAIN.CONTACT.UPDATE: {
            const contacts       = clone(state.domainContacts)
            const updatedContact = action.result
            const oldContact     = contacts.flatMap(group => group.contacts).find(c => c.id === updatedContact.id)

            contacts.forEach(group => {
                if (oldContact && group.id === oldContact.groupId) {
                    const index = group.contacts.findIndex(contact => contact.id === oldContact.id)
                    if (index !== -1) {
                        group.contacts.splice(index, 1)
                    }
                }
                if (group.id === updatedContact.groupId) {
                    group.contacts.push(updatedContact)
                }
            });

            return {...state, domainContacts: contacts};
        }

        // удаление общего контакта
        case types.contacts.DOMAIN.CONTACT.DELETE: {
            const deletedContactId = action.result
            const groups           = clone(state.domainContacts)

            groups.forEach(group => {
                group.contacts = group.contacts.filter(contact => contact.id !== deletedContactId)
            });

            return {...state, domainContacts: groups,};
        }

        // Загрузка списка контактов из файла
        case types.contacts.DOMAIN.LIST.UPLOAD: {
            return {...state, uploadFileReport: action.result};
        }

        // Загрузка списка контактов из файла
        case types.contacts.DOMAIN.LIST.ADD: {
            return {...state, domainContacts: action.result, uploadFileReport: null};
        }

        // Очистка предыдущего отчета
        case types.contacts.DOMAIN.LIST.CLEAR: {
            return {...state, uploadFileReport: null};
        }

        default:
            return state;

    }
}
