import initialState from '../config/initialState';
import * as types from '../config/types';


export default function reducer(state = initialState.groupSettings, action) {
  switch (action.type) {
    // получение настроек группы
    case types.group.GET_SETTINGS: {
      const { groupSettings } = action;
      return groupSettings;
    }

    // сброс настроек группы в стейте
    case types.group.SETTINGS_RESET: {
      if (!state.id || action.groupId === state.id) return state;
      else return initialState.groupSettings;
    }        

    // обновление медиа группы
    case types.group.UPLOAD_MEDIA: {
      let newState = {};
      switch (action.media.mediaType) {
        case "g_g":
          newState = Object.assign({}, state, {greeting: action.media})
          break;
        case "g_v":
          newState = Object.assign({}, state, {voicemailPrompt: action.media})
          break;
        case "g_h":
          newState = Object.assign({}, state, {onHoldMusic: action.media})
          break;
        default:
          newState = state;
      }
      return newState;
    }

    // удаление медиа группы
    case types.group.DELETE_MEDIA: {
      let newState = state;
      switch (action.media.mediaType) {
        case "g_g":
          let emptyGreeting = initialState.groupSettings.greeting;
          emptyGreeting['useCallToRecord'] = state.greeting.useCallToRecord;
          newState = Object.assign({}, state, {greeting: emptyGreeting})
          break;
        case "g_v":
          let emptyVoicemailPrompt = initialState.groupSettings.voicemailPrompt;
          emptyVoicemailPrompt['useCallToRecord'] = state.voicemailPrompt.useCallToRecord;
          newState = Object.assign({}, state, {voicemailPrompt: emptyVoicemailPrompt})
          break;
        case "g_h":
          let emptyOnHoldMusic = initialState.groupSettings.onHoldMusic;
          emptyOnHoldMusic['useCallToRecord'] = state.onHoldMusic.useCallToRecord;
          newState = Object.assign({}, state, {onHoldMusic: emptyOnHoldMusic})
          break;
        default:
          newState = state;
      }
      return newState;
    }

    // удалить пользователя из группы
    case types.group.DELETE_USER: {
      let users = state.users.filter(user => user.userId !== action.userId);
      const newState = Object.assign({}, state, {users: users});
      return newState;
    }

    //Уменьшение счетчика новых голосовых сообщений
    /*case types.domainUser.DECR_VOICEMAIL_UNREAD_COUNT:
      const newCount = state.voicemailUnreadCount > 0
        ? state.voicemailUnreadCount - 1
        : state.voicemailUnreadCount
      ;
      const newState = Object.assign({}, state, {voicemailUnreadCount: newCount});
      return newState;*/

    default: return state;
  }
};