import initialState from '../config/initialState'
import * as types from '../config/types'
import { pipe } from '../helpers'

export const getUsers = (state) => state.domainUsers.users
export const getUserById = (userId) =>
  pipe([getUsers, (users) => users.find(({ id }) => id === userId)])
export const getOperators = pipe([
  getUsers,
  (users) => users.filter(({ isOperator }) => isOperator),
])
export const getGroups = (state) => state.domainUsers.groups
export const getGroupById = (groupId) =>
  pipe([getGroups, (groups) => groups.find(({ id }) => id === groupId)])
export const getGroupsByUserId = (userId) =>
  pipe([
    getGroups,
    (groups) =>
      groups.filter(({ users = [] }) =>
        users.find(({ userId: id }) => id === userId),
      ),
  ])
export const getIvrs = (state) => state.domainUsers.ivrs
export const getIvrById = (ivrId) =>
  pipe([getIvrs, (ivrs) => ivrs.find(({ id }) => id === ivrId)])

export const getDescriptionDistributionGroup = (type, translation = null) => {
  let title = '';
  switch (type) {
    case TYPE_DISTRIBUTION.RANDOM:
      title = 'Вызовы распределяются в случайном порядке'
    case TYPE_DISTRIBUTION.MIA:
      title = 'Вызовы распределяются по приоритету пользователей'
    case TYPE_DISTRIBUTION.LOA:
      title = 'Вызовы распределяются по приоритету пользователей'
    case TYPE_DISTRIBUTION.HUNT:
      title = 'Вызовы направляются всем пользователям одновременно'
    default:
      title = ''    
  }
  if (typeof translation === 'function') title = translation(title);
  return title
}

export const TYPE_DISTRIBUTION = {
  NONE: 'none',
  RANDOM: 'rand',
  MIA: 'mia',
  LOA: 'loa',
  HUNT: 'hunt',
}

export default function reducer(state = initialState.domainUsers, action) {
  switch (action.type) {
    case types.domain.GET_IVRS: {
      const newState = Object.assign({}, state, { ivrs: action.ivrs })
      return newState
    }

    case types.domain.ADD_IVR:
      return { ...state, ivrs: [...state.ivrs, action.payload] }

    case types.domain.UPDATE_IVR:
      return { ...state, ivrs: state.ivrs.map((ivr) => ivr.id === action.payload.id ? action.payload : ivr) }

    case types.domain.REMOVE_IVR: {
      const newState = Object.assign({}, state, {
        ivrs: state.ivrs.filter(({ id }) => id !== action.id),
      })
      return newState
    }

    case types.domain.GET_USERS: {
      const newState = Object.assign({}, state, { users: action.users })
      return newState
    }

    case types.domain.GET_GROUPS: {
      const newState = Object.assign({}, state, { groups: action.groups })
      return newState
    }

    case types.domain.ADD_GROUP: {
      let groups = state.groups
      let users = state.users

      groups.push(action.group)

      // если в группе обработки вызовов есть пользователи, нужно у них добавить эту funcgroup
      if (action.group.users && action.group.users.length) {
        let usersToAdd = action.group.users.map((user) => user.userId)
        users = users.map((user) => {
          if (usersToAdd.includes(user.id)) {
            let funcgroups = user.funcgroups
            funcgroups.push({
              userId: user.id,
              groupId: action.group.id,
              priority: 10,
            })
            user['funcgroups'] = funcgroups
          }
          return user
        })
      }

      // если в группе перехвата есть пользователи, нужно указать у них pickupGroupId
      if (action.group.userIds && action.group.userIds.length) {
        users = users.map((user) => {
          if (action.group.userIds.includes(user.id))
            user['pickupGroupId'] = action.group.id
          return user
        })
      }

      const newState = Object.assign({}, state, {groups: groups, users: users});
      return newState
    }

    // обновить пользователей у группы
    case types.domain.SET_GROUP_USERS: {
      let groups = state.groups
      let users = state.users

      // заменим группу в state.groups
      groups = groups.map((group) => {
        if (group.id === action.group.id) return action.group
        else return group
      })

      // нужно обновить у всех юзеров funcgroups
      // обновленный состав пользователей новой группы (если есть)
      let usersToAdd = (action.group.users && action.group.users.length) ? action.group.users.map((user) => user.userId) : [];

      users = users.map((user) => {
        // составим список групп пользователя
        let userGroups = user.funcgroups.map((group) => group.groupId)

        // пользователь относится к списку группы, но не находится в искомой группе
        // нужно добавить ему эту группу
        if (
          usersToAdd.includes(user.id) &&
          !userGroups.includes(action.group.id)
        ) {
          let funcgroups = user.funcgroups
          funcgroups.push({
            userId: user.id,
            groupId: action.group.id,
            priority: 10,
          })
          user['funcgroups'] = funcgroups
        }
        // пользователь не относится к списку группы, но имеет эту группу
        // нужно удалить у него эту группу
        if (
          !usersToAdd.includes(user.id) &&
          userGroups.includes(action.group.id)
        ) {
          let funcgroups = user.funcgroups.filter(
            (group) => group.groupId !== action.group.id,
          )
          user['funcgroups'] = funcgroups
        }
        // пользователь относится к списку группы, и имеет эту группу
        // обновим свойства его funcgroup
        if (
          usersToAdd.includes(user.id) &&
          userGroups.includes(action.group.id)
        ) {
          let funcgroups = user.funcgroups.map(userFuncgroup => {
            if (userFuncgroup.groupId === action.group.id) {
              // нужно обновить эту funcgroup
              let funcgroup = {}
              action.group.users.forEach(groupUserFuncgroup => {
                if (groupUserFuncgroup.userId === user.id) funcgroup = groupUserFuncgroup
              })
              return funcgroup;
            } else return userFuncgroup;
          })
          user['funcgroups'] = funcgroups
        }
        return user
      })
      

      // группа перехвата
      if (action.group.userIds && action.group.userIds.length) {
        // нужно пройти по всем юзерам и сменить у целевых pickupGroupId
        users = users.map((user) => {
          if (action.group.userIds.includes(user.id))
            user['pickupGroupId'] = action.group.id
          return user
        })

        // пройдем по всем группам перехвата и у не целевых уберем целевые userIds
        groups = groups.map((group) => {
          if (group.isCallPickup && group.id !== action.group.id) {
            group['userIds'] = group.userIds.filter(
              (userId) => !action.group.userIds.includes(userId),
            )
          }
          return group
        })
      }

      const newState = Object.assign({}, state, {groups: groups, users: users});
      return newState
    }

    case types.domain.DELETE_GROUP: {
      const groups = state.groups.filter((group) => group.id !== action.groupId)
      const users = state.users.map((user) => {
        let funcgroups = user.funcgroups.filter(
          (funcgroup) => funcgroup.groupId !== action.groupId,
        )
        user['funcgroups'] = funcgroups

        // пользователь группы перехвата
        if (user.pickupGroupId === action.groupId) user['pickupGroupId'] = null
        return user
      })

      const newState = Object.assign({}, state, {groups: groups, users: users});
      return newState
    }

    // обновление группы
    case types.domain.UPDATE_GROUP: {
      let groups = state.groups.map((group) => {
        if (group.id === action.group.id) {
          return action.group;
        } else {
          return group;
        }
      })
      const newState = Object.assign({}, state, {groups: groups});
      return newState;
    }

    // удалить пользователя из группы
    /*case types.domain.DELETE_USER_FROM_GROUP: {
      // удалим юзера из групп
      let groups = []
      state.groups.forEach(group => {
          if (group.id === action.userGroup.group) {
              let users = group.users.filter(user => user.userId !== action.userGroup.user);
              group['users'] = users;
          }
          groups.push(group);
      })
      const newState = Object.assign({}, state, {groups: groups});

      return newState;
    }*/

    // обновить группы пользователя
    case types.domain.UPDATE_USER_GROUPS: {
      // поместим новые группы пользователя в массив с их id
      let targetUserGroups = action.userSettings.funcgroups.map(
        (userGroup) => userGroup.groupId,
      )
      // обновим все группы
      let groups = []
      state.groups.forEach((group) => {
        // группы обработки вызовов
        if (group.users) {
          let groupUsers = []
          group.users.forEach((groupUser) => {
            // если пользователь группы не равен целевому - добавляем
            if (groupUser.userId !== action.userSettings.id) {
              groupUsers.push(groupUser)
            }
          })
          // если группа есть у целевого пользователя - добавим его к пользователям группы
          if (targetUserGroups.includes(group.id)) {
            groupUsers.push({
              userId: action.userSettings.id,
              groupId: group.id,
              priority: 10,
            })
          }
          group['users'] = groupUsers
        }
        // группы перехвата
        if (group.userIds) {
          let groupUserIds = group.userIds
          // юзер должен относиться к этой группе перехвата, но его там нет - добавим
          if (
            action.userSettings.pickupGroupId === group.id &&
            !groupUserIds.includes(action.userSettings.id)
          ) {
            groupUserIds.push(action.userSettings.id)
          }
          // юзер не имеет pickupGroupId, но находится в группе перехвата - удалим
          if (
            !action.userSettings.pickupGroupId &&
            groupUserIds.includes(action.userSettings.id)
          ) {
            const userIdIndex = groupUserIds.indexOf(action.userSettings.id)
            groupUserIds.splice(userIdIndex, 1)
          }
          group['userIds'] = groupUserIds
        }
        groups.push(group)
      })

      // обновим привязку групп у пользователя
      /*let users = []
      state.users.forEach((user) => {
        if (user.id === action.userSettings.id) {
          let userGroups = []
          targetUserGroups.forEach((groupId) => {
            userGroups.push({ userId: user.id, groupId: groupId, priority: 0 })
          })
          user['funcgroups'] = userGroups
        }
        users.push(user)
      })*/
      let users = state.users.map((user) => {
        return user.id === action.userSettings.id ? action.userSettings : user;
      })

      const newState = Object.assign({}, state, {groups: groups, users: users});
      return newState;
    }

    // удаление пользователя
    case types.domain.DELETE_USER: {
      let users = state.users.filter((user) => user.id !== action.id)

      // нужно удалить юзера из групп в state.groups
      let groups = []
      state.groups.forEach((group) => {
        // удалим юзера из групп обработки вызовов
        if (group.users) {
          let groupUsers = group.users.filter(groupUser => groupUser.userId !== action.id)
          group['users'] = groupUsers
        }

        // удалим юзера из групп перехвата
        if (group.userIds) {
          let groupUserIds = group.userIds.filter(groupUserId => groupUserId !== action.id)
          group['userIds'] = groupUserIds
        }
        groups.push(group)
      })

      const newState = Object.assign({}, state, {groups: groups, users: users});
      return newState;
    }

    // добавление юзера
    case types.domain.ADD_USER: {

      let users = state.users
      let groups = state.groups

      // передан ли массив пользователей или один пользователь?
      let newUsers = Array.isArray(action.userSettings) ? action.userSettings : [action.userSettings];

      newUsers.forEach(newUser => {
        // добавим нового юзера в state.domainUsers.users
        users.push(newUser)

        // нужно добавить у state.domainUsers.groups привязку к этому пользователю
        // поместим новые группы пользователя в массив с их id
        if (newUser.funcgroups.length > 0) {
          let targetUserGroups = newUser.funcgroups.map(
            (userGroup) => userGroup.groupId,
          )
          // обновим все группы
          let newGroups = []
          groups.forEach((group) => {
            // обычные группы
            if (group.users) {
              let groupUsers = []
              group.users.forEach((groupUser) => {
                // если пользователь группы не равен целевому - добавляем
                if (groupUser.userId !== newUser.id) {
                  groupUsers.push(groupUser)
                }
              })
              // если группа есть у целевого пользователя - добавим его к пользователям группы
              if (targetUserGroups.includes(group.id)) {
                groupUsers.push({
                  userId: newUser.id,
                  groupId: group.id,
                  priority: 10,
                })
              }
              group['users'] = groupUsers
              newGroups.push(group)
            }
            // группы перехвата
            if (group.isCallPickup) newGroups.push(group)
          })
          groups = newGroups
        }    
      })
      
      const newState = Object.assign({}, state, {groups: groups, users: users});
      return newState;
    }

    case types.domain.UPDATE_USERS: {
      // создадим массив целевых пользователей с ключом, равным их id
      let targetUsers = []
      action.users.forEach((targetUser) => {
        targetUsers[targetUser.id] = targetUser
      })

      let users = state.users.map((user) => {
        if (targetUsers[user.id]) return targetUsers[user.id]
        else return user
      })

      const newState = Object.assign({}, state, { users: users })
      return newState
    }

    case types.domainCallRecord.GET_RESULT: {
      const { payload } = action
      state['charge'] = payload
      return state
    }

    // обновим меню в списке IVR
    case types.domain.UPDATE_IVR_MENU: {
      let ivrs = state.ivrs.map(ivr => {
        let menus = ivr.menus.map(menu => menu.id === action.menu.id ? action.menu : menu )
        return {...ivr, menus: menus}
      })
      const newState = Object.assign({}, state, { ivrs: ivrs })

      return newState
    }

    // //Подключить/отключить пользователю запись разговоров
    // case types.domainCallRecord.SET_USER: {
    //     const { payload } = action;
    //     state['set_user'] = payload;
    //     return state;
    // }
    // //Подключить/отключить запись разговоров нескольким пользователям
    // case types.domainCallRecord.SET_USERS: {
    //     const { payload } = action;
    //     return ({
    //         ...state,
    //         users: payload
    //     });
    // }
    // case types.domainCallRecord.GET_USERS: {
    //     const newState = Object.assign({}, state, {users: action.users});
    //     return newState;
    // }

    // case types.domainCallRecord.GET_GROUPS: {
    //     const newState = Object.assign({}, state, {groups: action.groups});
    //     return newState;
    // }

    default:
      return state
  }
}
