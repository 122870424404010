import { SystemApi } from 'vats_webapi';
import * as types from '../config/types';
import { createError } from './error';


export function getSystemSettings(callback = null) {
  return dispatch => {
    const apiInstance = new SystemApi();

    apiInstance.getSystemSettings((error, result) => {
      if (error) {
        dispatch(createError(error));
      } else {
        dispatch({
          type: types.systemSettings.GET,
          result: result,
        });
      }
      typeof setLoading === 'function' && callback()
    });
  }
}